import { MutationFunction, useQueryClient } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface ConfirmImportResponseModel {
	readonly collectionId: string;
}

export const useConfirmImport = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<ConfirmImportResponseModel>, string> = async importId => {
		return http<ConfirmImportResponseModel>({
			method: "POST",
			url: `/import/${importId}/confirm`,
			body: undefined
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: data => {
			queryClient.invalidateQueries(["importStatus"]);
			queryClient.invalidateQueries(["collections"]);
			queryClient.invalidateQueries({ queryKey: ["collection", data.body.collectionId] });
			queryClient.invalidateQueries(["transactions"]);
			queryClient.invalidateQueries(["transactionSummaries"]);
			queryClient.invalidateQueries(["dashboardCurrentPosition"]);
			queryClient.invalidateQueries(["dashboardHistoricalPositions"]);
		}
	});
};
