import { MutationFunction } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface CreateShortTokenResponseModel {
	shortToken: string;
}

export const useCreateShortToken = () => {
	const http = useHTTP(HTTPAuthMode.Required);

	const mutFunc: MutationFunction<Response<CreateShortTokenResponseModel>, void> = () => {
		return http<CreateShortTokenResponseModel, undefined>({
			method: "POST",
			url: "/short-token",
			body: undefined
		});
	};

	return useSimpleMutation(mutFunc);
};
