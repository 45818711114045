import { Theme, useTheme } from "@fyendalscollection/app/lib/ThemeContext";

export interface ThemedImageProps {
	className?: string;
	onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
	srcLight: string;
	srcDark: string;
}

export const ThemedImage = ({ className, srcLight, srcDark, onClick }: ThemedImageProps): JSX.Element => {
	const theme = useTheme();

	return (
		<img className={className} src={theme === Theme.Light ? srcLight : srcDark} onClick={onClick} />
	);
};
