import { StorageService } from "./StorageService";

export class BrowserStorageService implements StorageService {

	private static _localStorage = (document.defaultView || window).localStorage;
	private static _sessionStorage = (document.defaultView || window).sessionStorage;

	public setPersistent<T>(key: string, value: T): void {
		BrowserStorageService.set(BrowserStorageService._localStorage, key, value);
	}

	public setTemporary<T>(key: string, value: T): void {
		BrowserStorageService.set(BrowserStorageService._sessionStorage, key, value);
	}

	public getPersistent<T>(key: string): T | undefined {
		return BrowserStorageService.get<T>(BrowserStorageService._localStorage, key);
	}

	public getTemporary<T>(key: string): T | undefined {
		return BrowserStorageService.get<T>(BrowserStorageService._sessionStorage, key);
	}

	public hasPersistent(key: string): boolean {
		return BrowserStorageService.has(BrowserStorageService._localStorage, key);
	}

	public hasTemporary(key: string): boolean {
		return BrowserStorageService.has(BrowserStorageService._sessionStorage, key);
	}

	public removePersistent(key: string): void {
		return BrowserStorageService.remove(BrowserStorageService._localStorage, key);
	}

	public removeTemporary(key: string): void {
		return BrowserStorageService.remove(BrowserStorageService._sessionStorage, key);
	}

	private static set<T>(storage: Storage, key: string, value: T): void {
		storage.setItem(`fc_${key}`, JSON.stringify(value));
	}

	private static get<T>(storage: Storage, key: string): T | undefined {
		const json = storage.getItem(`fc_${key}`);
		if (!json) {
			return undefined;
		}

		return <T>JSON.parse(json);
	}

	private static has(storage: Storage, key: string): boolean {
		return !!storage.getItem(`fc_${key}`);
	}

	private static remove(storage: Storage, key: string): void {
		storage.removeItem(`fc_${key}`);
	}
}
