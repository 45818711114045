import { useHTTPEnvironment } from "./HTTPEnvironmentContext";
import { Problem } from "./Problem";
import { useCallback } from "react";

export type Request<U = undefined> = {
	method: "GET" | "DELETE" | "PATCH";
	url: string;
	headers?: Record<string, string>;
} | {
	method: "POST" | "PUT" | "PATCH";
	url: string;
	headers?: Record<string, string>;
	body: U;
};

export type Response<T = undefined> = {
	statusCode: number;
	successful: true;
	body: T;
};

export type ProblemResponse = {
	statusCode: number;
	problem: Problem;
};

export enum HTTPAuthMode {
	None,
	Required,
	UseIfPresent
}

export const useHTTP = (authMode: HTTPAuthMode) => {
	const httpContext = useHTTPEnvironment();

	const http = useCallback(async <T = undefined, U = undefined>(request: Request<U>): Promise<Response<T>> => {
		const fullUrl = request.url.indexOf("https://") === 0
			? request.url
			: `${httpContext.getApiBaseUrl()}${request.url}`;

		const headers = { ...request.headers };
		headers["Content-Type"] = "application/json";

		if (authMode === HTTPAuthMode.Required || authMode === HTTPAuthMode.UseIfPresent) {
			const accessToken = httpContext.getAccessToken();
			if (!accessToken && authMode === HTTPAuthMode.Required) {
				const problem: Problem = {
					problemType: "NotAuthenticated",
					description: "The operation requires authentication, but the user is not authenticated."
				};

				throw {
					statusCode: 0,
					problem
				};
			}

			if (accessToken) {
				headers["Authorization"] = `Bearer ${accessToken}`;
			}
		}

		try {
			const res = await fetch(fullUrl, {
				method: request.method,
				headers: headers,
				mode: "cors",
				cache: "no-cache",
				body: "body" in request
					? JSON.stringify(request.body)
					: undefined
			});

			if (res.status === 569) {
				httpContext.enableMaintenanceMode();

				throw {
					statusCode: res.status,
					problem: {
						problemType: "MaintenanceModeEnabled",
						description: "The platform is currently in maintenance mode."
					}
				};
			}

			if (res.status > 299 || res.status < 200) {
				const problem: Problem = await res.json();

				throw {
					statusCode: res.status,
					problem
				};
			}

			const body = !res.body || !res.headers.get("Content-Length")
				? undefined
				: await res.json() as T;

			return {
				statusCode: res.status,
				successful: true,
				body: body as T
			};
		} catch (err: unknown) {
			if ((err as ProblemResponse).problem) {
				throw err;
			}

			const problem: Problem = {
				problemType: "NetworkError",
				description: "A network failure caused the operation to fail."
			};

			throw {
				statusCode: 0,
				problem
			};
		}
	}, [httpContext]);

	return http;
};
