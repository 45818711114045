import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { GoalCollectionModel } from "./models";

export interface GetGoalCollectionResponseModel {
	readonly goalCollection: GoalCollectionModel | null;
}

export const useGoalCollection = (collectionId: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["goalCollection", collectionId], () => {
		return http<GetGoalCollectionResponseModel>({
			method: "GET",
			url: `/collection/${collectionId}/goal-collection`
		});
	});
};
