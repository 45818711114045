import styles from "./EditCollectionImage.module.scss";

export interface EditCollectionImageProps {
	image: string;
	onClick: () => void;
}

const gradient = "linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 38.02%)";

export const EditCollectionImage = (props: EditCollectionImageProps): JSX.Element => {
	const backgroundImageStyle = gradient + ", url('" + props.image + "')";

	return (
		<div style={{ backgroundImage: backgroundImageStyle }} onClick={props.onClick} className={styles.editCollectionImage}>
			<div>Edit Image</div>
		</div>
	);
};
