import mfa from "@fyendalscollection/app/assets/mfa.svg";
import { useAuthState } from "@fyendalscollection/shared";
import { BannerMessage } from "@fyendalscollection/app/lib/components/BannerMessage";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { useEffectOnFirstMount } from "@fyendalscollection/app/lib/useEffectOnFirstMount";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";

export const PageMfaEnabled = (): JSX.Element => {
	useTitle("MFA Enabled");

	const { dispatchAuthLoggedOut } = useAuthState();

	useEffectOnFirstMount(() => {
		dispatchAuthLoggedOut();
	});

	return (
		<>
			<Container>
				<ContainerSegment>
					<BannerMessage
						image={mfa}
						title="Multifactor Authentication Enabled"
						content="Please click the button below to login again." />
				</ContainerSegment>
			</Container>
			<GreenButtonSet buttons={[
				{
					text: "Login",
					link: "/login"
				}
			]} />
		</>
	);
};
