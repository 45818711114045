import styles from "./PageEvBreakdown.module.scss";
import { Link, useParams } from "react-router-dom";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import { PremiumPromotionContainer } from "@fyendalscollection/app/lib/components/PremiumPromotionContainer";
import { useUserPreferencesState } from "@fyendalscollection/shared";
import { EvBreakdownResponseModel, EvProductType, EvSetType, useEvBreakdown } from "@fyendalscollection/shared";
import { useSubscription } from "@fyendalscollection/shared";
import { Limit } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { SelectorCheckbox } from "@fyendalscollection/app/lib/components/SelectorCheckbox";

export const PageEvBreakdown = (): JSX.Element => {
	const params = useParams();
	const setType = params["setType"] as EvSetType;
	const productType = params["productType"] as EvProductType;

	const subscription = useSubscription();
	const evBreakdown = useEvBreakdown(setType, productType);

	return (
		<Limit>
			<Breadcrumb crumbs={[
				{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "EV Tracker",
					link: "/ev-tracker"
				},
				{
					text: evBreakdown.data ? evBreakdown.data.productName : "Product Breakdown"
				}
			]} />

			<PageTitle
				title={evBreakdown.data ? evBreakdown.data.productName : "Product Breakdown"}
				subTitle="Full breakdown of Estimated Value" />

			{
				subscription.data && (
					subscription.data.activeSubscription
						? <PageEvBreakdownInternal setType={setType} productType={productType} />
						: <PremiumPromotionContainer featureName="EV Tracker" />
				)
			}
		</Limit>
	);
};

interface PageEvBreakdownInternalProps {
	setType: EvSetType;
	productType: EvProductType;
}

const PageEvBreakdownInternal = ({ setType, productType }: PageEvBreakdownInternalProps): JSX.Element => {
	const evBreakdown = useEvBreakdown(setType, productType);
	const { state, dispatchSetExcludedEvCategories } = useUserPreferencesState();
	const excludedCategories = state.excludedEvCategories[setType];

	const buildCurrentEstimatedValue = (data: EvBreakdownResponseModel) => {
		return data.categories.reduce((x, cv) => {
			if (excludedCategories && excludedCategories.includes(cv.category)) return x;
			return x + cv.contributedValue.value;
		}, 0);
	};

	return (
		<Loadable loading={evBreakdown.isLoading}>
			{
				evBreakdown.data &&
				<Container>
					<Link className={styles.productDisplay} to={`/browse/product/${evBreakdown.data.productId}`}>
						<ProductImage className={styles.productImage} image={evBreakdown.data.imageUrl} />

						<div className={styles.title}>
							<div>{evBreakdown.data.productName}</div>
							<div>{evBreakdown.data.fcId}</div>
						</div>

						<PrettyMoney
							className={styles.price}
							mode={MoneyDisplayMode.Standard}
							money={excludedCategories && excludedCategories.length < 1 ? evBreakdown.data.currentEstimatedValue : {
								value: buildCurrentEstimatedValue(evBreakdown.data),
								currency: evBreakdown.data.currentEstimatedValue.currency
							}} />
					</Link>
					<ContainerSegment>
						The pull rates used on this page have been pieced together from both official and community sources.  We&apos;ve done our
						best to make reasonable assumptions where necessary, including reducing the value of some bulk cards to account for their
						difficulty to sell as a single.  If a figure looks incorrect, we&apos;d love to hear your feedback on
						our <a href="https://discord.gg/RDYn8yDnFm" className={styles.link} target="_blank" rel="noopener noreferrer">official Discord</a> or
						alternatively email us at <a href="mailto:support@fyendalscollection.com" className={styles.link}>support@fyendalscollection.com</a>
					</ContainerSegment>
				</Container>
			}

			<TabularData headers={["Category", "Average Market Price", "Pull Rate", "Contributed Value", ""]}>
				{
					(!evBreakdown.data ? [] : evBreakdown.data.categories).map(x => (
						<TabularDataItem key={x.categoryName}>
							<>
								<Link to={`/ev-tracker/breakdown/${setType}/${productType}/${x.category}`}>
									<div className={styles.categoryName}>{x.categoryName}</div>
								</Link>
							</>
							<><PrettyMoney mode={MoneyDisplayMode.Extended} money={x.averageMarketPrice} /></>
							<>{x.multiplier}</>
							<><PrettyMoney mode={MoneyDisplayMode.Standard} money={x.contributedValue} /></>
							<><SelectorCheckbox isChecked={!excludedCategories || !excludedCategories.includes(x.category)} onChange={(value: boolean) => {
								const newExclusions = value ? excludedCategories?.filter(y => y != x.category) :  [...excludedCategories ?? [], x.category];
								const exclusionsFromStorage = state.excludedEvCategories;

								exclusionsFromStorage[setType] = newExclusions;
								dispatchSetExcludedEvCategories(exclusionsFromStorage);
							}} label="" /></>
						</TabularDataItem>
					))
				}
			</TabularData>
		</Loadable>
	);
};
