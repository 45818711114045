import { MutationFunction, useMutation, useQueryClient } from "react-query";
import { Problem, ProblemResponse, Response, useHTTPEnvironment } from "../../http";

export interface ImportUploadRequestOptions {
	readonly csvImportToken: string;
	readonly file: File;
}

export const useImportUpload = () => {
	const httpEnvironment = useHTTPEnvironment();
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, ImportUploadRequestOptions> = async options => {
		const {
			csvImportToken,
			file
		} = options;

		try {
			const res = await fetch(`${httpEnvironment.getApiBaseUrl()}/import/upload/${csvImportToken}`, {
				method: "PUT",
				headers: {
					Authorization: `Bearer ${httpEnvironment.getAccessToken()}`
				},
				mode: "cors",
				cache: "no-cache",
				body: file
			});

			if (res.status > 299 || res.status < 200) {
				const problem: Problem = await res.json();

				throw {
					statusCode: res.status,
					problem
				};
			}

			return {
				statusCode: res.status,
				successful: true,
				body: undefined
			};
		} catch (err: unknown) {
			if ((err as ProblemResponse).problem) {
				throw err;
			}

			const problem: Problem = {
				problemType: "NetworkError",
				description: "A network failure caused the operation to fail."
			};

			throw {
				statusCode: 0,
				problem
			};
		}
	};

	return useMutation<Response, ProblemResponse, ImportUploadRequestOptions>(mutFunc, {
		onSuccess: () => {
			queryClient.invalidateQueries(["importStatus"]);
		}
	});
};
