import { Loader } from "@fyendalscollection/app/lib/components/Loader";
import styles from "./Loadable.module.scss";

export interface LoadableProps {
	children: React.ReactNode;
	loading: boolean;
}

export const Loadable = ({ children, loading }: LoadableProps): JSX.Element => {
	const classNames = [ styles.container ];
	if (loading) {
		classNames.push(styles.loading);
	}

	return (
		<div className={classNames.join(" ")}>
			<div className={styles.content}>
				{children}
			</div>
			<div className={styles.loader}>
				<Loader />
			</div>
		</div>
	);
};
