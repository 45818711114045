import { useState } from "react";
import dayjs from "dayjs";
import styles from "./PageSubscription.module.scss";
import tick from "@fyendalscollection/app/assets/tick.svg";
import { Currency, SubscriptionModel, useSubscription } from "@fyendalscollection/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { ModalCancelAutoRenew } from "@fyendalscollection/app/lib/components/ModalCancelAutorenew";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ContainerListItem } from "@fyendalscollection/app/lib/components/ContainerListItem";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { ModalCreateSubscription } from "@fyendalscollection/app/features/subscriptions/modals/ModalCreateSubscription";
import { ModalAddPaymentCard } from "@fyendalscollection/app/features/paymentCards/modals/ModalAddPaymentCard";
import { SubscriptionPlan } from "@fyendalscollection/app/features/subscriptions/components/SubscriptionPlan";

export const PageSubscription = (): JSX.Element => {
	useTitle("Subscription");

	const subscription = useSubscription();

	const [createModalVisible, setCreateModalVisible] = useState(false);
	const [addPaymentCardModalVisible, setAddPaymentCardModalVisible] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);

	const pageLimit = subscription.isLoading || subscription.data && subscription.data.activeSubscription
		? LimitWidth.Tiny
		: LimitWidth.Medium;

	return (
		<Limit force={pageLimit}>
			<Breadcrumb crumbs={[{
				text: "Settings",
				link: "/settings"
			},
			{
				text: "Subscription"
			}]}/>

			<PageTitle
				title="Subscription"
				subTitle="Manage your access to premium features" />

			<Loadable loading={subscription.isLoading}>
				{subscription.data ?
					activeSubscriptionInfoDisplay(subscription.data, setCreateModalVisible, setDeleteModalVisible) : loadingDisplay()}
			</Loadable>

			<ModalCreateSubscription
				visible={createModalVisible}
				onCancel={() => setCreateModalVisible(false)}
				showAddPaymentCard={() => setAddPaymentCardModalVisible(true)} />

			<ModalAddPaymentCard
				visible={addPaymentCardModalVisible}
				onCancel={() => setAddPaymentCardModalVisible(false)} />

			<ModalCancelAutoRenew
				visible={deleteModalVisible}
				onCancel={() => setDeleteModalVisible(false)} />
		</Limit>
	);
};

const activeSubscriptionInfoDisplay = (
	subscription: SubscriptionModel,
	setCreateModalVisible: (visible: boolean) => void,
	setDeleteModalVisible: (visible: boolean) => void): JSX.Element => {

	const autoRenewDisplay = subscription.autoRenewPaymentCardId && subscription.autoRenewPlanId && subscription.autoRenewPlanName ? (
		<ContainerSegment>
			<ContainerListItem title="Auto Renew Plan" subtitle={subscription.autoRenewPlanName}>
				<b><PrettyMoney mode={MoneyDisplayMode.Standard} money={{ currency: Currency.USD, value: subscription.autoRenewPlanPrice }} /></b>
			</ContainerListItem>
		</ContainerSegment>
	) : (
		<></>
	);

	const buttons = [
		{
			text: "Change Subscription",
			onClick: () => setCreateModalVisible(true)
		}
	];

	if (subscription.autoRenewPaymentCardId && subscription.autoRenewPlanId && subscription.autoRenewPlanName) {
		buttons.unshift({
			text: "Cancel Auto Renew",
			onClick: () => setDeleteModalVisible(true)
		});
	}

	return !subscription.activeSubscription ? (
		noSubscriptionDisplay(setCreateModalVisible)
	) : (
		<>
			<Container title="Subscription Details">
				<ContainerSegment>
					<ContainerListItem
						title="Current Plan"
						subtitle={`${subscription.activeSubscription.planName} 
							expires ${dayjs(subscription.activeSubscription.expiryDate).format("DD MMM YYYY")}`}>
						<LinkButton
							text={"Terms and Conditions"}
							link="https://fyendalscollection.com/terms-and-conditions"
							newTab={true} />
					</ContainerListItem>
				</ContainerSegment>

				{autoRenewDisplay}
			</Container>

			<GreenButtonSet buttons={buttons} />
		</>
	);
};

const noSubscriptionDisplay = (setCreateModalVisible: (visible: boolean) => void): JSX.Element => {
	return (
		<div className={styles.subscriptionPlans}>
			<SubscriptionPlan title="Free" description="We will always offer these features to everyone for free!" amount={0}>
				<div className={styles.featureTitle}>Free Features</div>
				<div className={styles.features}>
					<div>
						<img src={tick} />
						<div>Organize your products into collections</div>
					</div>
					<div>
						<img src={tick} />
						<div>Track the market value of your collections and products</div>
					</div>
					<div>
						<img src={tick} />
						<div>View recent Ebay sales data</div>
					</div>
					<div>
						<img src={tick} />
						<div>Import up to 5000 rows of CSV</div>
					</div>
					<div>
						<img src={tick} />
						<div>Set your preferred currency for pricing information</div>
					</div>
					<div>
						<img src={tick} />
						<div>Buy your favourite products through TCGplayer</div>
					</div>
					<div>
						<img src={tick} />
						<div>Regularly updated pricing information from TCGplayer</div>
					</div>
				</div>
			</SubscriptionPlan>

			<SubscriptionPlan title="Premium" subscribeButton={() => setCreateModalVisible(true)} description="Upgrade your experience with these premium features!" amount={4.99} discounted={true}>
				<div className={styles.featureTitle}>Additional Features</div>
				<div className={styles.features}>
					<div>
						<img src={tick} />
						<div>View the Estimated Value (EV) for sealed product</div>
					</div>
					<div>
						<img src={tick} />
						<div>View latest market trends by product</div>
					</div>
					<div>
						<img src={tick} />
						<div>View your total invested value</div>
					</div>
					<div>
						<img src={tick} />
						<div>View potential profits made on your products</div>
					</div>
					<div>
						<img src={tick} />
						<div>Track profit and loss through sales</div>
					</div>
					<div>
						<img src={tick} />
						<div>View the cash position for any of your collections</div>
					</div>
					<div>
						<img src={tick} />
						<div>Historical graphs for all your collection data</div>
					</div>
					<div>
						<img src={tick} />
						<div>Export your collections in CSV format</div>
					</div>
					<div>
						<img src={tick} />
						<div>Unlimited CSV imports</div>
					</div>
					<div>
						<img src={tick} />
						<div>Direct our future by participating in feature polls</div>
					</div>
					<div>
						<img src={tick} />
						<div>Share your collections publicly</div>
					</div>
				</div>
			</SubscriptionPlan>
		</div>
	);
};

const loadingDisplay = (): JSX.Element => {
	return (
		<Container>
			<ContainerSegment>
				Your subscription is loading...
			</ContainerSegment>
		</Container>
	);
};
