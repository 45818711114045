import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

export class PromiseGLTFLoader {
	private _baseLoader: GLTFLoader;

	constructor() {
		this._baseLoader = new GLTFLoader();
	}

	public load(url: string): Promise<GLTF> {
		return new Promise((resolve, reject) => {
			this._baseLoader.load(url, resolve, undefined, reject);
		});
	}
}
