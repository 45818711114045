import { QueryClient } from "react-query";

export const clearCacheAfterTransactionMutation = (queryClient: QueryClient, collectionId: string, variantId?: string) => {
	queryClient.invalidateQueries({ queryKey: ["transactionSummaries"] });
	queryClient.invalidateQueries({ queryKey: ["collections"] });
	queryClient.invalidateQueries({ queryKey: ["collection", collectionId] });
	queryClient.invalidateQueries(["dashboardCurrentPosition"]);
	queryClient.invalidateQueries(["dashboardHistoricalPositions"]);
	queryClient.invalidateQueries(["progressSummaries"]);
	queryClient.invalidateQueries(["collectionProgress"]);
	queryClient.invalidateQueries(["findInCollections"]);

	if (variantId) {
		queryClient.invalidateQueries({ queryKey: ["collectionVariantDetails", collectionId, variantId] });
	}
};
