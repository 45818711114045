import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PremiumPromotionContainer } from "@fyendalscollection/app/lib/components/PremiumPromotionContainer";
import { API_BASE_URL } from "@fyendalscollection/app/lib/config";
import { useCollection, useCreateShortToken } from "@fyendalscollection/shared";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { Checkbox } from "@fyendalscollection/app/lib/components/Checkbox";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { useSubscription } from "@fyendalscollection/shared";

export const PageTransactionsCSVExport = (): JSX.Element => {
	const { collectionId } = useParams();
	const collection = useCollection(collectionId || "");
	const createShortToken = useCreateShortToken();
	const subscription = useSubscription();
	const navigate = useNavigate();

	const [quantity, setQuantity] = useState(true);
	const [fcId, setFcId] = useState(true);
	const [description, setDescription] = useState(true);
	const [action, setAction] = useState(true);
	const [unitAmount, setUnitAmount] = useState(true);
	const [currency, setCurrency] = useState(true);
	const [date, setDate] = useState(true);

	useEffect(() => {
		createShortToken.mutate();

		const timer = setTimeout(() => navigate(".."), 9 * 60 * 60 * 1000);
		return () => clearTimeout(timer);
	}, []);

	const getFieldList = (): string => {
		const fieldList = [];
		if (!quantity) {
			fieldList.push("quantity");
		}
		if (!fcId) {
			fieldList.push("fcId");
		}
		if (!description) {
			fieldList.push("description");
		}
		if (!action) {
			fieldList.push("action");
		}
		if (!unitAmount) {
			fieldList.push("unitAmount");
		}
		if (!currency) {
			fieldList.push("currency");
		}
		if (!date) {
			fieldList.push("date");
		}
		return fieldList.join(",");
	};

	return (
		<Limit force={LimitWidth.Medium}>
			{!collection.data
				? <></>
				: <>
					<Breadcrumb crumbs={[{
						text: "Dashboard",
						link: "/dashboard"
					},
					{
						text: "Collections",
						link: "/collections"
					},
					{
						text: collection.data.name,
						link: `/collections/${collectionId}`
					},
					{
						text: "Transactions",
						link: `/collections/${collectionId}/transactions`
					},
					{
						text: "CSV Export"
					}]} />

					<PageTitle
						title="CSV Export"
						subTitle="Select the fields you want to export"
						primaryActions={
							<GreenButtonSet buttons={[
								{
									text: "Confirm",
									link: `${API_BASE_URL}/collection/${collectionId}/csv/transactional?omit=${getFieldList()}&shortToken=${createShortToken.data?.body.shortToken}`,
									onClick: () => navigate(".."),
									newTabLink: true,
									disabled: (!quantity && !fcId && !description && !action && !unitAmount && !currency && !date) || !createShortToken.data || !subscription.data?.activeSubscription
								}
							]} />
						} />

					{
						subscription.data && !subscription.data.activeSubscription &&
						<PremiumPromotionContainer featureName="CSV Export" />
					}

					<Container title="Fields">
						<ContainerSegment>
							<Checkbox
								label="Quantity"
								onChange={setQuantity}
								isChecked={quantity} />
						</ContainerSegment>
						<ContainerSegment>
							<Checkbox
								label="Identifier"
								onChange={setFcId}
								isChecked={fcId} />
						</ContainerSegment>
						<ContainerSegment>
							<Checkbox
								label="Description"
								onChange={setDescription}
								isChecked={description} />
						</ContainerSegment>
						<ContainerSegment>
							<Checkbox
								label="Action (Purchase or Sale)"
								onChange={setAction}
								isChecked={action} />
						</ContainerSegment>
						<ContainerSegment>
							<Checkbox
								label="Unit Amount"
								onChange={setUnitAmount}
								isChecked={unitAmount} />
						</ContainerSegment>
						<ContainerSegment>
							<Checkbox
								label="Currency"
								onChange={setCurrency}
								isChecked={currency} />
						</ContainerSegment>
						<ContainerSegment>
							<Checkbox
								label="Date"
								onChange={setDate}
								isChecked={date} />
						</ContainerSegment>
					</Container>
				</>
			}
		</Limit>
	);
};
