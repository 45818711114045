import { useEffect, useState } from "react";

export const useDebouncedState = <T,>(initialValue: T, debounceTime: number): [T, T, (value: T) => void] => {
	const [value, setValue] = useState(initialValue);
	const [debouncedValue, setDebouncedValue] = useState(initialValue);

	useEffect(() => {
		const timeout = setTimeout(() => setDebouncedValue(value), debounceTime);
		return () => clearTimeout(timeout);
	}, [value]);

	return [debouncedValue, value, setValue];
};
