export enum EvSort {
	PercentageGain = "PercentageGain",
	PercentageLoss = "PercentageLoss",
	FlatGain = "FlatGain",
	FlatLoss = "FlatLoss",
	MarketDeltaPositive = "MarketDeltaPositive",
	MarketDeltaNegative = "MarketDeltaNegative",
	HighestEstimatedValue = "HighestEstimatedValue",
	LowestEstimatedValue = "LowestEstimatedValue",
	NewestReleaseDate = "NewestReleaseDate",
	OldestReleaseDate = "OldestReleaseDate"
}
