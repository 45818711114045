import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { useUserPreferencesState } from "@fyendalscollection/shared";
import styles from "./ModalCompareOperation.module.scss";
import { CollectionModel, useCollections, usePushCompareCollectionEvent } from "@fyendalscollection/shared";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ContainerListItemRadioButton } from "@fyendalscollection/app/lib/components/ContainerListItemRadioButton";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";

export interface ModalCompareOperationProps {
	visible: boolean;
	onCancel: () => void;
	collection: CollectionModel;
}

export const ModalCompareOperation = ({ visible, onCancel, collection }: ModalCompareOperationProps): JSX.Element => {
	const [compareCollectionId, setCompareCollectionId] = useState<string | undefined>(undefined);
	const scrollRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const compareCollectionEvent = usePushCompareCollectionEvent();

	const { state } = useUserPreferencesState();
	const collections = useCollections(state.collectionsSortOrder);

	const collectionList = collections.data
		? collections.data.pages.flatMap(x => x.results).filter(x => x.collectionId != collection.collectionId)
		: [];

	const performCompareOperation = () => {
		if (!compareCollectionId) {
			return;
		}
		compareCollectionEvent.mutate();
		navigate(`compare/${compareCollectionId}`);
	};

	return (
		<Modal visible={visible} onCancel={onCancel}>
			<Loadable loading={collections.isLoading}>
				<TitleSecondary text="Compare Collection" />

				<Paragraph>
					Select the collection you want to compare with {collection.name}.
				</Paragraph>

				<div className={styles.collectionList} ref={scrollRef}>
					<Container>
						{
							collectionList.map((x, i) => (
								<ContainerSegment key={x.collectionId} onClick={() => setCompareCollectionId(x.collectionId)}>
									<ContainerListItemRadioButton title={x.name} subtitle={x.description} selected={x.collectionId == compareCollectionId} />
									{
										i == collectionList.length - 1 && <InfiniteScroller forQuery={collections} scrollRef={scrollRef} />
									}
								</ContainerSegment>
							))
						}
					</Container>
				</div>

				{
					collections.isError && (
						<Container>
							<ContainerSegment>
								<ErrorDisplay problemResponse={collections.error}/>
							</ContainerSegment>
						</Container>
					)
				}

				<GreenButtonSet buttons={[
					{
						text: "Compare",
						disabled: !compareCollectionId,
						onClick: performCompareOperation
					},
					{
						text: "Cancel",
						onClick: onCancel
					}
				]} />
			</Loadable>
		</Modal>
	);
};
