import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface FindInCollectionsResponseModel {
	readonly collectionId: string;
	readonly collectionName: string;
	readonly collectionImage: number;
	readonly variantId: string;
	readonly variantName: string;
	readonly quantity: number;
}

export const useFindInCollections = (productId: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["findInCollections", productId], () => {
		return http<FindInCollectionsResponseModel[]>({
			method: "GET",
			url: `/product/${productId}/find-in-collections`
		});
	});
};
