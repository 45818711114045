import styles from "./Checkbox.module.scss";

export interface CheckboxProps {
	onChange: (value: boolean) => void;
	isChecked: boolean;
	label: string;
	disabled?: boolean;
}

export const Checkbox = (props: CheckboxProps): JSX.Element => {
	const handleOnClick = () => {
		if (props.disabled) {
			return;
		}
		props.onChange(!props.isChecked);
	};

	const classNames = [styles.checkbox];
	if (props.disabled) {
		classNames.push(styles.disabled);
	}

	return (
		<div className={classNames.join(" ")}>
			<div
				className={styles.box + (props.isChecked ? " " + styles.checked : "")}
				onClick={handleOnClick}
			>
				&#x2713;
			</div>
			<div onClick={handleOnClick} className={styles.label}>{props.label}</div>
		</div>
	);
};
