import { EmblemRarity } from "../../domain";
import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface GetEmblemStatsResponseModel {
	readonly rarity: EmblemRarity;
	readonly uniqueOwnedCount: number;
	readonly uniqueTotalCount: number;
}

export const useEmblemStats = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["emblemStats"], () => {
		return http<GetEmblemStatsResponseModel[]>({
			method: "GET",
			url: "/emblem/stats"
		});
	});
};
