import { Link } from "react-router-dom";
import crown from "@fyendalscollection/app/assets/crown.svg";
import styles from "./PremiumErrorDisplay.module.scss";
import { ProblemResponse } from "@fyendalscollection/shared";

export type PremiumErrorDisplayProps = {
	message: string | undefined;
} | {
	problemResponse: ProblemResponse | undefined;
}

export const PremiumErrorDisplay = (props: PremiumErrorDisplayProps): JSX.Element => {
	const text = "message" in props
		? props.message
		: props.problemResponse?.problem?.description;

	return (
		<>
			{
				text &&
				<Link to="/settings/subscription" className={styles.premiumErrorDisplay}>
					<div>
						<img src={crown} />
						{text}
					</div>
				</Link>
			}
		</>
	);
};
