import { FormEvent } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import { Theme, useTheme } from "@fyendalscollection/app/lib/ThemeContext";

export interface StripeCardEntryProps {
	onConfirm: () => void;
}

export const StripeCardEntry = ({ onConfirm }: StripeCardEntryProps): JSX.Element => {
	const theme = useTheme();

	const cardElementOptions: StripeCardElementOptions = {
		style: {
			base: {
				fontFamily: "'Rubik', sans-serif",
				fontSmoothing: "antialiased",
				color: theme === Theme.Light ? "#444" : "#EEE",
				"::placeholder": {
					color: theme === Theme.Light ? "#999" : "#999"
				},
				fontSize: "13.33px"
			}
		}
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		onConfirm();
	};

	return (
		<form onSubmit={handleSubmit}>
			<CardElement options={cardElementOptions} />
		</form>
	);
};
