import { Money } from "../../domain";
import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface DashboardCurrentPositionResponseModel {
	marketValue: Money;
	invested: Money | null;
	cashPosition: Money | null;
	gain: Money | null;
	profit: Money | null;
}

export const useDashboardCurrentPosition = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["dashboardCurrentPosition"], () => {
		return http<DashboardCurrentPositionResponseModel>({
			method: "GET",
			url: "/collection/dashboard/current-position"
		});
	});
};

