import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import styles from "./HelpModal.module.scss";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";

interface HelpModalProps {
	showModal: boolean;
	closeModal: () => void;
}

export const HelpModal = ({ showModal, closeModal }: HelpModalProps) => {
	return (
		<Modal visible={showModal} onCancel={closeModal}>
			<TitleSecondary text="Financial Statistics" />

			<ul className={styles.financialStatistics}>
				<li><b>Total Quantity</b> - The total quantity of all the products in your collection.</li>
				<li><b>Market Value</b> - The current market value of your collection.</li>
				<li><b>Invested</b> - The total amount of money you have spent to obtain the products in your collection.</li>
				<li><b>Gain</b> - The amount of money you have gained (or lost) due to changes in market value.  This is the difference between <i>Market Value</i> and <i>Invested.</i></li>
				<li><b>Profit</b> - The amount of money you have realized through selling products from your collection.</li>
				<li><b>Cash Position</b> - Your position with regards to your cash wallet.  This is the difference between <i>Profit</i> and <i>Invested</i>.</li>
			</ul>
		</Modal>
	);
};
