import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { OfficialDeckVariantDetailsModel } from "./models";

export const useOfficialDeckVariantDetails = (officialDeckId: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["officialDeckVariantDetails", officialDeckId], () => {
		return http<OfficialDeckVariantDetailsModel[]>({
			method: "GET",
			url: `/official-deck/${officialDeckId}/variants`
		});
	});
};
