import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import { PaymentCardModel, usePaymentCardSetupIntent } from "@fyendalscollection/shared";
import { useFocus } from "@fyendalscollection/app/lib/useFocus";
import { derivePaymentCardId } from "@fyendalscollection/app/features/paymentCards/derivePaymentCardId";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { StripeCardEntry } from "@fyendalscollection/app/features/paymentCards/components/StripeCardEntry";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";

export interface ModalAddPaymentCardProps {
	visible: boolean;
	onCancel: () => void;
}

export const ModalAddPaymentCard = ({ visible, onCancel }: ModalAddPaymentCardProps): JSX.Element => {
	const [nameOnCard, setNameOnCard] = useState("");
	const [loadingConfirmCardSetup, setLoadingConfirmCardSetup] = useState(false);
	const [errorConfirmCardSetup, setErrorConfirmCardSetup] = useState("");
	const [nameTextEntryRef, setNameTextEntryFocused] = useFocus();
	const paymentCardSetupIntent = usePaymentCardSetupIntent();
	const queryClient = useQueryClient();

	const setupIntentClientSecret = paymentCardSetupIntent.isSuccess ? paymentCardSetupIntent.data.body.setupIntentClientSecret : undefined;

	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		setErrorConfirmCardSetup("");
		if (visible) {
			setNameTextEntryFocused();

			if (!paymentCardSetupIntent.data) {
				paymentCardSetupIntent.mutate(undefined);
				setNameOnCard("");
			}
		}
	}, [visible]);

	const sanitizedName = nameOnCard.trim();

	const confirmCard = async () => {
		if (!stripe || !elements || !sanitizedName || !setupIntentClientSecret) {
			return;
		}

		const cardElement = elements.getElement(CardElement);
		if (!cardElement) {
			return;
		}

		setLoadingConfirmCardSetup(true);

		try {
			const result = await stripe.confirmCardSetup(setupIntentClientSecret, {
				payment_method: {
					card: cardElement,
					billing_details: {
						name: sanitizedName
					}
				}
			});

			if (result.error) {
				setErrorConfirmCardSetup(result.error.message || "Stripe returned an error.");
				setLoadingConfirmCardSetup(false);
				return;
			}

			if (result.setupIntent.status === "succeeded") {
				const paymentMethodId = result.setupIntent.payment_method;

				if (paymentMethodId !== null) {
					queryClient.setQueryData(["paymentCards"], (oldData: PaymentCardModel[] | undefined) => {
						if (!oldData) {
							return [];
						}
						return [...oldData, {
							paymentCardId: typeof paymentMethodId === "string" ? derivePaymentCardId(paymentMethodId) : derivePaymentCardId(paymentMethodId.id),
							last4: "●●●●",
							expiry: dayjs(0).toDate(),
							provider: "Unknown"
						}];
					});
				}

				setLoadingConfirmCardSetup(false);
				onCancel();
				cardElement.clear();
				paymentCardSetupIntent.reset();
				setTimeout(() => queryClient.invalidateQueries(["paymentCards"]), 3000);

				return;
			}

			setErrorConfirmCardSetup(`The Stripe SetupIntent is in a "${result.setupIntent.status}" status.`);
		} catch (err) {
			setErrorConfirmCardSetup("An exception was thrown when contacting Stripe.");
		}

		setLoadingConfirmCardSetup(false);
	};

	return (
		<Modal visible={visible} onCancel={onCancel}>
			<Loadable loading={paymentCardSetupIntent.isLoading || loadingConfirmCardSetup}>
				<TitleSecondary text="Add Payment Card" />

				<Paragraph>
					Please provide your payment card details.  Confirming your
					card details will not cause payment to be taken.
				</Paragraph>

				<Container>
					<ContainerSegment>
						<TextEntry ref={nameTextEntryRef} placeholder="Name on Card" value={nameOnCard} onChange={setNameOnCard} />
					</ContainerSegment>

					<ContainerSegment>
						<StripeCardEntry onConfirm={confirmCard} />
					</ContainerSegment>

					{
						errorConfirmCardSetup && (
							<ContainerSegment>
								<ErrorDisplay message={errorConfirmCardSetup} />
							</ContainerSegment>
						)
					}
				</Container>

				<GreenButtonSet buttons={[
					{
						text: "Confirm Card",
						onClick: confirmCard,
						disabled: !stripe || !elements || !sanitizedName || !setupIntentClientSecret
					}
				]} />
			</Loadable>
		</Modal>
	);
};
