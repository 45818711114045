import { useParams } from "react-router-dom";
import styles from "./CollectionGraphView.module.scss";
import { useUserPreferencesState } from "@fyendalscollection/shared";
import dayjs from "dayjs";
import { HistoricalPositionsGraph } from "@fyendalscollection/app/features/collections/components/HistoricalPositionsGraph";
import { useCollectionHistoricalPositions } from "@fyendalscollection/shared";

export const CollectionGraphView = (): JSX.Element => {
	const { collectionId } = useParams();
	const { state } = useUserPreferencesState();
	const dateStr = dayjs().format("YYYY-MM-DD");
	const collectionHistoricalPositions = useCollectionHistoricalPositions(collectionId || "", state.graphDays, dateStr);

	return (
		<div className={styles.graph}>
			<HistoricalPositionsGraph
				historicalPositions={collectionHistoricalPositions.data}
				loading= {collectionHistoricalPositions.isFetching} />
		</div>
	);
};
