import { Money, ProgressSummarySortOrder } from "../../domain";
import { HTTPAuthMode, InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";

export interface ProgressSummaryResponseModel {
	readonly variantId: string;
	readonly productId: string;
	readonly fcId: string;
	readonly productName: string;
	readonly imageUrl: string;
	readonly goalQuantity: number;
	readonly actualQuantity: number;
	readonly marketValue: Money;
}

export const useProgressSummaries = (collectionId: string, includeCompleted: boolean, sortOrder: ProgressSummarySortOrder) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQueryWithCursor(["progressSummaries", collectionId, includeCompleted, sortOrder], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		return http<PaginatedResponseModel<ProgressSummaryResponseModel>>({
			method: "GET",
			url: `/collection/${collectionId}/progress-summaries?cursor=${pageParam}&includeCompleted=${includeCompleted}&sortOrder=${sortOrder}`
		});
	});
};
