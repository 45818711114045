import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { PlanModel } from "./models/PlanModel";

export const usePlans = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["plans"], () => {
		return http<PlanModel[]>({
			method: "GET",
			url: "/plan"
		});
	});
};
