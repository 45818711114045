import styles from "./PrettyMoney.module.scss";
import { Currency, Money, usePreferredCurrency } from "@fyendalscollection/shared";

type SharedProps = {
	className?: string;
	money: Money;
} & ({
	mode: MoneyDisplayMode.DeltaPercentage;
	originalValue: number;
} | {
	mode: Exclude<MoneyDisplayMode, MoneyDisplayMode.DeltaPercentage>;
});

export type PrettyMoneyProps = SharedProps & { preferredCurrency?: Currency };
type ApiPrettyMoneyProps = SharedProps;
type RenderedPrettyMoneyProps = SharedProps & { preferredCurrency: Currency };

export enum MoneyDisplayMode {
	Standard,
	Extended,
	Colored,
	Delta,
	DeltaPercentage
}

export const PrettyMoney = (props: PrettyMoneyProps): JSX.Element => {
	return (
		props.preferredCurrency
			? <RenderedPrettyMoney preferredCurrency={props.preferredCurrency} {...props} />
			: <ApiPrettyMoney {...props} />
	);
};

const ApiPrettyMoney = (props: ApiPrettyMoneyProps): JSX.Element => {
	const preferredCurrency = usePreferredCurrency();
	const currency = preferredCurrency.data ? preferredCurrency.data.currency : Currency.USD;

	return (
		<RenderedPrettyMoney preferredCurrency={currency} {...props} />
	);
};

const RenderedPrettyMoney = (props: RenderedPrettyMoneyProps): JSX.Element => {
	const positiveNumber = turnNegativeNumIntoPositiveNum(props.money.value);
	const positiveMoney = {
		value: positiveNumber,
		currency: props.money.currency
	};

	const prefix = getPrefixForCurrency(props.money.currency);
	const formattedValue = formatCurrencyString(positiveMoney, props.mode);

	let text = props.money.currency !== props.preferredCurrency
		? prefix + formattedValue
		: formattedValue;

	if (props.money.value < 0) {
		text = "-" + text;
	}

	const getClassNames = () => {
		if (props.mode === MoneyDisplayMode.Colored || props.mode === MoneyDisplayMode.Delta || props.mode === MoneyDisplayMode.DeltaPercentage) {
			return getClassNamesForValue(props.money.value, props.className);
		}
		return props.className;
	};

	const getPrefixForDelta = (text: string): string => {
		const mode = props.mode === MoneyDisplayMode.DeltaPercentage && (!props.originalValue || !props.money.value)
			? MoneyDisplayMode.Delta
			: props.mode;

		if (mode === MoneyDisplayMode.DeltaPercentage && props.mode === MoneyDisplayMode.DeltaPercentage) {
			const percentageValue = props.money.value / props.originalValue * 100;
			if (props.money.value > 0) {
				return `+${text} (${formatPercentage(percentageValue)})`;
			}
			return `${text} (${formatPercentage(percentageValue)})`;
		}

		if (mode === MoneyDisplayMode.Delta) {
			if (props.money.value > 0) {
				return "+" + text;
			}
		}
		return text;
	};

	return (
		<span className={getClassNames()}>
			<div className={styles.default}>
				{getPrefixForDelta(text)}
			</div>
		</span>
	);
};

const formatPercentage = (value: number): string => {
	if (value % 1 != 0) {
		return value.toFixed(1) + "%";
	}
	return value + "%";
};

const turnNegativeNumIntoPositiveNum = (num: number): number => {
	return Math.abs(num);
};

const formatCurrencyString = (money: Money, mode: MoneyDisplayMode): string => {
	return money.value.toLocaleString(undefined, {
		style: "currency",
		currency: money.currency, currencyDisplay: "narrowSymbol",
		maximumFractionDigits: mode == MoneyDisplayMode.Extended ? 5
			: money.currency === Currency.IDR || money.currency === Currency.JPY ? 0 : undefined
	});
};

const getPrefixForCurrency = (currency: Currency): string => {
	switch (currency) {
	case Currency.AUD:
		return "AU";
	case Currency.NZD:
		return "NZ";
	case Currency.CAD:
		return "CA";
	case Currency.SGD:
		return "SG";
	case Currency.USD:
		return "US";
	default:
		return "";
	}
};

const getClassNamesForValue = (value: number, className: string | undefined): string => {
	const classNames: string[] = [ ];
	if (className) {
		classNames.push(className);
	}

	if (value > 0) {
		classNames.push(styles.positive);
	}

	if (value < 0) {
		classNames.push(styles.negative);
	}

	if (value === 0) {
		classNames.push(styles.neutral);
	}

	return classNames.join(" ");
};
