import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { EmblemResponseModel } from "./models";

export const useEmblem = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["emblem"], () => {
		return http<EmblemResponseModel>({
			method: "GET",
			url: "/emblem/active"
		});
	});
};
