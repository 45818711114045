import styles from "./TitleSecondary.module.scss";

export interface TitleSecondaryProps {
	text: string;
}

export const TitleSecondary = ({ text }: TitleSecondaryProps): JSX.Element => {
	return (
		<div className={styles.holder}>
			<div className={styles.title}>{text}</div>
		</div>
	);
};
