import {  useEffect, useState } from "react";
import plus from "@fyendalscollection/app/assets/plus.svg";
import styles from "./ModalCreateSubscription.module.scss";
import { usePaymentCards, useSubscriptionCreate } from "@fyendalscollection/shared";
import { PlanSelector, PlanWithPromoCode } from "@fyendalscollection/app/features/subscriptions/components/PlanSelector";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { PaymentCardSelector } from "@fyendalscollection/app/lib/components/PaymentCardSelector";
import { ContainerListItem, ImageSize } from "@fyendalscollection/app/lib/components/ContainerListItem";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";

export interface ModalCreateSubscriptionProps {
	visible: boolean;
	onCancel: () => void;
	showAddPaymentCard: () => void;
}

export const ModalCreateSubscription = ({ visible, onCancel, showAddPaymentCard }: ModalCreateSubscriptionProps): JSX.Element => {
	const [selectedPaymentCardId, setSelectedPaymentCardId] = useState<string | null>(null);
	const [planWithPromoCode, setPlanWithPromoCode] = useState<PlanWithPromoCode | null>(null);

	const cards = usePaymentCards();
	const subscriptionCreate = useSubscriptionCreate();

	useEffect(() => {
		if (subscriptionCreate.isSuccess) {
			onCancel();
			setSelectedPaymentCardId(null);
		}
	}, [subscriptionCreate.isSuccess]);

	const confirm = () => {
		if(selectedPaymentCardId && planWithPromoCode) {
			subscriptionCreate.mutate({
				planId: planWithPromoCode.planId,
				paymentCardId: selectedPaymentCardId,
				promoCode: planWithPromoCode.promoCode
			});
		}
	};

	return (
		<Modal visible={visible} onCancel={onCancel}>
			<Loadable loading={subscriptionCreate.isLoading || cards.isLoading}>
				<TitleSecondary text="Subscribe to a plan" />

				<Paragraph>
					Please select which plan you wish to subscribe to and a payment
					card you want to use. Subscription renewal charges will occur 2
					days before the auto renewal date. <br/> <br/>
					By starting your subscription, you agree to our <a target="_blank" rel="noreferrer" className={styles.link} href={"https://fyendalscollection.com/terms-and-conditions"}>Terms and Conditions</a>.
				</Paragraph>

				<PlanSelector onPlanSelected={x => setPlanWithPromoCode(x)} selectedPlanWithPromoCode={planWithPromoCode}/>

				{subscriptionCreate.isError &&
					<Container transparent={true}>
						<ContainerSegment>
							<ErrorDisplay message={subscriptionCreate.error.problem.description} />
						</ContainerSegment>
					</Container>
				}

				<PaymentCardSelector selectedPaymentCardId={selectedPaymentCardId} setSelectedPaymentCardId={setSelectedPaymentCardId} />

				<Container>
					<ContainerSegment onClick={showAddPaymentCard}>
						<ContainerListItem
							title="Add Payment Card"
							image={plus}
							imageSize={ImageSize.Small} />
					</ContainerSegment>
				</Container>

				<Container transparent={true}>
					<GreenButtonSet buttons={[
						{
							text: "Start Subscription",
							onClick: confirm,
							disabled: !planWithPromoCode || !selectedPaymentCardId
						}
					]} />
				</Container>
			</Loadable>
		</Modal>
	);
};
