import "./Calendar.scss";
import ReactCalendar from "react-calendar";

export interface CalendarProps {
	selectedDate: Date;
	maxDate?: Date;
	minDate?: Date;
	onChange: (value: Date) => void;
}

export const Calendar = ({ selectedDate, maxDate, minDate, onChange }: CalendarProps): JSX.Element => {
	return (
		<ReactCalendar
			className="calendar"
			maxDate={maxDate}
			minDate={minDate}
			value={selectedDate}
			onChange={value => {
				if (!value) {
					return;
				}

				onChange(value as Date);
			}} />
	);
};
