import { useEffect, useState } from "react";
import { useFocus } from "@fyendalscollection/app/lib/useFocus";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { TextAreaEntry } from "@fyendalscollection/app/lib/components/TextAreaEntry";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { useSubscriptionDelete } from "@fyendalscollection/shared";

export interface ModalCancelAutoRenewProps {
	visible: boolean;
	onCancel: () => void;
}

export const ModalCancelAutoRenew = (props: ModalCancelAutoRenewProps) => {
	const [cancellationReason, setCancellationReason] = useState("");
	const [ref, focus] = useFocus<HTMLTextAreaElement>();
	const deleteSubscription = useSubscriptionDelete();

	useEffect(() => {
		if (props.visible) {
			focus();
		}
	}, [props.visible]);

	useEffect(() => {
		if (deleteSubscription.isSuccess) {
			props.onCancel();
		}
	}, [deleteSubscription.isSuccess]);

	const onConfirm = () => deleteSubscription.mutate({ cancellationReason: cancellationReason.trim() });

	return (
		<>
			<Modal visible={props.visible} onCancel={props.onCancel}>
				<TitleSecondary text={"Cancel Auto Renew"} />

				<Paragraph>
					Are you sure? Any time remaining on your existing subscription will be preserved.
				</Paragraph>

				<Loadable loading={deleteSubscription.isLoading}>
					<Container>
						<ContainerSegment>
							<TextAreaEntry
								ref={ref}
								maxLength={500}
								onChange={setCancellationReason}
								value={cancellationReason}
								placeholder={"(Optional) Do you mind sharing with us why you're cancelling?"} />
						</ContainerSegment>
					</Container>

					{
						deleteSubscription.error &&
						<Container>
							<ContainerSegment>
								<ErrorDisplay problemResponse={deleteSubscription.error} />
							</ContainerSegment>
						</Container>
					}

					<GreenButtonSet buttons={[
						{
							text: "Confirm",
							onClick: onConfirm
						},
						{
							text: "Cancel",
							onClick: props.onCancel
						}
					]} />
				</Loadable>
			</Modal>
		</>
	);
};
