import React, { ForwardedRef } from "react";
import styles from "./TextAreaEntry.module.scss";

interface TextAreaEntryProps {
	onChange: (text: string) => void;
	value: string;
	placeholder: string;
	maxLength?: number;
}

const TextAreaEntryInternal = (props: TextAreaEntryProps, ref: ForwardedRef<HTMLTextAreaElement>) => {

	return (
		<div className={styles.textAreaEntry}>
			<textarea
				ref={ref}
				maxLength={props.maxLength}
				onChange={(e) => props.onChange(e.target.value)}
				value={props.value}
				placeholder={props.placeholder} />
		</div>
	);
};

export const TextAreaEntry = React.forwardRef<HTMLTextAreaElement, TextAreaEntryProps>(TextAreaEntryInternal);
