import { useEffect, useState } from "react";
import styles from "./Modal.module.scss";

export interface ModalProps {
	children: React.ReactNode;
	visible: boolean;
	padded?: boolean;
	elevation?: number;
	onCancel: () => void;
}

export const Modal = (props: ModalProps): JSX.Element => {
	const {
		children,
		visible,
		padded = true,
		elevation,
		onCancel
	} = props;

	const [hasVisibleClass, setHasVisibleClass] = useState(false);
	const [hasDisplayedClass, setHasDisplayedClass] = useState(false);

	useEffect(() => {
		if (visible) {
			setHasDisplayedClass(true);

			const timeout = setTimeout(() => setHasVisibleClass(true), 20);
			return () => clearTimeout(timeout);
		} else {
			setHasVisibleClass(false);

			const timeout = setTimeout(() => setHasDisplayedClass(false), 400);
			return () => clearTimeout(timeout);
		}
	}, [visible]);

	const backDropStyles = [ styles.backDrop ];
	if (hasVisibleClass) {
		backDropStyles.push(styles.visible);
	}
	if (hasDisplayedClass) {
		backDropStyles.push(styles.displayed);
	}

	const modalStyles = [ styles.modal ];
	if (padded) {
		modalStyles.push(styles.padding);
	}

	return (
		<div className={backDropStyles.join(" ")} style={{ zIndex: elevation || 100 }} onClick={onCancel}>
			<div className={modalStyles.join(" ")} onClick={e => e.stopPropagation()}>
				{children}
			</div>
		</div>
	);
};
