import { MutationFunction, useQueryClient } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";
import { Money } from "../../domain";

export interface OperationNormaliseUnitAmountRequestModel {
	normalisedUnitAmount: Money;
}

export interface OperationNormaliseUnitAmountProps {
	requestModel: OperationNormaliseUnitAmountRequestModel;
	collectionId: string;
}

export const useOperationNormaliseUnitAmount = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, OperationNormaliseUnitAmountProps> = (requestProperties: OperationNormaliseUnitAmountProps) => {
		return http<undefined, OperationNormaliseUnitAmountRequestModel>({
			method: "POST",
			url: "/collection/" + requestProperties.collectionId + "/operation/normalise-unit-amount",
			body: requestProperties.requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (_, requestProperties) => {
			queryClient.invalidateQueries(["collections"]);
			queryClient.invalidateQueries(["collection", requestProperties.collectionId]);
			queryClient.invalidateQueries(["collectionVariantDetails", requestProperties.collectionId]);
			queryClient.invalidateQueries(["dashboardCurrentPosition"]);
			queryClient.invalidateQueries(["dashboardHistoricalPositions"]);
			queryClient.invalidateQueries(["transactionSummaries"]);
			queryClient.invalidateQueries(["transactions", requestProperties.collectionId]);
		}
	});
};
