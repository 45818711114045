import { useEffect, useState } from "react";
import planIcon from "@fyendalscollection/app/assets/plan.svg";
import promoIcon from "@fyendalscollection/app/assets/promo.svg";
import styles from "./PlanSelector.module.scss";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { Currency, usePlanForPromoCode, usePlans } from "@fyendalscollection/shared";
import { Container } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { ModalTextEntry } from "@fyendalscollection/app/lib/components/ModalTextEntry";

export interface PlanSelectorProps {
	onPlanSelected: (planWithPromoCode: PlanWithPromoCode) => void;
	selectedPlanWithPromoCode: PlanWithPromoCode | null;
}

export interface PlanWithPromoCode {
	planId: string;
	promoCode?: string;
}

export const PlanSelector = (props: PlanSelectorProps): JSX.Element => {
	const planPromoCode = usePlanForPromoCode();
	const plans = usePlans();

	const [modalVisible, setModalVisible] = useState(false);

	useEffect(() => {
		if (!planPromoCode.data) {
			return;
		}

		props.onPlanSelected({ planId: planPromoCode.data.body.planId, promoCode: planPromoCode.variables });
	}, [planPromoCode.data]);

	const displayPromoPlan = planPromoCode.data
		? (
			<div className={props.selectedPlanWithPromoCode?.planId === planPromoCode.data.body.planId ? styles.selectedPlan : undefined} onClick={() => props.onPlanSelected({ planId: planPromoCode.data.body.planId, promoCode: planPromoCode.variables })}>
				<img src={promoIcon}></img>
				<div>
					<div className={styles.planName}>{planPromoCode.data.body.name}</div>
					<div className={styles.planPrice}>
						<PrettyMoney mode={MoneyDisplayMode.Standard} money={{ currency: Currency.USD, value: planPromoCode.data.body.price }} />
					</div>
				</div>
			</div>
		) : (
			<div onClick={() => setModalVisible(true)}>
				<img src={promoIcon}></img>
				<div>
					<div className={styles.planName}>Enter Promo Code</div>
				</div>
			</div>
		);

	return (
		<>
			<div className={styles.planSelector}>
				{(plans.data || []).map(plan => (
					<div className={props.selectedPlanWithPromoCode && props.selectedPlanWithPromoCode.planId === plan.planId ? styles.selectedPlan : undefined} key={plan.planId} onClick={() => props.onPlanSelected(plan)}>
						<img src={planIcon}></img>
						<div>
							<div className={styles.planName}>{plan.name}</div>
							<div className={styles.planPrice}>
								<PrettyMoney mode={MoneyDisplayMode.Standard} money={{ currency: Currency.USD, value: plan.price }} />
							</div>
						</div>
					</div>
				))}

				{displayPromoPlan}
			</div>

			{
				planPromoCode.error &&
				<Container transparent={true}>
					<ErrorDisplay problemResponse={planPromoCode.error} />
				</Container>
			}

			<ModalTextEntry
				title="Promo Code"
				description="Please enter your promo code"
				visible={modalVisible}
				onCancel={() => setModalVisible(false)}
				onConfirm={promoCode => planPromoCode.mutate(promoCode)} />
		</>
	);
};
