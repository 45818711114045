import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PremiumPromotionContainer } from "@fyendalscollection/app/lib/components/PremiumPromotionContainer";
import { API_BASE_URL } from "@fyendalscollection/app/lib/config";
import { useCollection, useCreateShortToken } from "@fyendalscollection/shared";
import { useSubscription } from "@fyendalscollection/shared";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { Checkbox } from "@fyendalscollection/app/lib/components/Checkbox";

export const PageCollectionsCSVExport = (): JSX.Element => {
	const { collectionId } = useParams();
	const collection = useCollection(collectionId || "");
	const createShortToken = useCreateShortToken();
	const navigate = useNavigate();
	const subscription = useSubscription();

	const [quantity, setQuantity] = useState(true);
	const [fcId, setFcId] = useState(true);
	const [description, setDescription] = useState(true);
	const [rarity, setRarity] = useState(true);
	const [totalValue, setTotalValue] = useState(true);
	const [unitValue, setUnitValue] = useState(true);
	const [includeTotals, setIncludeTotals] = useState(true);

	useEffect(() => {
		createShortToken.mutate();

		const timer = setTimeout(() => navigate(".."), 9 * 60 * 60 * 1000);
		return () => clearTimeout(timer);
	}, []);

	const getFieldList = (): string => {
		const fieldList = [];
		if (!quantity) {
			fieldList.push("quantity");
		}
		if (!fcId) {
			fieldList.push("fcId");
		}
		if (!description) {
			fieldList.push("description");
		}
		if (!rarity) {
			fieldList.push("rarity");
		}
		if (!totalValue) {
			fieldList.push("totalValue");
		}
		if (!unitValue) {
			fieldList.push("unitValue");
		}
		return fieldList.join(",");
	};

	return (
		<Limit force={LimitWidth.Medium}>
			{!collection.data
				? <></>
				: <>
					<Breadcrumb crumbs={[{
						text: "Dashboard",
						link: "/dashboard"
					},
					{
						text: "Collections",
						link: "/collections"
					},
					{
						text: collection.data.name,
						link: `/collections/${collectionId}`
					},
					{
						text: "CSV Export"
					}]} />

					<PageTitle
						title="CSV Export"
						subTitle="Select the fields you want to export"
						primaryActions={
							<GreenButtonSet buttons={[
								{
									text: "Confirm",
									link: `${API_BASE_URL}/collection/${collectionId}/csv/stateful?omit=${getFieldList()}&shortToken=${createShortToken.data?.body.shortToken}&includeTotals=${includeTotals}`,
									disabled: (!quantity && !fcId && !description && !rarity && !totalValue && !unitValue) || !createShortToken.data || !subscription.data?.activeSubscription,
									newTabLink: true,
									onClick: () => navigate("..")
								}
							]} />
						} />

					{
						subscription.data && !subscription.data.activeSubscription &&
						<PremiumPromotionContainer featureName="CSV Export" />
					}

					<Container title="Fields">
						<ContainerSegment>
							<Checkbox
								label="Quantity"
								onChange={setQuantity}
								isChecked={quantity} />
						</ContainerSegment>
						<ContainerSegment>
							<Checkbox
								label="Identifier"
								onChange={setFcId}
								isChecked={fcId} />
						</ContainerSegment>
						<ContainerSegment>
							<Checkbox
								label="Description"
								onChange={setDescription}
								isChecked={description} />
						</ContainerSegment>
						<ContainerSegment>
							<Checkbox
								label="Rarity"
								onChange={setRarity}
								isChecked={rarity} />
						</ContainerSegment>
						<ContainerSegment>
							<Checkbox
								label="Unit Value"
								onChange={setUnitValue}
								isChecked={unitValue} />
						</ContainerSegment>
						<ContainerSegment>
							<Checkbox
								label="Total Value"
								onChange={setTotalValue}
								isChecked={totalValue} />
						</ContainerSegment>
					</Container>

					<Container title="Options">
						<ContainerSegment>
							<Checkbox
								label="Include Totals"
								onChange={setIncludeTotals}
								isChecked={includeTotals} />
						</ContainerSegment>
						<ContainerSegment>
							When enabled, a final row of total values will be added to the CSV for any numeric columns.  The total in the Unit Market Value column will be the same as the Total Market Value.
						</ContainerSegment>
					</Container>
				</>
			}
		</Limit>
	);
};
