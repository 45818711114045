import { ScreenSizeView, useScreenSize } from "@fyendalscollection/app/lib/ScreenSizeContext";

export interface ScreenSizeProps {
	children: React.ReactNode;
	screenSizeView: ScreenSizeView;
}

export const ScreenSize = (props: ScreenSizeProps): JSX.Element => {
	const screenSize = useScreenSize();

	const showDesktopTabletView = props.screenSizeView === ScreenSizeView.DesktopAndTablet &&
		(screenSize === ScreenSizeView.Tablet || screenSize === ScreenSizeView.Desktop);

	return screenSize === props.screenSizeView || showDesktopTabletView
		? <>{props.children}</>
		: <></>;
};
