import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { NotificationModel } from "./models";

export const useNotification = (notificationId: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["notification", notificationId], () => {
		return http<NotificationModel>({
			method: "GET",
			url: `/notification/${notificationId}`
		});
	});
};
