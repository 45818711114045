import { MutationFunction } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";
import { Currency } from "../../domain";

export interface AuthRegisterRequestModel {
	emailAddress: string;
	password: string;
	displayName: string;
	preferredCurrency: Currency;
	registerPassword?: string;
	attribution?: string;
}

export interface AuthRegisterResponseModel {
	userId: string;
}

export const useAuthRegister = () => {
	const http = useHTTP(HTTPAuthMode.None);

	const mutFunc: MutationFunction<Response<AuthRegisterResponseModel>, AuthRegisterRequestModel> = requestModel => {
		return http<AuthRegisterResponseModel, AuthRegisterRequestModel>({
			method: "POST",
			url: "/auth/register",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc);
};
