import React from "react";
import { StorageService } from "../storage/StorageService";

export interface StorageProviderProps {
	storageService: StorageService;
	children: React.ReactNode;
}

export const StorageProvider = ({ storageService, children }: StorageProviderProps): JSX.Element => {
	return (
		<StorageContext.Provider value={storageService}>
			{children}
		</StorageContext.Provider>
	);
};

export const StorageContext = React.createContext<StorageService | null>(null);
export const useStorage = () => React.useContext(StorageContext) as StorageService;
