
import { UseMutationResult, UseQueryResult } from "react-query";
import { ProblemResponse } from "@fyendalscollection/shared";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";

export interface SmartBoxProps<T> {
	switchOn: UseQueryResult<T, ProblemResponse> | UseMutationResult<T, ProblemResponse>;
	loading?: () => JSX.Element;
	success?: (data: T) => JSX.Element;
	error?: (error: ProblemResponse) => JSX.Element;
}

export const SmartBox = <T, >(props: SmartBoxProps<T>): JSX.Element => {
	const {
		switchOn,
		loading,
		success,
		error
	} = props;

	if (loading && switchOn.isLoading) {
		return loading();
	}

	if (success && switchOn.isSuccess) {
		return success(switchOn.data);
	}

	if (switchOn.isError) {
		return error
			? error(switchOn.error)
			: <ErrorDisplay problemResponse={switchOn.error}/>;
	}

	return <></>;
};
