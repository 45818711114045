import { EvProductType, EvSetType, EvSort, EvStep, Money } from "../../domain";
import { HTTPAuthMode, InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";

export interface EvItemResponseModel {
	readonly setType: EvSetType;
	readonly productType: EvProductType;
	readonly productId: string;
	readonly productImage: string;
	readonly productName: string;
	readonly fcId: string;
	readonly delta: Money;
	readonly currentEstimatedValue: Money;
	readonly previousEstimatedValue: Money;
	readonly marketPrice: Money;
	readonly marketPriceDelta: Money;
}

export const useEvItems = (step: EvStep, sort: EvSort, productType: EvProductType | null) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQueryWithCursor(["ev", step, sort, productType], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		const url = `/ev?sort=${sort}&step=${step}&cursor=${pageParam}` + (productType ? `&productType=${productType}` : "");
		return http<PaginatedResponseModel<EvItemResponseModel>>({
			method: "GET",
			url
		});
	});
};
