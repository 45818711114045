import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import styles from "./SubscriptionPlan.module.scss";
import { Currency } from "@fyendalscollection/shared";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";

export interface SubscriptionPlanProps {
	title: string;
	description: string;
	amount: number;
	children: React.ReactNode;
	subscribeButton?: () => void;
	disabled?: boolean;
	discounted?: boolean;
}

export const SubscriptionPlan = (props: SubscriptionPlanProps): JSX.Element => {
	const subscribeButtonVisible = props.subscribeButton
		? <GreenButtonSet buttons={[
			{
				text: "Subscribe",
				onClick: props.subscribeButton,
				disabled: props.disabled
			}
		]} />
		: <></>;

	const bannerDisplay = props.disabled ? <div className={styles.bannerDisplay}>Coming Soon</div> : <></>;

	const classNames = [styles.moneyValue];
	if (props.discounted) {
		classNames.push(styles.moneyValueDiscount);
	}

	return (
		<div className={styles.subscriptionDetailsContainer + (props.disabled ? " " + styles.disabled : "")}>
			<div>
				<div className={styles.subscriptionDetails}>
					<div className={styles.title}>{props.title}</div>
					<div>{props.description}</div>
					<div className={styles.moneyHolder}>
						<PrettyMoney className={classNames.join(" ")} money={{value: props.amount, currency: Currency.USD}} mode={MoneyDisplayMode.Standard}/>
					</div>
					<div className={styles.subtext}>per month</div>
					{bannerDisplay}
				</div>
			</div>
			<div className={styles.children}>
				{props.children}
			</div>
			{subscribeButtonVisible}
		</div>
	);
};
