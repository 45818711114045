import { ProblemResponse } from "@fyendalscollection/shared";
import styles from "./ErrorIndicator.module.scss";
import errorIcon from "@fyendalscollection/app/assets/error-bubble.svg";

export interface ErrorIndicatorProps {
	error: ProblemResponse | string | null;
}

export const ErrorIndicator = ({ error }: ErrorIndicatorProps): JSX.Element => {
	return (
		<>
			<div className={styles.errorIndicator} tabIndex={0}>
				{
					error && (
						<>
							<img src={errorIcon} />
							<div className={styles.errorIndicatorPopUp}>{typeof error === "string" ? error : error.problem.description}</div>
						</>
					)
				}
			</div>
		</>
	);
};
