import sadFace from "@fyendalscollection/app/assets/sad-face.svg";
import { BannerMessage } from "@fyendalscollection/app/lib/components/BannerMessage";
import { Container } from "@fyendalscollection/app/lib/components/Container";

export const PageNotFound = () => {
	return (
		<Container>
			<BannerMessage image={sadFace} title="Page Not Found" content="Uh oh!  Are you lost?"/>
		</Container>
	);
};
