import { MutationFunction, useQueryClient } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface UpdateImportRowRequestModel {
	readonly importRowId: string;
	readonly quantityText: string;
	readonly fcIdText: string;
	readonly actionText: string;
	readonly unitAmountText: string;
	readonly currencyText: string;
	readonly dateText: string;
}

export const useUpdateImportRow = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, UpdateImportRowRequestModel> = async requestModel => {
		return http<undefined, UpdateImportRowRequestModel>({
			method: "PUT",
			url: `/import-row/${requestModel.importRowId}`,
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: () => {
			queryClient.invalidateQueries(["importStatus"]);
		}
	});
};
