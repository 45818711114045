import { MutationFunction, useQueryClient } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";
import { EmblemResponseModel } from "./models";

export const useRoll = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<EmblemResponseModel>> = () => {
		return http<EmblemResponseModel>({
			method: "POST",
			url: "/emblem/roll",
			body: undefined
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: () => {
			setTimeout(() => {
				queryClient.invalidateQueries(["userEmblemRolls"]);
				queryClient.invalidateQueries(["emblems"]);
				queryClient.invalidateQueries(["emblemStats"]);
			}, 2500);
		}
	});
};
