import { Money } from "../../domain";
import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface GetProductAndVariantsMarketPriceResponseModel {
	productId: string;
	tcgPlayerProductId: number;
	name: string;
	number?: string;
	imageUrl: string;
	description: string;
	cardType?: string;
	cardSubType?: string;
	class?: string;
	rarity?: string;
	cost?: string;
	defenseValue?: string;
	pitchValue?: string;
	talent?: string;
	power?: string;
	intellect?: string;
	life?: string;
	variants: VariantMarketPriceResponseModel[];
}

export interface VariantMarketPriceResponseModel {
	variantId: string;
	variantName: string;
	marketPrice: Money | null;
	fcId: string;
	saleVolumes: number[];
}

export const useProduct = (productId: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["product", productId], () => {
		return http<GetProductAndVariantsMarketPriceResponseModel>({
			method: "GET",
			url: `/product/${productId}`
		});
	});
};
