import { MutationFunction, useQueryClient } from "react-query";
import { CollectionModel } from "./models/CollectionModel";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";
import { PrepopulateFilterFlags, PrepopulateSet } from "../../domain";

export interface CreatePrepopulatedCollectionRequestModel {
	name: string;
	sets: PrepopulateSet[];
	filterFlags: PrepopulateFilterFlags;
	quantity: number;
	forceEquipmentWeaponQuantity: boolean;
	forceColdFoilQuantity: boolean;
}

export const useCreatePrepopulatedCollection = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<CollectionModel>, CreatePrepopulatedCollectionRequestModel> = requestModel => {
		return http<CollectionModel, CreatePrepopulatedCollectionRequestModel>({
			method: "POST",
			url: "/collection/prepopulated",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: () => {
			queryClient.invalidateQueries(["collections"]);
		}
	});
};
