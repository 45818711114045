import styles from "./GraphTooltip.module.scss";

interface Label {
	label: string;
	value: number | string;
}
interface GraphTooltipProps {
	color: string;
	labels: Label[];
}

export const GraphTooltip = ({ color, labels }: GraphTooltipProps) => (
	<div className={styles.tooltipContainer}>
		<div className={styles.tooltipColorBlock} style={{ backgroundColor: color }} />
		{labels.map((x) => (
			<div key={x.label} className={styles.tooltipData}>
				{x.label}: <b>{x.value}</b>
			</div>
		))}
	</div>
);
