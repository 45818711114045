import { MutationFunction } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface RemoveMfaSecretRequestModel {
	password: string;
	mfaCode: string;
}

export const useRemoveMfaSecret = () => {
	const http = useHTTP(HTTPAuthMode.Required);

	const mutFunc: MutationFunction<Response<undefined>, RemoveMfaSecretRequestModel> = requestModel => {
		return http<undefined, RemoveMfaSecretRequestModel>({
			method: "POST",
			url: "/mfa/remove",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc);
};
