export enum Currency {
	AUD = "AUD",
	CAD = "CAD",
	EUR = "EUR",
	GBP = "GBP",
	IDR = "IDR",
	NZD = "NZD",
	SGD = "SGD",
	USD = "USD",
	JPY = "JPY"
}

export const getCurrencySymbol = (currency: Currency): string => {
	switch (currency) {
	case Currency.EUR:
		return "€";
	case Currency.GBP:
		return "£";
	case Currency.IDR:
		return "Rp";
	case Currency.JPY:
		return "¥";
	default:
		return "$";
	}
};
