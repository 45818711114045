import { MutationFunction } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface AuthEmailVerifyRequestModel {
	userId: string;
	emailVerifyCode: string;
}

export const useAuthEmailVerify = () => {
	const http = useHTTP(HTTPAuthMode.None);

	const mutFunc: MutationFunction<Response, AuthEmailVerifyRequestModel> = requestModel => {
		return http<undefined, AuthEmailVerifyRequestModel>({
			method: "POST",
			url: "/auth/verify",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc);
};
