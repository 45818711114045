import { QueryClient, QueryKey } from "react-query";
import { paginatedPrepend } from "./paginatedPrepend";

export const prependToQueryKeyIfExists = <T>(queryClient: QueryClient, queryKey: QueryKey, data: T) => {
	if (!queryClient.getQueryData(queryKey)) {
		return;
	}

	queryClient.setQueryData(queryKey, paginatedPrepend(data));
};
