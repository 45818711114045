import styles from "./Emblem.module.scss";
import { getGoldenThresholdForEmblemRarity } from "@fyendalscollection/app/features/emblems/getGoldenThresholdForEmblemRarity";
import accentGolden from "@fyendalscollection/app/assets/emblems/GOLDEN_ACCENT.png";
import borderCommon from "@fyendalscollection/app/assets/emblems/borders/BORDER_COMMON.png";
import borderRare from "@fyendalscollection/app/assets/emblems/borders/BORDER_RARE.png";
import borderMajestic from "@fyendalscollection/app/assets/emblems/borders/BORDER_MAJESTIC.png";
import borderLegendary from "@fyendalscollection/app/assets/emblems/borders/BORDER_LEGENDARY.png";
import borderFabled from "@fyendalscollection/app/assets/emblems/borders/BORDER_FABLED.png";
export interface EmblemProps {
	emblemId: string;
	rarity: EmblemRarity;
	count: number;
	onClick?: () => void;
}

export const Emblem = (props: EmblemProps): JSX.Element => {
	const {
		emblemId,
		rarity,
		count,
		onClick
	} = props;

	const classNames = [styles.emblem];
	if (count > 0) {
		classNames.push(styles.owned);
	}
	if (onClick) {
		classNames.push(styles.clickable);
	}

	return (
		<div className={classNames.join(" ")} onClick={onClick}>
			{ count >= getGoldenThresholdForEmblemRarity(rarity) && <img src={accentGolden} />}
			<img src={getImageForEmblemId(emblemId)} />
			<img src={getBorderForRarity(rarity)} />
		</div>
	);
};

const getBorderForRarity = (rarity: EmblemRarity): string => {
	switch (rarity) {
	case EmblemRarity.Rare:
		return borderRare;
	case EmblemRarity.Majestic:
		return borderMajestic;
	case EmblemRarity.Legendary:
		return borderLegendary;
	case EmblemRarity.Fabled:
		return borderFabled;
	default:
		return borderCommon;
	}
};

const getImageForEmblemId = (emblemId: string): string => {
	switch (emblemId) {
	case "HEART_OF_FYENDAL":
		return image_HEART_OF_FYENDAL;
	case "FYENDALS_SPRING_TUNIC":
		return image_FYENDALS_SPRING_TUNIC;
	case "EMPEROR_DRACAI_OF_AESIR":
		return image_EMPEROR_DRACAI_OF_AESIR;
	case "LEVIA_REDEEMED":
		return image_LEVIA_REDEEMED;
	case "TEKLOVOSSEN_THE_MECHROPOTENT":
		return image_TEKLOVOSSEN_THE_MECHROPOTENT;
	case "ENIGMA_NEW_MOON":
		return image_ENIGMA_NEW_MOON;
	case "ENLIGHTENED_STRIKE":
		return image_ENLIGHTENED_STRIKE;
	case "ART_OF_WAR":
		return image_ART_OF_WAR;
	case "COMMAND_AND_CONQUER":
		return image_COMMAND_AND_CONQUER;
	case "CHANNEL_LAKE_FRIGID":
		return image_CHANNEL_LAKE_FRIGID;
	case "TOMELTAI":
		return image_TOMELTAI;
	case "GAMBLERS_GLOVE":
		return image_GAMBLERS_GLOVE;
	case "NOURISHING_EMPTINESS":
		return image_NOURISHING_EMPTINESS;
	case "ALPHA_RAMPAGE":
		return image_ALPHA_RAMPAGE;
	case "AETHER_WILDFIRE":
		return image_AETHER_WILDFIRE;
	case "LEAVE_NO_WITNESSES":
		return image_LEAVE_NO_WITNESSES;
	case "RIPTIDE":
		return image_RIPTIDE;
	case "DORINTHEA":
		return image_DORINTHEA;
	case "PRISM":
		return image_PRISM;
	case "DROMAI":
		return image_DROMAI;
	case "LEVIA":
		return image_LEVIA;
	case "KAYO":
		return image_KAYO;
	case "OLDHIM":
		return image_OLDHIM;
	case "VYNNSET":
		return image_VYNNSET;
	case "BRAVO":
		return image_BRAVO;
	case "IYSLANDER":
		return image_IYSLANDER;
	case "KASSAI":
		return image_KASSAI;
	case "LEXI":
		return image_LEXI;
	case "BRIAR":
		return image_BRIAR;
	case "KAVDAEN":
		return image_KAVDAEN;
	case "ARAKNI":
		return image_ARAKNI;
	case "CHANE":
		return image_CHANE;
	case "BENJI":
		return image_BENJI;
	case "FAI":
		return image_FAI;
	case "OLYMPIA":
		return image_OLYMPIA;
	case "VICTOR":
		return image_VICTOR;
	case "ANOTHOS":
		return image_ANOTHOS;
	case "CINTARI_SABER":
		return image_CINTARI_SABER;
	case "DAWNBLADE":
		return image_DAWNBLADE;
	case "HARMONIZED_KODACHI":
		return image_HARMONIZED_KODACHI;
	case "MANDIBLE_CLAW":
		return image_MANDIBLE_CLAW;
	case "ROMPING_CLUB":
		return image_ROMPING_CLUB;
	case "TEKLO_PLASMA_PISTOL":
		return image_TEKLO_PLASMA_PISTOL;
	case "DEATH_DEALER":
		return image_DEATH_DEALER;
	case "ROSETTA_THORN":
		return image_ROSETTA_THORN;
	case "SEARING_EMBERBLADE":
		return image_SEARING_EMBERBLADE;
	case "HELM_OF_ISENS_PEAK":
		return image_HELM_OF_ISENS_PEAK;
	case "SINK_BELOW":
		return image_SINK_BELOW;
	case "SNAPDRAGON_SCALERS":
		return image_SNAPDRAGON_SCALERS;
	case "FATE_FORSEEN":
		return image_FATE_FORSEEN;
	case "RAVENOUS_RABLE":
		return image_RAVENOUS_RABLE;
	case "HALO_OF_ILLUMINATION":
		return image_HALO_OF_ILLUMINATION;
	case "RAGAMUFFINS_HAT":
		return image_RAGAMUFFINS_HAT;
	case "COPPER":
		return image_COPPER;
	case "SPECTRAL_SHIELD":
		return image_SPECTRAL_SHIELD;
	case "COLD_SNAP":
		return image_COLD_SNAP;
	case "SCAR_FOR_A_SCAR":
		return image_SCAR_FOR_A_SCAR;
	case "CROUCHING_TIGER":
		return image_CROUCHING_TIGER;
	case "WATER_GLOW_LANTERNS":
		return image_WATER_GLOW_LANTERNS;
	case "BIG_BERTHA":
		return image_BIG_BERTHA;
	case "GOBLET_OF_BLOODRUN_WINE":
		return image_GOBLET_OF_BLOODRUN_WINE;
	case "WILD_RIDE":
		return image_WILD_RIDE;
	case "FIRST_TENET_OF_CHI_TIDE":
		return image_FIRST_TENET_OF_CHI_TIDE;
	case "STIR_THE_POT":
		return image_STIR_THE_POT;
	case "THE_GRAIN_THAT_TIPS_THE_SCALE":
		return image_THE_GRAIN_THAT_TIPS_THE_SCALE;
	default:
		return image_CRACKED_BAUBLE;
	}
};

import image_HEART_OF_FYENDAL from "@fyendalscollection/app/assets/emblems/images/HEART_OF_FYENDAL.png";
import image_FYENDALS_SPRING_TUNIC from "@fyendalscollection/app/assets/emblems/images/FYENDALS_SPRING_TUNIC.png";
import image_EMPEROR_DRACAI_OF_AESIR from "@fyendalscollection/app/assets/emblems/images/EMPEROR_DRACAI_OF_AESIR.png";
import image_LEVIA_REDEEMED from "@fyendalscollection/app/assets/emblems/images/LEVIA_REDEEMED.png";
import image_TEKLOVOSSEN_THE_MECHROPOTENT from "@fyendalscollection/app/assets/emblems/images/TEKLOVOSSEN_THE_MECHROPOTENT.png";
import image_ENIGMA_NEW_MOON from "@fyendalscollection/app/assets/emblems/images/ENIGMA_NEW_MOON.png";
import image_ENLIGHTENED_STRIKE from "@fyendalscollection/app/assets/emblems/images/ENLIGHTENED_STRIKE.png";
import image_ART_OF_WAR from "@fyendalscollection/app/assets/emblems/images/ART_OF_WAR.png";
import image_COMMAND_AND_CONQUER from "@fyendalscollection/app/assets/emblems/images/COMMAND_AND_CONQUER.png";
import image_CHANNEL_LAKE_FRIGID from "@fyendalscollection/app/assets/emblems/images/CHANNEL_LAKE_FRIGID.png";
import image_TOMELTAI from "@fyendalscollection/app/assets/emblems/images/TOMELTAI.png";
import image_GAMBLERS_GLOVE from "@fyendalscollection/app/assets/emblems/images/GAMBLERS_GLOVE.png";
import image_NOURISHING_EMPTINESS from "@fyendalscollection/app/assets/emblems/images/NOURISHING_EMPTINESS.png";
import image_ALPHA_RAMPAGE from "@fyendalscollection/app/assets/emblems/images/ALPHA_RAMPAGE.png";
import image_AETHER_WILDFIRE from "@fyendalscollection/app/assets/emblems/images/AETHER_WILDFIRE.png";
import image_LEAVE_NO_WITNESSES from "@fyendalscollection/app/assets/emblems/images/LEAVE_NO_WITNESSES.png";
import image_RIPTIDE from "@fyendalscollection/app/assets/emblems/images/RIPTIDE.png";
import image_DORINTHEA from "@fyendalscollection/app/assets/emblems/images/DORINTHEA.png";
import image_PRISM from "@fyendalscollection/app/assets/emblems/images/PRISM.png";
import image_DROMAI from "@fyendalscollection/app/assets/emblems/images/DROMAI.png";
import image_LEVIA from "@fyendalscollection/app/assets/emblems/images/LEVIA.png";
import image_KAYO from "@fyendalscollection/app/assets/emblems/images/KAYO.png";
import image_OLDHIM from "@fyendalscollection/app/assets/emblems/images/OLDHIM.png";
import image_VYNNSET from "@fyendalscollection/app/assets/emblems/images/VYNNSET.png";
import image_BRAVO from "@fyendalscollection/app/assets/emblems/images/BRAVO.png";
import image_IYSLANDER from "@fyendalscollection/app/assets/emblems/images/IYSLANDER.png";
import image_KASSAI from "@fyendalscollection/app/assets/emblems/images/KASSAI.png";
import image_LEXI from "@fyendalscollection/app/assets/emblems/images/LEXI.png";
import image_BRIAR from "@fyendalscollection/app/assets/emblems/images/BRIAR.png";
import image_KAVDAEN from "@fyendalscollection/app/assets/emblems/images/KAVDAEN.png";
import image_ARAKNI from "@fyendalscollection/app/assets/emblems/images/ARAKNI.png";
import image_CHANE from "@fyendalscollection/app/assets/emblems/images/CHANE.png";
import image_BENJI from "@fyendalscollection/app/assets/emblems/images/BENJI.png";
import image_FAI from "@fyendalscollection/app/assets/emblems/images/FAI.png";
import image_OLYMPIA from "@fyendalscollection/app/assets/emblems/images/OLYMPIA.png";
import image_VICTOR from "@fyendalscollection/app/assets/emblems/images/VICTOR.png";
import image_ANOTHOS from "@fyendalscollection/app/assets/emblems/images/ANOTHOS.png";
import image_CINTARI_SABER from "@fyendalscollection/app/assets/emblems/images/CINTARI_SABER.png";
import image_DAWNBLADE from "@fyendalscollection/app/assets/emblems/images/DAWNBLADE.png";
import image_HARMONIZED_KODACHI from "@fyendalscollection/app/assets/emblems/images/HARMONIZED_KODACHI.png";
import image_MANDIBLE_CLAW from "@fyendalscollection/app/assets/emblems/images/MANDIBLE_CLAW.png";
import image_ROMPING_CLUB from "@fyendalscollection/app/assets/emblems/images/ROMPING_CLUB.png";
import image_TEKLO_PLASMA_PISTOL from "@fyendalscollection/app/assets/emblems/images/TEKLO_PLASMA_PISTOL.png";
import image_DEATH_DEALER from "@fyendalscollection/app/assets/emblems/images/DEATH_DEALER.png";
import image_ROSETTA_THORN from "@fyendalscollection/app/assets/emblems/images/ROSETTA_THORN.png";
import image_SEARING_EMBERBLADE from "@fyendalscollection/app/assets/emblems/images/SEARING_EMBERBLADE.png";
import image_HELM_OF_ISENS_PEAK from "@fyendalscollection/app/assets/emblems/images/HELM_OF_ISENS_PEAK.png";
import image_SINK_BELOW from "@fyendalscollection/app/assets/emblems/images/SINK_BELOW.png";
import image_SNAPDRAGON_SCALERS from "@fyendalscollection/app/assets/emblems/images/SNAPDRAGON_SCALERS.png";
import image_FATE_FORSEEN from "@fyendalscollection/app/assets/emblems/images/FATE_FORSEEN.png";
import image_RAVENOUS_RABLE from "@fyendalscollection/app/assets/emblems/images/RAVENOUS_RABLE.png";
import image_HALO_OF_ILLUMINATION from "@fyendalscollection/app/assets/emblems/images/HALO_OF_ILLUMINATION.png";
import image_RAGAMUFFINS_HAT from "@fyendalscollection/app/assets/emblems/images/RAGAMUFFINS_HAT.png";
import image_COPPER from "@fyendalscollection/app/assets/emblems/images/COPPER.png";
import image_SPECTRAL_SHIELD from "@fyendalscollection/app/assets/emblems/images/SPECTRAL_SHIELD.png";
import image_COLD_SNAP from "@fyendalscollection/app/assets/emblems/images/COLD_SNAP.png";
import image_SCAR_FOR_A_SCAR from "@fyendalscollection/app/assets/emblems/images/SCAR_FOR_A_SCAR.png";
import image_CROUCHING_TIGER from "@fyendalscollection/app/assets/emblems/images/CROUCHING_TIGER.png";
import image_WATER_GLOW_LANTERNS from "@fyendalscollection/app/assets/emblems/images/WATER_GLOW_LANTERNS.png";
import image_BIG_BERTHA from "@fyendalscollection/app/assets/emblems/images/BIG_BERTHA.png";
import image_GOBLET_OF_BLOODRUN_WINE from "@fyendalscollection/app/assets/emblems/images/GOBLET_OF_BLOODRUN_WINE.png";
import image_WILD_RIDE from "@fyendalscollection/app/assets/emblems/images/WILD_RIDE.png";
import image_FIRST_TENET_OF_CHI_TIDE from "@fyendalscollection/app/assets/emblems/images/FIRST_TENET_OF_CHI_TIDE.png";
import image_STIR_THE_POT from "@fyendalscollection/app/assets/emblems/images/STIR_THE_POT.png";
import image_THE_GRAIN_THAT_TIPS_THE_SCALE from "@fyendalscollection/app/assets/emblems/images/THE_GRAIN_THAT_TIPS_THE_SCALE.png";
import image_CRACKED_BAUBLE from "@fyendalscollection/app/assets/emblems/images/CRACKED_BAUBLE.png";import { EmblemRarity } from "@fyendalscollection/shared";

