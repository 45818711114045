import styles from "./PagePerformanceDeltas.module.scss";
import { PremiumPromotionContainer } from "@fyendalscollection/app/lib/components/PremiumPromotionContainer";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { Link } from "react-router-dom";
import { useUserPreferencesState } from "@fyendalscollection/shared";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import { WarningContainer } from "@fyendalscollection/app/lib/components/WarningContainer";
import { PerformanceDeltaSet, PerformanceDeltaSort, PerformanceDeltaStep, PriceSource, usePerformanceDeltas, usePreferredPriceSource } from "@fyendalscollection/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { useSubscription } from "@fyendalscollection/shared";
import { Limit } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { PerformanceDeltasFilter } from "@fyendalscollection/app/features/performanceDeltas/components/PerformanceDeltasFilter";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { VolumeGraph } from "@fyendalscollection/app/lib/components/VolumeGraph";
import { ENABLE_SALE_VOLUMES } from "@fyendalscollection/app/lib/config";

export const PagePerformanceDeltas = (): JSX.Element => {
	useTitle("Market Trends");

	const { state } = useUserPreferencesState();

	const {
		performanceDeltaStep,
		performanceDeltaMinimum,
		performanceDeltaSort,
		performanceDeltaSets
	} = state;

	const subscription = useSubscription();

	return (
		<Limit>
			<Breadcrumb crumbs={[
				{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "Market Trends"
				}
			]} />

			<PageTitle
				title="Market Trends"
				subTitle="The biggest gains and losses for products over time"
				primaryActions={<PerformanceDeltasFilter includeSets={true} />} />

			{
				subscription.data && (
					subscription.data.activeSubscription
						? <PagePerformanceDeltasInternal step={performanceDeltaStep} minimum={performanceDeltaMinimum} sort={performanceDeltaSort} sets={performanceDeltaSets} />
						: <PremiumPromotionContainer featureName="Market Trends" />
				)
			}
		</Limit>
	);
};

interface PagePerformanceDeltasInternalProps {
	step: PerformanceDeltaStep;
	minimum: number;
	sort: PerformanceDeltaSort;
	sets: PerformanceDeltaSet[];
}

const PagePerformanceDeltasInternal = ({ step, minimum, sort, sets }: PagePerformanceDeltasInternalProps): JSX.Element => {
	const performanceDeltas = usePerformanceDeltas(step, minimum, sort, sets);
	const priceSource = usePreferredPriceSource();

	if (performanceDeltas.error) {
		return (
			<Container>
				<ContainerSegment>
					<ErrorDisplay problemResponse={performanceDeltas.error} />
				</ContainerSegment>
			</Container>
		);
	}

	const pages = performanceDeltas.data?.pages ?? [];
	const headers = ENABLE_SALE_VOLUMES
		? [ "Product", "Sale Volumes", "Gain", "Current Price", "Previous Price" ]
		: [ "Product", "Gain", "Current Price", "Previous Price" ];

	return (
		<>
			{
				priceSource.data && priceSource.data.priceSource === PriceSource.TcgPlayerLow &&
				<WarningContainer title="You're using TCGplayer Low Pricing">
					TCGplayer Low Pricing often has extreme outliers that make it unsuitable for use with Market Trends.  We recommend using TCGplayer Market Pricing instead.
				</WarningContainer>
			}
			<Loadable loading={performanceDeltas.isLoading}>
				<TabularData headers={headers}>
					{
						pages.flatMap(x => x.results).map(x => (
							<TabularDataItem key={x.variantId}>
								<>
									<Link className={styles.productDisplay} to={`/browse/product/${x.productId}`}>
										<ProductImage className={styles.productImage} image={x.imageUrl} />
										<div>
											<div>{x.productName}</div>
											<div>{x.fcId}</div>
										</div>
									</Link>
								</>
								{ ENABLE_SALE_VOLUMES && <><VolumeGraph data={x.saleVolumes} /></> }
								<><PrettyMoney className={styles.price} money={x.delta} mode={MoneyDisplayMode.DeltaPercentage} originalValue={x.previousPrice.value} /></>
								<><PrettyMoney className={styles.price} money={x.currentPrice} mode={MoneyDisplayMode.Standard} /></>
								<><PrettyMoney className={styles.price} money={x.previousPrice} mode={MoneyDisplayMode.Standard} /></>
							</TabularDataItem>
						))
					}
				</TabularData>
			</Loadable>
			<InfiniteScroller forQuery={performanceDeltas} />
		</>
	);
};
