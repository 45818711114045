import React, { useContext, useEffect, useState } from "react";

export enum ScreenSizeView {
	Desktop,
	DesktopAndTablet,
	Tablet,
	Mobile
}

export interface ScreenSizeProviderProps {
	children: React.ReactNode;
}

export const ScreenSizeProvider = ({ children }: ScreenSizeProviderProps) => {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		const onResize = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener("resize", onResize);
		return () => window.removeEventListener("resize", onResize);
	}, []);

	return (
		<ctx.Provider value={determineScreenSizeView(screenWidth)}>
			{children}
		</ctx.Provider>
	);
};

const determineScreenSizeView = (screenWidth: number): ScreenSizeView => {
	if (screenWidth >= 1024) return ScreenSizeView.Desktop;
	if (screenWidth >= 768 && screenWidth < 1024) return ScreenSizeView.Tablet;
	if (screenWidth < 768) return ScreenSizeView.Mobile;

	return ScreenSizeView.Desktop;
};

const ctx = React.createContext(determineScreenSizeView(window.innerWidth));

export const useScreenSize = () => useContext(ctx);
