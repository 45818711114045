import { Money } from "../../domain";
import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface SponsorPerformanceResponseModel {
	readonly month: string;
	readonly activeSubscriptions: number;
	readonly subscriptionRevenue: Money;
	readonly commissions: Money;
}

export const useSponsorPerformance = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["sponsorPerformance"], () => {
		return http<SponsorPerformanceResponseModel[]>({
			method: "GET",
			url: "/sponsor/performance"
		});
	});
};
