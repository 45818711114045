import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import { Money, PitchValue } from "@fyendalscollection/shared";
import { Link } from "react-router-dom";
import styles from "./OfficialDeckVariantsDisplay.module.scss";
import { ScreenSizeView, useScreenSize } from "@fyendalscollection/app/lib/ScreenSizeContext";

export interface OfficialDeckVariantsDisplayProps {
	officialDeckVariants: OfficialDeckVariant[];
}

export interface OfficialDeckVariant {
	productId: string;
	productName: string;
	fcId: string;
	quantity: number;
	price: Money;
	imageUrl: string;
	pitchValue: PitchValue;
}

export const OfficialDeckVariantsDisplay = (props: OfficialDeckVariantsDisplayProps): JSX.Element => {
	const pitchOtherVariants = props.officialDeckVariants.filter(x => !x.pitchValue);
	const pitch1Variants = props.officialDeckVariants.filter(x => x.pitchValue === PitchValue.Red);
	const pitch2Variants = props.officialDeckVariants.filter(x => x.pitchValue === PitchValue.Yellow);
	const pitch3Variants = props.officialDeckVariants.filter(x => x.pitchValue === PitchValue.Blue);

	const screenSize = useScreenSize();

	const desktopEmptyFragment = screenSize !== ScreenSizeView.Mobile && <></>;

	return (
		<TabularData headers={["Product", "Quantity", "Price"]}>
			<TabularDataItem>
				<>
					<div className={styles.pitchSection + " " + styles.equipment}>
						<div className={styles.green}></div>
						<div>Equipment/Other</div>
					</div>
				</>
				{desktopEmptyFragment}
				{desktopEmptyFragment}
			</TabularDataItem>
			<PitchSection officialDeckVariants={pitchOtherVariants}/>
			<TabularDataItem>
				<>
					<div className={styles.pitchSection}>
						<div className={styles.red}></div>
						<div>Red</div>
					</div>
				</>
				{desktopEmptyFragment}
				{desktopEmptyFragment}
			</TabularDataItem>
			<PitchSection officialDeckVariants={pitch1Variants}/>
			<TabularDataItem>
				<>
					<div className={styles.pitchSection}>
						<div className={styles.yellow}></div>
						<div>Yellow</div>
					</div>
				</>
				{desktopEmptyFragment}
				{desktopEmptyFragment}
			</TabularDataItem>
			<PitchSection officialDeckVariants={pitch2Variants}/>
			<TabularDataItem>
				<>
					<div className={styles.pitchSection}>
						<div className={styles.blue}></div>
						<div>Blue</div>
					</div>
				</>
				{desktopEmptyFragment}
				{desktopEmptyFragment}
			</TabularDataItem>
			<PitchSection officialDeckVariants={pitch3Variants}/>
		</TabularData>
	);
};

export interface PitchSectionProps {
	officialDeckVariants: OfficialDeckVariant[];
}

const PitchSection = (pitchVariants: PitchSectionProps): JSX.Element => {
	return (
		<>
			{pitchVariants.officialDeckVariants.map(x => (
				<TabularDataItem key={x.productId}>
					<>
						<Link className={styles.productInfo} to={`/browse/product/${x.productId}`}>
							<ProductImage className={styles.image} image={x.imageUrl} />
							<div>
								<div>{x.productName}</div>
								<div>{x.fcId}</div>
							</div>
						</Link>
					</>
					<>{x.quantity}</>
					<>
						<PrettyMoney className={styles.price} money={x.price} mode={MoneyDisplayMode.Standard}/>
					</>
				</TabularDataItem>
			))}
		</>
	);
};
