import { MutationFunction, useQueryClient } from "react-query";
import { PriceOverrideDetailsModel } from "./models/PriceOverrideDetailsModel";
import { clearCacheAfterPriceOverrideMutation } from "./clearCacheAfterPriceOverrideMutation";
import { HTTPAuthMode, Response, paginatedPrepend, useHTTP, useSimpleMutation } from "../../http";
import { Money } from "../../domain";

export interface PriceOverrideCreateRequestModel {
	variantId: string;
	price: Money;
}

export const usePriceOverrideCreate = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<PriceOverrideDetailsModel>, PriceOverrideCreateRequestModel> = requestModel => {
		return http<PriceOverrideDetailsModel, PriceOverrideCreateRequestModel>({
			method: "POST",
			url: "/price-override",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (data) => {
			queryClient.setQueryData(["priceOverrideDetails"], paginatedPrepend(data.body));
			clearCacheAfterPriceOverrideMutation(queryClient);
		}
	});
};
