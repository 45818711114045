import styles from "./ProductImage.module.scss";

interface ProductImageProps {
	image: string;
	className?: string;
}

export const ProductImage = (props: ProductImageProps): JSX.Element => {
	const style: React.CSSProperties = {
		backgroundImage: `url('${props.image}')`
	};

	const joinClassNames = (className: string | undefined) => {
		const classNames = [styles.productImage];
		if (className) {
			classNames.push(className);
		}
		const joinedString = classNames.join(" ");
		return joinedString;
	};

	return (
		<div className={joinClassNames(props.className)} style={style}></div>
	);
};
