import { useEffect, useState } from "react";
import styles from "./ModalCalendar.module.scss";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Calendar } from "@fyendalscollection/app/lib/components/Calendar";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";

export interface ModalCalendarProps {
	visible: boolean;
	defaultDate: Date;
	maxDate?: Date;
	minDate?: Date;
	onCancel: () => void;
	onDateSelected: (value: Date) => void;
}

export const ModalCalendar = ({ visible, defaultDate, maxDate, minDate, onCancel, onDateSelected }: ModalCalendarProps): JSX.Element => {
	const [selectedDate, setSelectedDate] = useState(defaultDate);

	// When the modal becomes visible, set the selectedDate to the defaultDate.
	useEffect(() => {
		if (visible) {
			setSelectedDate(defaultDate);
		}
	}, [visible]);

	return (
		<Modal visible={visible} onCancel={onCancel}>
			<div className={styles.widthCap}>
				<TitleSecondary text="Select Date"></TitleSecondary>

				<Calendar
					selectedDate={selectedDate}
					maxDate={maxDate}
					minDate={minDate}
					onChange={setSelectedDate} />

				<GreenButtonSet buttons={[
					{
						text: "Confirm Date",
						onClick: () => {
							onDateSelected(selectedDate);
							onCancel();
						}
					}
				]} />
			</div>
		</Modal>
	);
};

