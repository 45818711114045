import { Base64 } from "js-base64";

export class TokenClaims {
	[key: string]: unknown;

	constructor(token: string) {
		const segments = token.split(".", 3);
		if (segments.length != 3) {
			throw new Error("invalid token");
		}

		const rawJSON = Base64.decode(segments[1]);
		const obj: Record<string, unknown> = JSON.parse(rawJSON);

		for (const x of Object.keys(obj)) {
			this[x] = obj[x];
		}
	}
}
