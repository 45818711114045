import { useNavigate } from "react-router-dom";
import { Banner } from "@fyendalscollection/app/lib/components/Banner";
import { useStorage, useSubscription } from "@fyendalscollection/shared";
import { useCallback, useState } from "react";

export const SubscriptionBanner = (): JSX.Element => {
	const storage = useStorage();
	const navigate = useNavigate();
	const subscription = useSubscription();
	const [hideBanner, setHideBanner] = useState(() => storage.getTemporary<boolean>("hideBanner") || false);
	const setHideBannerEnriched = useCallback((value: boolean) => {
		storage.setTemporary("hideBanner", value);
		setHideBanner(value);
	}, [setHideBanner]);

	if (hideBanner
		|| !subscription.isSuccess
		|| (!subscription.data.autoRenewFailureCount && subscription.data.activeSubscription)) {
		return <></>;
	}

	const title = subscription.data.autoRenewFailureCount
		? "Payment Failure"
		: "Subscribe Now";

	const text = subscription.data.autoRenewFailureCount
		? "Your payment card declined, please click here to update your payment information."
		: "Unlock more features by going premium with Fyendal's Collection.";

	return (
		<>
			<Banner
				title={title}
				text={text}
				onClick={() => navigate("/settings/subscription")}
				onCancel={() => setHideBannerEnriched(true)}
				isError={!!subscription.data.autoRenewFailureCount} />
		</>
	);
};
