import { HTTPAuthMode, InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";
import { NotificationModel } from "./models";

export const useNotifications = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQueryWithCursor(["notifications"], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		return http<PaginatedResponseModel<NotificationModel>>({
			method: "GET",
			url: `/notification?cursor=${pageParam}`
		});
	});
};
