import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { Money } from "@fyendalscollection/shared";
import styles from "./OfficialDeckTopBar.module.scss";
import dayjs from "dayjs";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";

export interface OfficialDeckTopBarProps {
	country: string;
	date: Date;
	event: string | null;
	format: string;
	hero: string;
	placing: number | null;
	totalPrice: Money;
}

export const OfficialDeckTopBar = (props: OfficialDeckTopBarProps): JSX.Element => {
	return (
		<div className={styles.topbarContainer}>
			<Container>
				<ContainerSegment>
					<div className={styles.topBar}>
						<div>
							<div className={styles.title}>Country</div>
							<div className={styles.value}>{props.country}</div>
						</div>
						<div>
							<div className={styles.title}>Date</div>
							<div className={styles.value}>{dayjs(props.date).format("DD MMM YYYY")}</div>
						</div>
						{
							props.event &&
							<div>
								<div className={styles.title}>Event</div>
								<div className={styles.value}>{props.event}</div>
							</div>
						}
						<div>
							<div className={styles.title}>Format</div>
							<div className={styles.value}>{props.format}</div>
						</div>
						<div>
							<div className={styles.title}>Hero</div>
							<div className={styles.value}>{props.hero}</div>
						</div>
						{
							props.placing &&
							<div>
								<div className={styles.title}>Placing</div>
								<div className={styles.value}>{props.placing}</div>
							</div>
						}
						<div>
							<div className={styles.title}>Total Price</div>
							<PrettyMoney className={styles.value} money={props.totalPrice} mode={MoneyDisplayMode.Standard} />
						</div>
					</div>
				</ContainerSegment>
			</Container>
		</div>
	);
};
