import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { ProblemResponse, Response } from "./useHTTP";

export const useSimpleQuery = <T>(
	queryKey: QueryKey,
	queryFunc: QueryFunction<Response<T>>,
	options: Omit<UseQueryOptions<T, ProblemResponse, T, QueryKey>, "queryKey" | "queryFn"> = { }
): UseQueryResult<T, ProblemResponse> => {
	return useQuery(queryKey, async ctx => {
		const result = await Promise.resolve(queryFunc(ctx));
		return result.body;
	}, options);
};
