import { EvProductType, EvSetType, Money } from "../../domain";
import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface EvBreakdownResponseModel {
	readonly productId: string;
	readonly productName: string;
	readonly fcId: string;
	readonly imageUrl: string;
	readonly currentEstimatedValue: Money;
	readonly categories: EvBreakdownResponseModelCategory[];
}

export interface EvBreakdownResponseModelCategory {
	readonly category: string;
	readonly categoryName: string;
	readonly multiplier: number;
	readonly averageMarketPrice: Money;
	readonly contributedValue: Money;
}

export const useEvBreakdown = (setType: EvSetType, productType: EvProductType) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["evBreakdown", setType, productType], () => {
		return http<EvBreakdownResponseModel>({
			method: "GET",
			url: `/ev/breakdown?setType=${setType}&productType=${productType}`
		});
	});
};
