import dayjs from "dayjs";
import visa from "@fyendalscollection/app/assets/visa.svg";
import mastercard from "@fyendalscollection/app/assets/mastercard.svg";
import amex from "@fyendalscollection/app/assets/amex.svg";
import card from "@fyendalscollection/app/assets/card.svg";
import { usePaymentCards } from "@fyendalscollection/shared";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ContainerListItemRadioButton } from "@fyendalscollection/app/lib/components/ContainerListItemRadioButton";

export interface PaymentCardSelectorProps {
	selectedPaymentCardId: string | null;
	setSelectedPaymentCardId: (cardId: string | null) => void;
}

export const PaymentCardSelector = ({ selectedPaymentCardId, setSelectedPaymentCardId }: PaymentCardSelectorProps): JSX.Element => {
	const cards = usePaymentCards();

	const getProviderImage = (provider: string) => {
		switch (provider) {
		case "visa":
			return visa;
		case "mastercard":
			return mastercard;
		case "amex":
			return amex;
		default:
			return card;
		}
	};

	return (
		<>
			<Container>
				{(cards.data || []).map(card => (
					<ContainerSegment key={card.paymentCardId} onClick={() => setSelectedPaymentCardId(card.paymentCardId)}>
						<ContainerListItemRadioButton
							image={getProviderImage(card.provider)}
							selected={selectedPaymentCardId === card.paymentCardId}
							title={`Card ending in ${card.last4}`}
							subtitle={card.expiry.valueOf() === 0 ? "●●/●●" : dayjs(card.expiry).format("MM/YY")} />
					</ContainerSegment>
				))}
			</Container>
		</>
	);
};
