import { useEmblemStats } from "@fyendalscollection/shared";
import styles from "./EmblemStats.module.scss";
import { getColorForEmblemRarity } from "@fyendalscollection/app/features/emblems/getColorForEmblemRarity";

export const EmblemStats = (): JSX.Element => {
	const emblemStats = useEmblemStats();

	if (!emblemStats.data) {
		return (
			<div className={styles.emblemStats}></div>
		);
	}

	return (
		<div className={styles.emblemStats}>
			{
				emblemStats.data.map(x => (
					<div key={x.rarity}>
						<div style={{ backgroundColor: getColorForEmblemRarity(x.rarity) }}></div>
						<div>{x.uniqueOwnedCount}/{x.uniqueTotalCount}</div>
					</div>
				))
			}
		</div>
	);
};
