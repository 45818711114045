import { Link, useParams } from "react-router-dom";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import styles from "./PageOperationCompare.module.scss";
import { CollectionModel, Money, useCollection } from "@fyendalscollection/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Limit } from "@fyendalscollection/app/lib/components/Limit";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { CollectionTab, CollectionTabSelector } from "@fyendalscollection/app/features/collections/components/CollectionTabSelector";
import { Padlocked } from "@fyendalscollection/app/lib/components/Padlocked";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";

export const PageOperationCompare = (): JSX.Element => {
	const { collectionId } = useParams();
	const { compareCollectionId } = useParams();

	const collection = useCollection(collectionId || "");
	const compareCollection = useCollection(compareCollectionId || "");

	useTitle(collection.data ? collection.data.name : "Collection");

	return (
		<Limit>
			<Loadable loading={collection.isLoading}>
				{
					collection.data && (
						<>
							<Breadcrumb crumbs={[
								{
									text: "Dashboard",
									link: "/dashboard"
								},
								{
									text: "Collections",
									link: "/collections"
								},
								{
									text: collection.data.name,
									link: `/collections/${collectionId}`
								},
								{
									text: "Tools"
								}
							]} />

							<PageTitle
								title={collection.data.name}
								subTitle={collection.data.description}
								primaryActions={<CollectionTabSelector selected={CollectionTab.Tools} />} />

							{
								compareCollection.data && (
									<PageOperationCompareInternal
										collection={collection.data}
										compareCollection={compareCollection.data} />
								)
							}
						</>
					)
				}
			</Loadable>
		</Limit>
	);
};

interface PageOperationCompareInternalProps {
	collection: CollectionModel;
	compareCollection: CollectionModel;
}

const PageOperationCompareInternal = ({ collection, compareCollection }: PageOperationCompareInternalProps): JSX.Element => {

	const quantityDiff: number = compareCollection.quantity - collection.quantity;

	const marketValueDiff: Money = {
		value: compareCollection.marketValue.value - collection.marketValue.value,
		currency: collection.marketValue.currency
	};

	const gainDiff: Money | undefined = collection.gain && compareCollection.gain ? {
		value: compareCollection.gain.value - collection.gain.value,
		currency: collection.gain.currency
	} : undefined;

	const investedDiff: Money | undefined = collection.invested && compareCollection.invested ? {
		value: compareCollection.invested.value - collection.invested.value,
		currency: collection.invested.currency
	} : undefined;

	return (
		<TabularData headers={["", "MARKET VALUE", "GAIN", "INVESTED", "QTY"]}>
			<TabularDataItem>
				<Link to={`/collections/${collection.collectionId}`}>
					<div className={styles.bold}>{collection.name}</div>
				</Link>
				<>
					<Padlocked on={collection.marketValue} element={marketValue => (
						<PrettyMoney className={styles.bold} money={marketValue} mode={MoneyDisplayMode.Standard} />
					)} />
				</>
				<>
					<Padlocked on={collection.gain} element={marketValue => (
						<PrettyMoney className={styles.bold} money={marketValue} mode={MoneyDisplayMode.Standard} />
					)} />
				</>
				<>
					<Padlocked on={collection.invested} element={marketValue => (
						<PrettyMoney className={styles.bold} money={marketValue} mode={MoneyDisplayMode.Standard} />
					)} />
				</>
				<>
					<div className={styles.bold}>{collection.quantity}</div>
				</>
			</TabularDataItem>
			<TabularDataItem>
				<Link to={`/collections/${compareCollection.collectionId}`}>
					<div className={styles.bold}>{compareCollection.name}</div>
				</Link>
				<>
					<Padlocked on={compareCollection.marketValue} element={marketValue => (
						<PrettyMoney className={styles.bold} money={marketValue} mode={MoneyDisplayMode.Standard} />
					)} />
				</>
				<>
					<Padlocked on={compareCollection.gain} element={marketValue => (
						<PrettyMoney className={styles.bold} money={marketValue} mode={MoneyDisplayMode.Standard} />
					)} />
				</>
				<>
					<Padlocked on={compareCollection.invested} element={marketValue => (
						<PrettyMoney className={styles.bold} money={marketValue} mode={MoneyDisplayMode.Standard} />
					)} />
				</>
				<>
					<div className={styles.bold}>{compareCollection.quantity}</div>
				</>
			</TabularDataItem>
			<TabularDataItem>
				<>
					<div className={styles.bold}>DIFFERENCE</div>
				</>
				<>
					<Padlocked on={marketValueDiff} element={marketValue => (
						<PrettyMoney
							className={styles.bold}
							money={marketValue}
							mode={MoneyDisplayMode.DeltaPercentage}
							originalValue={collection.marketValue.value} />
					)} />
				</>
				<>
					<Padlocked on={gainDiff} element={gain => (
						<PrettyMoney
							className={styles.bold}
							money={gain}
							mode={MoneyDisplayMode.DeltaPercentage}
							originalValue={collection.gain?.value || 0} />
					)} />
				</>
				<>
					<Padlocked on={investedDiff} element={invested => (
						<PrettyMoney
							className={styles.bold}
							money={invested}
							mode={MoneyDisplayMode.DeltaPercentage}
							originalValue={collection.invested?.value || 0} />
					)} />
				</>
				<>
					<div className={styles.bold}>{quantityDiff}</div>
				</>
			</TabularDataItem>
		</TabularData>
	);
};
