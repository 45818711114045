import { useEffect, useState } from "react";
import mfaImage from "@fyendalscollection/app/assets/mfa.svg";
import { useFocus } from "@fyendalscollection/app/lib/useFocus";
import { BannerMessage } from "@fyendalscollection/app/lib/components/BannerMessage";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";

export interface ModalMfaProps {
	visible: boolean;
	onCancel: () => void;
	onConfirm: (mfaCode: string) => void;
}

const mfaCodeRegex = /^\d{6}$/;

export const ModalMfa = ({ visible, onCancel, onConfirm }: ModalMfaProps): JSX.Element => {
	const [mfaCode, setMfaCode] = useState("");
	const [ref, focus] = useFocus();

	useEffect(() => {
		if (mfaCode.length === 6) {
			onConfirm(mfaCode);
			onCancel();
			setMfaCode("");
		}
	}, [mfaCode]);

	useEffect(() => {
		if (visible) {
			setTimeout(focus, 10);
		}
	}, [visible]);

	return (
		<Modal visible={visible} onCancel={onCancel}>
			<BannerMessage
				title="Multifactor Authentication"
				content="Please enter your 6-digit multifactor authentication code"
				image={mfaImage} />
			<Container>
				<ContainerSegment>
					<TextEntry
						ref={ref}
						placeholder="6-digit code"
						inputMode="numeric"
						value={mfaCode}
						onChange={setMfaCode}
						maxLength={6}
						valid={mfaCodeRegex.test(mfaCode)} />
				</ContainerSegment>
			</Container>
		</Modal>
	);
};
