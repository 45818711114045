import { useEffect } from "react";

// React is introducing, at some point, the ability for components to mount ->
// unmount -> re-mount.  If we want something to only run when first mounted, we
// need to ensure this ourselves.  React 18 intentionally does this in dev mode
// each time a component is first mounted.
export const useEffectOnFirstMount = (effect: () => void) => {
	useEffect(() => {
		const timeout = setTimeout(effect, 10);
		return () => clearTimeout(timeout);
	}, []);
};
