import { OfficialDeckTopBar } from "@fyendalscollection/app/features/officialDecks/components/OfficialDeckTopBar";
import { OfficialDeckVariantsDisplay } from "@fyendalscollection/app/features/officialDecks/components/OfficialDeckVariantsDisplay";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { Limit } from "@fyendalscollection/app/lib/components/Limit";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { PremiumErrorDisplay } from "@fyendalscollection/app/lib/components/PremiumErrorDisplay";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { useOfficialDeckCollectionCreate, useOfficialDeckDetails, useOfficialDeckVariantDetails } from "@fyendalscollection/shared";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const PageOfficialDeckDetails = (): JSX.Element => {
	const params = useParams();
	const officialDeckId = params.officialDeckId as string;

	const officialDeckDetails = useOfficialDeckDetails(officialDeckId);
	const officialDeckVariantDetails = useOfficialDeckVariantDetails(officialDeckId);
	const officialDeckCreateCollection = useOfficialDeckCollectionCreate();
	useTitle(officialDeckDetails.data ? officialDeckDetails.data.name : "Official Deck");
	const navigate = useNavigate();

	useEffect(() => {
		if (officialDeckCreateCollection.isSuccess) {
			navigate("/collections/" + officialDeckCreateCollection.data.body.collectionId);
		}
	}, [officialDeckCreateCollection.isSuccess]);

	return (
		<Limit>
			<Breadcrumb crumbs={[{
				text: "Dashboard",
				link: "/dashboard"
			},
			{
				text: "Official Decks",
				link: "/official-decks"
			},
			{
				text: officialDeckDetails.data ? officialDeckDetails.data.name : "Official Deck"
			}]} />

			<PageTitle
				title={officialDeckDetails.data ? officialDeckDetails.data.name : "Official Deck"}
				subTitle={officialDeckDetails.data ? `${officialDeckDetails.data.hero} at ${officialDeckDetails.data.event} ${officialDeckDetails.data.format}` : "Official deck information"}
				primaryActions={
					<GreenButtonSet buttons={[
						{
							text: "Create Collection",
							disabled: officialDeckCreateCollection.isLoading || !officialDeckDetails.data,
							onClick: () => officialDeckCreateCollection.mutate({ officialDeckId: officialDeckId })
						},
						{
							text: "View Source Deck",
							disabled: !officialDeckDetails.data,
							link: officialDeckDetails.data ? officialDeckDetails.data.deckUrl : undefined
						}
					]} />
				} />

			<Loadable loading={officialDeckDetails.isLoading || officialDeckCreateCollection.isLoading}>
				{
					officialDeckDetails.data &&
					<OfficialDeckTopBar
						country={officialDeckDetails.data.country}
						date={officialDeckDetails.data.date}
						event={officialDeckDetails.data.event}
						format={officialDeckDetails.data.format}
						hero={officialDeckDetails.data.hero}
						placing={officialDeckDetails.data.placing}
						totalPrice={officialDeckDetails.data.totalPrice} />
				}
			</Loadable>

			{
				officialDeckDetails.isError &&
				<Container>
					<ContainerSegment>
						<ErrorDisplay problemResponse={officialDeckDetails.error}/>
					</ContainerSegment>
				</Container>
			}

			{
				officialDeckVariantDetails.isError &&
				<Container>
					<ContainerSegment>
						<ErrorDisplay problemResponse={officialDeckVariantDetails.error}/>
					</ContainerSegment>
				</Container>
			}

			{
				officialDeckCreateCollection.isError && (
					officialDeckCreateCollection.error.problem.problemType === "PremiumAccessRequired"
						? <PremiumErrorDisplay problemResponse={officialDeckCreateCollection.error} />
						: <Container>
							<ContainerSegment>
								<ErrorDisplay problemResponse={officialDeckCreateCollection.error}/>
							</ContainerSegment>
						</Container>
				)
			}

			<Loadable loading={officialDeckVariantDetails.isLoading}>
				{
					officialDeckVariantDetails.data &&
					<OfficialDeckVariantsDisplay officialDeckVariants={officialDeckVariantDetails.data} />
				}
			</Loadable>
		</Limit>
	);
};
