import { MutationFunction } from "react-query";
import { PlanModel } from "./models/PlanModel";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export const usePlanForPromoCode = () => {
	const http = useHTTP(HTTPAuthMode.Required);

	const mutFunc: MutationFunction<Response<PlanModel>, string> = promoCode => {
		return http<PlanModel>({
			method: "GET",
			url: "/plan/" + promoCode
		});
	};

	return useSimpleMutation(mutFunc);
};
