import card from "@fyendalscollection/app/assets/card.svg";
import passwordLock from "@fyendalscollection/app/assets/passwordLock.svg";
import mfa from "@fyendalscollection/app/assets/mfa.svg";
import discordPurple from "@fyendalscollection/app/assets/discord-purple.svg";
import priceSource from "@fyendalscollection/app/assets/price-source.svg";
import currency from "@fyendalscollection/app/assets/currency.svg";
import priceOverride from "@fyendalscollection/app/assets/price-override.svg";
import crown from "@fyendalscollection/app/assets/crown.svg";
import logout from "@fyendalscollection/app/assets/logout.svg";
import { useAuthState } from "@fyendalscollection/shared";
import styles from "./PageSettings.module.scss";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { useNavigate } from "react-router-dom";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ContainerListItem } from "@fyendalscollection/app/lib/components/ContainerListItem";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";

export const PageSettings = (): JSX.Element => {
	useTitle("Settings");

	const navigate = useNavigate();
	const { dispatchAuthLoggedOut } = useAuthState();

	return (
		<Limit force={LimitWidth.Tiny}>
			<Breadcrumb crumbs={[
				{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "Settings"
				}
			]} />
			<PageTitle title="Settings" subTitle="Manage your account and profile settings"/>
			<Container title="General">
				<ContainerSegment onClick={() => navigate("preferred-currency")}>
					<ContainerListItem title="Preferred Currency" image={currency} subtitle="Change the currency used for displaying pricing">
						<LinkButton text="Change" onClick={() => undefined} />
					</ContainerListItem>
				</ContainerSegment>

				<ContainerSegment onClick={() => navigate("preferred-price-source")}>
					<ContainerListItem title="Preferred Price Source" image={priceSource} subtitle="Configure which source of pricing information to use">
						<LinkButton text="Change" onClick={() => undefined} />
					</ContainerListItem>
				</ContainerSegment>

				<ContainerSegment onClick={() => navigate("price-overrides")}>
					<ContainerListItem title="Price Overrides" image={priceOverride} subtitle="Manage your custom price overrides">
						<LinkButton text="Manage" onClick={() => undefined} />
					</ContainerListItem>
				</ContainerSegment>
			</Container>

			<Container title="Billing">
				<ContainerSegment onClick={() => navigate("subscription")}>
					<ContainerListItem title="Subscription" image={crown} subtitle="Manage your access to premium features">
						<LinkButton text="Manage" onClick={() => undefined} />
					</ContainerListItem>
				</ContainerSegment>
				<ContainerSegment onClick={() => navigate("payment-cards")}>
					<ContainerListItem title="Payment Cards" image={card} subtitle="Add or remove payment cards">
						<LinkButton text="View" onClick={() => undefined} />
					</ContainerListItem>
				</ContainerSegment>
			</Container>

			<Container title="Support">
				<ContainerSegment onClick={() => window.open("https://discord.gg/RDYn8yDnFm", "_blank")}>
					<ContainerListItem title="Discord Community" image={discordPurple} subtitle="Have questions or found a bug?">
						<LinkButton text="Join" onClick={() => undefined} />
					</ContainerListItem>
				</ContainerSegment>
				<ContainerSegment>
					<div className={styles.email}>Alternatively email us at <a href="mailto:support@fyendalscollection.com">support@fyendalscollection.com</a></div>
				</ContainerSegment>
			</Container>

			<Container title="Profile">
				<ContainerSegment onClick={() => navigate("change-password")}>
					<ContainerListItem title="Change Password" image={passwordLock} subtitle="Change your account password">
						<LinkButton text="Change" onClick={() => undefined} />
					</ContainerListItem>
				</ContainerSegment>
				<ContainerSegment onClick={() => navigate("mfa")}>
					<ContainerListItem title="Multifactor Authentication" image={mfa} subtitle="Configure your MFA settings">
						<LinkButton text="Manage" onClick={() => undefined}/>
					</ContainerListItem>
				</ContainerSegment>
				<ContainerSegment onClick={dispatchAuthLoggedOut}>
					<ContainerListItem title="Logout" image={logout}>
						<LinkButton text="Logout" onClick={() => undefined} />
					</ContainerListItem>
				</ContainerSegment>
			</Container>
		</Limit>
	);
};
