import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import styles from "./ProductNameFilter.module.scss";

export interface ProductNameFilterProps {
	value: string;
	onChange: (value: string) => void;
}

export const ProductNameFilter = ({ value, onChange }: ProductNameFilterProps): JSX.Element => {
	return (
		<div className={styles.productNameFilter}>
			<TextEntry
				value={value}
				onChange={onChange}
				placeholder="Filter by Product Name" />
		</div>
	);
};
