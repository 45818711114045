import { useState } from "react";
import styles from "./TopBar.module.scss";
import { Money } from "@fyendalscollection/shared";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { Padlocked } from "@fyendalscollection/app/lib/components/Padlocked";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { HelpModal } from "@fyendalscollection/app/features/collections/components/HelpModal";

export interface TopBarProps {
	quantity: number;
	marketValue: Money;
	invested: Money | undefined;
	gain: Money | undefined;
	profit: Money | undefined;
	cashPosition: Money | undefined;
	averageBuyPrice?: Money | undefined;
}

export const TopBar = (props: TopBarProps): JSX.Element => {
	const [modalVisibility, setModalVisibility] = useState(false);
	return (
		<>
			<Container>
				<ContainerSegment>
					<div className={styles.topBar}>
						<div className={styles.grid}>
							<div>
								<div className={styles.title}>Total Quantity</div>
								<div className={styles.valueText}>{props.quantity}</div>
							</div>
							{
								props.averageBuyPrice !== undefined &&
								<>
									<div>
										<div className={styles.title}>Average Buy Price</div>
										<Padlocked on={props.averageBuyPrice} element={averageBuyPrice => <PrettyMoney className={styles.valueText} money={averageBuyPrice} mode={MoneyDisplayMode.Standard}/>} />
									</div>
								</>
							}
							<div>
								<div className={styles.title}>Market Value</div>
								<PrettyMoney className={styles.valueText} money={props.marketValue} mode={MoneyDisplayMode.Standard} />
							</div>
							<div>
								<div className={styles.title}>Gain</div>
								<Padlocked on={props.gain} element={gain => <PrettyMoney className={styles.valueText} money={gain} mode={MoneyDisplayMode.DeltaPercentage} originalValue={props.invested?.value || 0}/>} />
							</div>
							<div>
								<div className={styles.title}>Invested</div>
								<Padlocked on={props.invested} element={invested => <PrettyMoney className={styles.valueText} money={invested} mode={MoneyDisplayMode.Standard}/>} />
							</div>
							<div>
								<div className={styles.title}>Profit</div>
								<Padlocked on={props.profit} element={profit => <PrettyMoney className={styles.valueText} money={profit} mode={MoneyDisplayMode.Delta}/>} />
							</div>
							<div className={styles.cashPosition}>
								<div className={styles.title}>Cash Position</div>
								<Padlocked on={props.cashPosition} element={cashPosition => <PrettyMoney className={styles.valueText} money={cashPosition} mode={MoneyDisplayMode.Delta} />} />
							</div>
						</div>
						{
							props.averageBuyPrice === undefined &&
							<LinkButton text="Help" onClick={() => setModalVisibility(true)} />
						}
					</div>
				</ContainerSegment>
			</Container>

			<HelpModal showModal={modalVisibility} closeModal={() => setModalVisibility(false)} />
		</>
	);
};
