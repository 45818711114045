import { useEffect, useState } from "react";
import styles from "./PageMfa.module.scss";
import QRCode from "qrcode";
import tick from "@fyendalscollection/app/assets/tick.svg";
import { useAuthState } from "@fyendalscollection/shared";
import { useNavigate } from "react-router-dom";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { BannerMessage } from "@fyendalscollection/app/lib/components/BannerMessage";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { ModalConfirmation } from "@fyendalscollection/app/lib/components/ModalConfirmation";
import { SmartBox } from "@fyendalscollection/app/lib/components/SmartBox";
import { CreateMfaSecretResponseModel, useCreateMfaSecret, useRemoveMfaSecret, useSetMfaSecret } from "@fyendalscollection/shared";

const mfaCodeRegex = /^\d{6}$/;

export const PageMfa = (): JSX.Element => {
	useTitle("MFA");

	const { mfa } = useAuthState().state;
	return (
		<Limit force={LimitWidth.Tiny}>
			<Breadcrumb crumbs={[{
				text: "Settings",
				link: "/settings"
			},
			{
				text: "Multifactor Authentication"
			}]}/>

			<PageTitle
				title="Multifactor Authentication"
				subTitle="Configure and manage your multifactor authentication settings" />

			{
				mfa
					? <ContentMfaAlreadyConfigured />
					: <ContentMfaNotConfigured />
			}
		</Limit>
	);
};

const ContentMfaAlreadyConfigured = (): JSX.Element => {
	const removeMfaSecret = useRemoveMfaSecret();
	const navigate = useNavigate();
	const [password, setPassword] = useState("");
	const [mfaCode, setMfaCode] = useState("");
	const [modalVisible, setModalVisible] = useState(false);

	useEffect(() => {
		if (removeMfaSecret.isSuccess) {
			navigate("/login/mfa/disabled");
		}
	}, [removeMfaSecret.isSuccess]);

	return (
		<Loadable loading={removeMfaSecret.isLoading}>
			<Container>
				<ContainerSegment>
					<BannerMessage image={tick} title="Multifactor Authentication Enabled" />
				</ContainerSegment>
			</Container>
			<Container title="Disable MFA">
				<ContainerSegment>
					If you&apos;d like to disable multifactor authentication for your account, please provide your account password and one of your
					6-digit account codes below.
				</ContainerSegment>
				{
					removeMfaSecret.isError &&
					<ContainerSegment>
						<ErrorDisplay problemResponse={removeMfaSecret.error}/>
					</ContainerSegment>
				}
				<ContainerSegment>
					<TextEntry
						placeholder="Password"
						type="password"
						value={password}
						onChange={setPassword} />
				</ContainerSegment>
				<ContainerSegment>
					<TextEntry
						placeholder="6-digit code"
						inputMode="numeric"
						value={mfaCode}
						onChange={setMfaCode}
						maxLength={6} />
				</ContainerSegment>
			</Container>
			<GreenButtonSet buttons={[
				{
					text: "Disable MFA",
					disabled: password.length < 8 || !mfaCodeRegex.test(mfaCode),
					onClick: () => setModalVisible(true)
				}
			]} />
			<ModalConfirmation
				title="Disable MFA"
				description="Are you sure you want to disable multifactor authentication?"
				visible={modalVisible}
				onConfirm={() => removeMfaSecret.mutate({ password, mfaCode })}
				onCancel={() => setModalVisible(false)} />
		</Loadable>
	);
};

const ContentMfaNotConfigured = (): JSX.Element => {
	const createMfaSecret = useCreateMfaSecret();

	useEffect(() => {
		createMfaSecret.mutate(undefined);
	}, []);

	return (
		<Loadable loading={createMfaSecret.isLoading}>
			<SmartBox
				switchOn={createMfaSecret}
				loading={() => (
					<Container>
						<ContainerSegment>
							Hold tight... we&apos;re generating you a new multifactor authentication QR code...
						</ContainerSegment>
					</Container>
				)}
				success={data => <ContentMfaQRCode {...data.body} />} />
		</Loadable>
	);
};

const ContentMfaQRCode = (props: CreateMfaSecretResponseModel): JSX.Element => {
	const setMfaSecret = useSetMfaSecret();
	const navigate = useNavigate();
	const { dispatchAuthLoggedOut } = useAuthState();
	const [qrCode, setQRCode] = useState<string | null>(null);
	const [mfaCode, setMfaCode] = useState("");

	useEffect(() => generateQRCode(props.mfaUrl, setQRCode), [props.mfaUrl]);

	useEffect(() => {
		if (setMfaSecret.isError) {
			setMfaCode("");
		}
	}, [setMfaSecret.isError]);

	useEffect(() => {
		if (setMfaSecret.isSuccess) {
			dispatchAuthLoggedOut();
		}
	}, [setMfaSecret.isSuccess]);

	useEffect(() => {
		if (mfaCode.length === 6) {
			setMfaSecret.mutate({
				mfaToken: props.mfaToken,
				mfaCode
			});
		}
	}, [mfaCode]);

	useEffect(() => {
		if (setMfaSecret.isSuccess) {
			navigate("/login/mfa/enabled");
		}
	}, [setMfaSecret.isSuccess]);

	return (
		<Loadable loading={setMfaSecret.isLoading}>
			<Container title="Enable MFA">
				<ContainerSegment>
					Scan the QR code with your authenticator app and enter the 6-digit code
				</ContainerSegment>
				{
					setMfaSecret.isError &&
					<ContainerSegment>
						<ErrorDisplay problemResponse={setMfaSecret.error}/>
					</ContainerSegment>
				}
				<ContainerSegment>
					<TextEntry
						placeholder="6-digit code"
						inputMode="numeric"
						value={mfaCode}
						onChange={setMfaCode}
						maxLength={6}
						valid={mfaCodeRegex.test(mfaCode)} />
				</ContainerSegment>
			</Container>

			{
				qrCode &&
				<div className={styles.qrCode}>
					<img src={qrCode} />
				</div>
			}
		</Loadable>
	);
};

const generateQRCode = (mfaUrl: string, callback: (qrCode: string) => void) => {
	const options: QRCode.QRCodeToDataURLOptions = {
		width: 768,
		color: {
			dark: "#444"
		}
	};

	QRCode.toDataURL(mfaUrl, options).then(callback);
};
