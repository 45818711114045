import { HTTPAuthMode, InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";
import { PriceOverrideDetailsModel } from "./models/PriceOverrideDetailsModel";

export const usePriceOverrideDetails = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQueryWithCursor(["priceOverrideDetails"], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		return http<PaginatedResponseModel<PriceOverrideDetailsModel>>({
			method: "GET",
			url: `/price-override?cursor=${pageParam}`
		});
	});
};
