import collectionArt0 from "@fyendalscollection/app/assets/collectionArt/0.jpg";
import collectionArt1 from "@fyendalscollection/app/assets/collectionArt/1.jpg";
import collectionArt2 from "@fyendalscollection/app/assets/collectionArt/2.jpg";
import collectionArt3 from "@fyendalscollection/app/assets/collectionArt/3.jpg";
import collectionArt4 from "@fyendalscollection/app/assets/collectionArt/4.jpg";
import collectionArt5 from "@fyendalscollection/app/assets/collectionArt/5.jpg";
import collectionArt6 from "@fyendalscollection/app/assets/collectionArt/6.jpg";
import collectionArt7 from "@fyendalscollection/app/assets/collectionArt/7.jpg";
import collectionArt8 from "@fyendalscollection/app/assets/collectionArt/8.jpg";
import collectionArt9 from "@fyendalscollection/app/assets/collectionArt/9.jpg";
import collectionArt10 from "@fyendalscollection/app/assets/collectionArt/10.jpg";
import collectionArt11 from "@fyendalscollection/app/assets/collectionArt/11.jpg";
import collectionArt12 from "@fyendalscollection/app/assets/collectionArt/12.jpg";
import collectionArt13 from "@fyendalscollection/app/assets/collectionArt/13.jpg";
import collectionArt14 from "@fyendalscollection/app/assets/collectionArt/14.jpg";
import collectionArt15 from "@fyendalscollection/app/assets/collectionArt/15.jpg";
import collectionArt16 from "@fyendalscollection/app/assets/collectionArt/16.jpg";
import collectionArt17 from "@fyendalscollection/app/assets/collectionArt/17.jpg";
import collectionArt18 from "@fyendalscollection/app/assets/collectionArt/18.jpg";
import collectionArt19 from "@fyendalscollection/app/assets/collectionArt/19.jpg";
import collectionArt20 from "@fyendalscollection/app/assets/collectionArt/20.jpg";
import collectionArt21 from "@fyendalscollection/app/assets/collectionArt/21.jpg";
import collectionArt22 from "@fyendalscollection/app/assets/collectionArt/22.jpg";
import collectionArt23 from "@fyendalscollection/app/assets/collectionArt/23.jpg";
import collectionArt24 from "@fyendalscollection/app/assets/collectionArt/24.jpg";
import collectionArt25 from "@fyendalscollection/app/assets/collectionArt/25.jpg";
import collectionArt26 from "@fyendalscollection/app/assets/collectionArt/26.jpg";
import collectionArt27 from "@fyendalscollection/app/assets/collectionArt/27.jpg";
import collectionArt28 from "@fyendalscollection/app/assets/collectionArt/28.jpg";
import collectionArt29 from "@fyendalscollection/app/assets/collectionArt/29.jpg";
import collectionArt30 from "@fyendalscollection/app/assets/collectionArt/30.jpg";
import collectionArt31 from "@fyendalscollection/app/assets/collectionArt/31.jpg";
import collectionArt32 from "@fyendalscollection/app/assets/collectionArt/32.jpg";
import collectionArt33 from "@fyendalscollection/app/assets/collectionArt/33.jpg";
import collectionArt34 from "@fyendalscollection/app/assets/collectionArt/34.jpg";
import collectionArt35 from "@fyendalscollection/app/assets/collectionArt/35.jpg";
import collectionArt36 from "@fyendalscollection/app/assets/collectionArt/36.jpg";
import collectionArt37 from "@fyendalscollection/app/assets/collectionArt/37.jpg";
import collectionArt38 from "@fyendalscollection/app/assets/collectionArt/38.jpg";
import collectionArt39 from "@fyendalscollection/app/assets/collectionArt/39.jpg";
import collectionArt40 from "@fyendalscollection/app/assets/collectionArt/40.jpg";
import collectionArt41 from "@fyendalscollection/app/assets/collectionArt/41.jpg";
import collectionArt42 from "@fyendalscollection/app/assets/collectionArt/42.jpg";
import collectionArt43 from "@fyendalscollection/app/assets/collectionArt/43.jpg";
import collectionArt44 from "@fyendalscollection/app/assets/collectionArt/44.jpg";
import collectionArt45 from "@fyendalscollection/app/assets/collectionArt/45.jpg";
import collectionArt46 from "@fyendalscollection/app/assets/collectionArt/46.jpg";
import collectionArt47 from "@fyendalscollection/app/assets/collectionArt/47.jpg";
import collectionArt48 from "@fyendalscollection/app/assets/collectionArt/48.jpg";
import collectionArt49 from "@fyendalscollection/app/assets/collectionArt/49.jpg";
import collectionArt50 from "@fyendalscollection/app/assets/collectionArt/50.jpg";
import collectionArt51 from "@fyendalscollection/app/assets/collectionArt/51.jpg";
import collectionArt52 from "@fyendalscollection/app/assets/collectionArt/52.jpg";
import collectionArt53 from "@fyendalscollection/app/assets/collectionArt/53.jpg";
import collectionArt54 from "@fyendalscollection/app/assets/collectionArt/54.jpg";
import collectionArt55 from "@fyendalscollection/app/assets/collectionArt/55.jpg";
import collectionArt56 from "@fyendalscollection/app/assets/collectionArt/56.jpg";
import collectionArt57 from "@fyendalscollection/app/assets/collectionArt/57.jpg";
import collectionArt58 from "@fyendalscollection/app/assets/collectionArt/58.jpg";
import collectionArt59 from "@fyendalscollection/app/assets/collectionArt/59.jpg";
import collectionArt60 from "@fyendalscollection/app/assets/collectionArt/60.jpg";
import collectionArt61 from "@fyendalscollection/app/assets/collectionArt/61.jpg";
import collectionArt62 from "@fyendalscollection/app/assets/collectionArt/62.jpg";
import collectionArt63 from "@fyendalscollection/app/assets/collectionArt/63.jpg";
import collectionArt64 from "@fyendalscollection/app/assets/collectionArt/64.jpg";
import collectionArt65 from "@fyendalscollection/app/assets/collectionArt/65.jpg";
import collectionArt66 from "@fyendalscollection/app/assets/collectionArt/66.jpg";
import collectionArt67 from "@fyendalscollection/app/assets/collectionArt/67.jpg";
import collectionArt68 from "@fyendalscollection/app/assets/collectionArt/68.jpg";
import collectionArt69 from "@fyendalscollection/app/assets/collectionArt/69.jpg";
import collectionArt70 from "@fyendalscollection/app/assets/collectionArt/70.jpg";
import collectionArt71 from "@fyendalscollection/app/assets/collectionArt/71.jpg";
import collectionArt72 from "@fyendalscollection/app/assets/collectionArt/72.jpg";
import collectionArt73 from "@fyendalscollection/app/assets/collectionArt/73.jpg";
import collectionArt74 from "@fyendalscollection/app/assets/collectionArt/74.jpg";
import collectionArt75 from "@fyendalscollection/app/assets/collectionArt/75.jpg";
import collectionArt76 from "@fyendalscollection/app/assets/collectionArt/76.jpg";
import collectionArt77 from "@fyendalscollection/app/assets/collectionArt/77.jpg";
import collectionArt78 from "@fyendalscollection/app/assets/collectionArt/78.jpg";
import collectionArt79 from "@fyendalscollection/app/assets/collectionArt/79.jpg";
import collectionArt80 from "@fyendalscollection/app/assets/collectionArt/80.jpg";
import collectionArt81 from "@fyendalscollection/app/assets/collectionArt/81.jpg";
import collectionArt82 from "@fyendalscollection/app/assets/collectionArt/82.jpg";
import collectionArt83 from "@fyendalscollection/app/assets/collectionArt/83.jpg";
import collectionArt84 from "@fyendalscollection/app/assets/collectionArt/84.jpg";
import collectionArt85 from "@fyendalscollection/app/assets/collectionArt/85.jpg";
import collectionArt86 from "@fyendalscollection/app/assets/collectionArt/86.jpg";
import collectionArt87 from "@fyendalscollection/app/assets/collectionArt/87.jpg";
import collectionArt88 from "@fyendalscollection/app/assets/collectionArt/88.jpg";
import collectionArt89 from "@fyendalscollection/app/assets/collectionArt/89.jpg";
import collectionArt90 from "@fyendalscollection/app/assets/collectionArt/90.jpg";
import collectionArt91 from "@fyendalscollection/app/assets/collectionArt/91.jpg";
import collectionArt92 from "@fyendalscollection/app/assets/collectionArt/92.jpg";
import collectionArt93 from "@fyendalscollection/app/assets/collectionArt/93.jpg";
import collectionArt94 from "@fyendalscollection/app/assets/collectionArt/94.jpg";
import collectionArt95 from "@fyendalscollection/app/assets/collectionArt/95.jpg";
import collectionArt96 from "@fyendalscollection/app/assets/collectionArt/96.jpg";
import collectionArt97 from "@fyendalscollection/app/assets/collectionArt/97.jpg";
import collectionArt98 from "@fyendalscollection/app/assets/collectionArt/98.jpg";
import collectionArt99 from "@fyendalscollection/app/assets/collectionArt/99.jpg";
import collectionArt100 from "@fyendalscollection/app/assets/collectionArt/100.jpg";
import collectionArt101 from "@fyendalscollection/app/assets/collectionArt/101.jpg";
import collectionArt102 from "@fyendalscollection/app/assets/collectionArt/102.jpg";
import collectionArt103 from "@fyendalscollection/app/assets/collectionArt/103.jpg";
import collectionArt104 from "@fyendalscollection/app/assets/collectionArt/104.jpg";
import collectionArt105 from "@fyendalscollection/app/assets/collectionArt/105.jpg";
import collectionArt106 from "@fyendalscollection/app/assets/collectionArt/106.jpg";
import collectionArt107 from "@fyendalscollection/app/assets/collectionArt/107.jpg";
import collectionArt108 from "@fyendalscollection/app/assets/collectionArt/108.jpg";
import collectionArt109 from "@fyendalscollection/app/assets/collectionArt/109.jpg";
import collectionArt110 from "@fyendalscollection/app/assets/collectionArt/110.jpg";
import collectionArt111 from "@fyendalscollection/app/assets/collectionArt/111.jpg";
import collectionArt112 from "@fyendalscollection/app/assets/collectionArt/112.jpg";
import collectionArt113 from "@fyendalscollection/app/assets/collectionArt/113.jpg";
import collectionArt114 from "@fyendalscollection/app/assets/collectionArt/114.jpg";
import collectionArt115 from "@fyendalscollection/app/assets/collectionArt/115.jpg";
import collectionArt116 from "@fyendalscollection/app/assets/collectionArt/116.jpg";
import collectionArt117 from "@fyendalscollection/app/assets/collectionArt/117.jpg";
import collectionArt118 from "@fyendalscollection/app/assets/collectionArt/118.jpg";
import collectionArt119 from "@fyendalscollection/app/assets/collectionArt/119.jpg";
import collectionArt120 from "@fyendalscollection/app/assets/collectionArt/120.jpg";
import collectionArt121 from "@fyendalscollection/app/assets/collectionArt/121.jpg";
import collectionArt122 from "@fyendalscollection/app/assets/collectionArt/122.jpg";
import collectionArt123 from "@fyendalscollection/app/assets/collectionArt/123.jpg";
import collectionArt124 from "@fyendalscollection/app/assets/collectionArt/124.jpg";
import collectionArt125 from "@fyendalscollection/app/assets/collectionArt/125.jpg";
import collectionArt126 from "@fyendalscollection/app/assets/collectionArt/126.jpg";
import collectionArt127 from "@fyendalscollection/app/assets/collectionArt/127.jpg";
import collectionArt128 from "@fyendalscollection/app/assets/collectionArt/128.jpg";
import collectionArt129 from "@fyendalscollection/app/assets/collectionArt/129.jpg";
import collectionArt130 from "@fyendalscollection/app/assets/collectionArt/130.jpg";
import collectionArt131 from "@fyendalscollection/app/assets/collectionArt/131.jpg";
import collectionArt132 from "@fyendalscollection/app/assets/collectionArt/132.jpg";
import collectionArt133 from "@fyendalscollection/app/assets/collectionArt/133.jpg";
import collectionArt134 from "@fyendalscollection/app/assets/collectionArt/134.jpg";
import collectionArt135 from "@fyendalscollection/app/assets/collectionArt/135.jpg";
import collectionArt136 from "@fyendalscollection/app/assets/collectionArt/136.jpg";
import collectionArt137 from "@fyendalscollection/app/assets/collectionArt/137.jpg";
import collectionArt138 from "@fyendalscollection/app/assets/collectionArt/138.jpg";

export const collectionArts = [
	collectionArt0,
	collectionArt1,
	collectionArt2,
	collectionArt3,
	collectionArt4,
	collectionArt5,
	collectionArt6,
	collectionArt7,
	collectionArt8,
	collectionArt9,
	collectionArt10,
	collectionArt11,
	collectionArt12,
	collectionArt13,
	collectionArt14,
	collectionArt15,
	collectionArt16,
	collectionArt17,
	collectionArt18,
	collectionArt19,
	collectionArt20,
	collectionArt21,
	collectionArt22,
	collectionArt23,
	collectionArt24,
	collectionArt25,
	collectionArt26,
	collectionArt27,
	collectionArt28,
	collectionArt29,
	collectionArt30,
	collectionArt31,
	collectionArt32,
	collectionArt33,
	collectionArt34,
	collectionArt35,
	collectionArt36,
	collectionArt37,
	collectionArt38,
	collectionArt39,
	collectionArt40,
	collectionArt41,
	collectionArt42,
	collectionArt43,
	collectionArt44,
	collectionArt45,
	collectionArt46,
	collectionArt47,
	collectionArt48,
	collectionArt49,
	collectionArt50,
	collectionArt51,
	collectionArt52,
	collectionArt53,
	collectionArt54,
	collectionArt55,
	collectionArt56,
	collectionArt57,
	collectionArt58,
	collectionArt59,
	collectionArt60,
	collectionArt61,
	collectionArt62,
	collectionArt63,
	collectionArt64,
	collectionArt65,
	collectionArt66,
	collectionArt67,
	collectionArt68,
	collectionArt69,
	collectionArt70,
	collectionArt71,
	collectionArt72,
	collectionArt73,
	collectionArt74,
	collectionArt75,
	collectionArt76,
	collectionArt77,
	collectionArt78,
	collectionArt79,
	collectionArt80,
	collectionArt81,
	collectionArt82,
	collectionArt83,
	collectionArt84,
	collectionArt85,
	collectionArt86,
	collectionArt87,
	collectionArt88,
	collectionArt89,
	collectionArt90,
	collectionArt91,
	collectionArt92,
	collectionArt93,
	collectionArt94,
	collectionArt95,
	collectionArt96,
	collectionArt97,
	collectionArt98,
	collectionArt99,
	collectionArt100,
	collectionArt101,
	collectionArt102,
	collectionArt103,
	collectionArt104,
	collectionArt105,
	collectionArt106,
	collectionArt107,
	collectionArt108,
	collectionArt109,
	collectionArt110,
	collectionArt111,
	collectionArt112,
	collectionArt113,
	collectionArt114,
	collectionArt115,
	collectionArt116,
	collectionArt117,
	collectionArt118,
	collectionArt119,
	collectionArt120,
	collectionArt121,
	collectionArt122,
	collectionArt123,
	collectionArt124,
	collectionArt125,
	collectionArt126,
	collectionArt127,
	collectionArt128,
	collectionArt129,
	collectionArt130,
	collectionArt131,
	collectionArt132,
	collectionArt133,
	collectionArt134,
	collectionArt135,
	collectionArt136,
	collectionArt137,
	collectionArt138
];
