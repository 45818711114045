import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { OfficialDeckDetailsModel } from "./models";

export const useOfficialDeckDetails = (officialDeckId: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["officialDeckDetails", officialDeckId], () => {
		return http<OfficialDeckDetailsModel>({
			method: "GET",
			url: `/official-deck/${officialDeckId}`
		});
	});
};
