import styles from "./ProgressBar.module.scss";

export interface ProgressBarProps {
	progress: number;
}

export const ProgressBar = ({ progress }: ProgressBarProps): JSX.Element => {
	return (
		<div className={styles.progressBar}>
			<div style={{ width: `${progress * 100}%` }}></div>
		</div>
	);
};
