import { MutationFunction } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";
export interface CreateMfaSecretResponseModel {
	mfaToken: string;
	mfaUrl: string;
}

export const useCreateMfaSecret = () => {
	const http = useHTTP(HTTPAuthMode.Required);

	const mutFunc: MutationFunction<Response<CreateMfaSecretResponseModel>> = () => {
		return http<CreateMfaSecretResponseModel>({
			method: "POST",
			url: "/mfa/create",
			body: undefined
		});
	};

	return useSimpleMutation(mutFunc);
};
