export enum PrepopulateFilterFlags {
	None = 0,
	Common = 1,
	Rare = 2,
	SuperRare = 4,
	Majestic = 8,
	Marvel = 16,
	Legendary = 32,
	Fabled = 64,
	NonFoil = 128,
	RainbowFoil = 256,
	ColdFoil = 512,
	Promo = 1024
}

export const allFoilFlags = PrepopulateFilterFlags.NonFoil | PrepopulateFilterFlags.RainbowFoil | PrepopulateFilterFlags.ColdFoil;
export const allRarityFlags = PrepopulateFilterFlags.Common | PrepopulateFilterFlags.Rare | PrepopulateFilterFlags.SuperRare | PrepopulateFilterFlags.Majestic | PrepopulateFilterFlags.Marvel | PrepopulateFilterFlags.Legendary | PrepopulateFilterFlags.Fabled | PrepopulateFilterFlags.Promo;

export const mergePrepopulateFlags = (flags1: PrepopulateFilterFlags, flags2: PrepopulateFilterFlags) => {
	return flags1 | flags2;
};

export const checkPrepopulateFlags = (flags1: PrepopulateFilterFlags, flags2: PrepopulateFilterFlags) => {
	return (flags1 & flags2) === flags2;
};

export const removePrepopulateFlags = (flags1: PrepopulateFilterFlags, flags2: PrepopulateFilterFlags) => {
	return flags1 & ~flags2;
};
