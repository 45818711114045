import { useEffect, useRef } from "react";

export interface ThreeCanvasProps<T, U> {
	width: number;
	height: number;
	setupArgs: U;
	setupFunc: (canvas: HTMLCanvasElement, ctx: WebGLRenderingContext, setupArgs: U) => T;
	updateFunc: (args: T, dt: number) => void;
}

export const ThreeCanvas = <T, U>(props: ThreeCanvasProps<T, U>): JSX.Element => {
	const {
		width,
		height,
		setupArgs,
		setupFunc,
		updateFunc
	} = props;

	const args = useRef<T | null>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) {
			return;
		}

		const ctx = canvas.getContext("webgl2");
		if (!ctx) {
			return;
		}

		args.current = setupFunc(canvas, ctx, setupArgs);

		return () => {
			args.current = null;
		};
	}, []);

	useEffect(() => {
		const drawFunc = (t: DOMHighResTimeStamp) => {
			const dt = lastTime === 0 ? 0 : t - lastTime;
			lastTime = t;

			updateFunc(args.current as T, dt);
			handle = requestAnimationFrame(drawFunc);
		};

		let lastTime = 0;
		let handle = requestAnimationFrame(drawFunc);

		return () => {
			cancelAnimationFrame(handle);
		};
	}, [updateFunc]);

	return (
		<canvas ref={canvasRef} width={width} height={height} />
	);
};
