import { MutationFunction, useQueryClient } from "react-query";
import { CollectionModel } from "./models/CollectionModel";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface CollectionUpdateRequestModel {
	name: string;
	valued: boolean;
	description: string;
	pinned: boolean;
	image: number;
}

export interface CollectionUpdateRequestProperties {
	requestModel: CollectionUpdateRequestModel;
	id: string;
}

export const useCollectionUpdate = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<CollectionModel>, CollectionUpdateRequestProperties> = requestProperties => {
		return http<CollectionModel, CollectionUpdateRequestModel>({
			method: "PUT",
			url: "/collection/" + requestProperties.id,
			body: requestProperties.requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: () => {
			queryClient.invalidateQueries(["dashboardCurrentPosition"]);
			queryClient.invalidateQueries(["dashboardHistoricalPositions"]);
			queryClient.invalidateQueries(["collections"]);
			queryClient.invalidateQueries(["goalCollection"]);
		}
	});
};
