import { useEffect, useState } from "react";
import envelope from "@fyendalscollection/app/assets/envelope.svg";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { useFocus } from "@fyendalscollection/app/lib/useFocus";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { BannerMessage } from "@fyendalscollection/app/lib/components/BannerMessage";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { useAuthInitiateForgotPassword } from "@fyendalscollection/shared";

export const PageInitiateForgotPassword = (): JSX.Element => {
	useTitle("Forgot Password");

	const authForgotPassword = useAuthInitiateForgotPassword();
	const [ref, focus] = useFocus();

	const [emailAddress, setEmailAddress] = useState("");

	const emailAddressValid = !!emailAddress && emailAddress.indexOf("@") > 0;

	// Focus the emailAddress TextEntry when we land on the page.
	useEffect(() => focus(), []);

	const mutateFunc = () => authForgotPassword.mutate({
		emailAddress
	});

	const formContent = !authForgotPassword.isSuccess ? (
		<>
			<Container title="Forgot Password">
				<ContainerSegment>
					Please provide your email address, we will send you a password reset email
				</ContainerSegment>
				<ContainerSegment>
					<TextEntry
						ref={ref}
						type="email"
						inputMode="email"
						placeholder="Email Address"
						value={emailAddress}
						onChange={setEmailAddress}
						onEnter={mutateFunc} />
				</ContainerSegment>

				{authForgotPassword.isError && <ContainerSegment>
					<ErrorDisplay problemResponse={authForgotPassword.error}/>
				</ContainerSegment>}
			</Container>

			<Container transparent={true}>
				<GreenButtonSet buttons={[
					{
						text: "Confirm",
						disabled: !emailAddressValid,
						onClick: mutateFunc
					},
					{
						text: "Back",
						link: ".."
					}
				]} />
			</Container>
		</>
	) : (
		<>
			<Container>
				<ContainerSegment>
					<BannerMessage image={envelope} title="Forgot Password" content="We've just sent you an email with instructions on how to reset your password.  If you cannot find the email, you may need to check your spam folder."/>
				</ContainerSegment>
			</Container>

			<Container transparent={true}>
				<GreenButtonSet buttons={[
					{
						text: "Back",
						link: ".."
					}
				]} />
			</Container>
		</>
	);

	return (
		<Loadable loading={authForgotPassword.isLoading}>
			{formContent}
		</Loadable>
	);
};
