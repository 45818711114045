import { NotificationModel } from "@fyendalscollection/shared";
import styles from "./NotificationDisplay.module.scss";
import dayjs from "dayjs";
import Markdown from "react-markdown";

export interface NotificationDisplayProps {
	showReadStatus: boolean;
	notification: NotificationModel;
}

export const NotificationDisplay = (props: NotificationDisplayProps) => {
	const classNames = [styles.marker];
	if (props.notification.readAt) {
		classNames.push(styles.read);
	}

	return (
		<div className={styles.notification}>
			{
				!props.showReadStatus &&
				<div className={styles.readMarkerContainer}>
					<div className={classNames.join(" ")}></div>
				</div>
			}
			<div className={styles.notificationBodyContainer}>
				<div className={styles.notificationBody}>
					<div className={styles.subject}>{props.notification.subject}</div>
					<div className={styles.created}>{dayjs(props.notification.created).format("DD MMM YYYY")}</div>
				</div>
				<div className={styles.message}>
					<Markdown>
						{props.notification.message}
					</Markdown>
				</div>
			</div>
		</div>
	);
};
