import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface GetImportStatusResponseModel {
	readonly import: GetImportStatusResponseModelImport;
}

export interface GetImportStatusResponseModelImport {
	readonly importId: string;
	readonly validRowCount: number;
	readonly invalidRowCount: number;
	readonly invalidRows: GetImportResponseModelInvalidRow[];
}

export interface GetImportResponseModelInvalidRow {
	readonly importRowId: string;
	readonly quantityText: string;
	readonly quantityError: string | null;
	readonly fcIdText: string;
	readonly fcIdError: string | null;
	readonly actionText: string;
	readonly actionError: string | null;
	readonly unitAmountText: string;
	readonly unitAmountError: string | null;
	readonly currencyText: string;
	readonly currencyError: string | null;
	readonly dateText: string;
	readonly dateError: string | null;
}

export const useImportStatus = (collectionId: string | undefined) => {
	const http = useHTTP(HTTPAuthMode.Required);

	return useSimpleQuery(["importStatus", collectionId], async () => {
		return http<GetImportStatusResponseModel>({
			method: "GET",
			url: `/import${collectionId ? "?collectionId=" + collectionId : ""}`
		});
	});
};
