import { QueryFunction, QueryFunctionContext, QueryKey, useInfiniteQuery, UseInfiniteQueryOptions, UseInfiniteQueryResult } from "react-query";
import { ProblemResponse, Response } from "./useHTTP";
import { PaginatedResponseModel } from "./PaginatedResponseModel";

export const useSimpleQueryWithCursor = <T>(
	queryKey: QueryKey,
	queryFunc: QueryFunction<Response<PaginatedResponseModel<T>>>,
	options?: Omit<UseInfiniteQueryOptions<PaginatedResponseModel<T>, ProblemResponse>, "queryKey" | "queryFn" | "keepPreviousData" | "getNextPageParam">
): UseInfiniteQueryResult<PaginatedResponseModel<T>, ProblemResponse> => {
	return useInfiniteQuery(queryKey, async ctx => {
		const result = await Promise.resolve(queryFunc(ctx));
		return result.body;
	}, {
		...options,
		getNextPageParam: lastPage => lastPage?.nextCursor
	});
};

export type InfiniteQueryPageContext = QueryFunctionContext<QueryKey, number>;
