import { Link } from "react-router-dom";
import styles from "./GreenButtonSet.module.scss";

export interface GreenButtonSetProps {
	buttons: GreenButton[];
}

export interface GreenButton {
	text: React.ReactNode;
	disabled?: boolean;
	onClick?: () => void;
	link?: string;
	newTabLink?: boolean;
}

export const GreenButtonSet = ({ buttons }: GreenButtonSetProps): JSX.Element => {
	const buttonDivs = buttons.map((x, i) => {
		const disabled = x.disabled || (!x.onClick && !x.link);
		const classNames = [ styles.button ];
		if (disabled) {
			classNames.push(styles.disabled);
		}

		const buttonDiv = (
			<div className={classNames.join(" ")}>
				{x.text}
			</div>
		);

		if (disabled) {
			return <div key={i}>{buttonDiv}</div>;
		}

		if (x.link) {
			return <Link
				key={i}
				to={x.link}
				onClick={x.onClick}
				target={x.newTabLink ? "_blank" : undefined}
				rel={x.newTabLink ? "noopener noreferrer" : undefined}>{buttonDiv}</Link>;
		}

		return <div key={i} onClick={x.onClick}>{buttonDiv}</div>;
	});

	return (
		<div className={styles.greenButtonSet}>
			{buttonDivs}
		</div>
	);
};
