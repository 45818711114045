import { Link } from "react-router-dom";
import styles from "./Gallery.module.scss";
import { UseInfiniteQueryResult } from "react-query";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { PaginatedResponseModel, ProblemResponse } from "@fyendalscollection/shared";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";

export interface GalleryProps<T> {
	children: React.ReactNode;
	infiniteScrollerQuery?: UseInfiniteQueryResult<PaginatedResponseModel<T>, ProblemResponse>;
}

export const Gallery = <T,>({ children, infiniteScrollerQuery }: GalleryProps<T>): JSX.Element => {
	return (
		<div className={styles.gallery}>
			{children}

			{
				infiniteScrollerQuery &&
				<InfiniteScroller forQuery={infiniteScrollerQuery} />
			}
		</div>
	);
};

export interface GalleryItemDetail {
	description: string;
	value: React.ReactNode;
}

export interface GalleryItemProps {
	title: string;
	subtitle: string;
	imageUrl: string;
	faded?: boolean;
	imagePrefix?: React.ReactNode;
	imageLink: string;
	details: GalleryItemDetail[];
}

export const GalleryItem = (props: GalleryItemProps): JSX.Element => {
	const {
		title,
		subtitle,
		imageUrl,
		faded = false,
		imagePrefix,
		imageLink,
		details
	} = props;

	const classNames = [styles.galleryItem];
	if (faded) {
		classNames.push(styles.faded);
	}

	return (
		<div className={classNames.join(" ")}>
			<div className={styles.titles}>
				<div>{title}</div>
				<div>{subtitle}</div>
			</div>

			{
				imagePrefix !== undefined &&
				<div className={styles.imagePrefixHolder}>
					{imagePrefix}
				</div>
			}

			<div className={styles.image}>
				<Link to={imageLink}>
					<ProductImage className={styles.productImage} image={imageUrl} />
				</Link>
			</div>

			{
				details.map((detail, i) => (
					<div key={i} className={styles.galleryItemDetail}>
						<div>{detail.description}</div>
						{detail.value}
					</div>
				))
			}
		</div>
	);
};
