import { Gallery, GalleryItem } from "@fyendalscollection/app/lib/components/Gallery";
import { Money, TransactionSummaryModel } from "@fyendalscollection/shared";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { Padlocked } from "@fyendalscollection/app/lib/components/Padlocked";

export interface CollectionGalleryViewProps {
	transactionSummaries: TransactionSummaryModel[];
	scrollerElement: React.ReactNode;
	collectionId: string;
}

export const CollectionGalleryView = (props: CollectionGalleryViewProps): JSX.Element => {

	const getSingleItemValue = (total: Money, quantity: number): Money => {
		if (quantity === 0) {
			return {
				value: 0,
				currency: total.currency
			};
		}

		return {
			value: total.value/quantity,
			currency: total.currency
		};
	};

	return (
		<Gallery>
			{
				props.transactionSummaries.map(x => (
					<GalleryItem
						key={x.variantId}
						title={x.productName}
						subtitle={x.fcId}
						imageUrl={x.imageUrl}
						imageLink={`/collections/${props.collectionId}/transactions/${x.variantId}`}
						details={[
							{
								description: "Value",
								value: <PrettyMoney money={getSingleItemValue(x.marketValue, x.quantity)} mode={MoneyDisplayMode.Standard} />
							},
							{
								description: "Quantity",
								value: x.quantity
							},
							{
								description: "Total",
								value: <PrettyMoney money={x.marketValue} mode={MoneyDisplayMode.Standard} />
							},
							{
								description: "Invested",
								value: <Padlocked on={x.invested} element={invested => (
									<PrettyMoney money={invested} mode={MoneyDisplayMode.Standard}/>)} />
							},
							{
								description: "Gain",
								value: <Padlocked on={x.gain} element={gain => (
									<PrettyMoney money={gain} mode={MoneyDisplayMode.DeltaPercentage} originalValue={x.invested?.value || 0}/>)}/>
							}
						]} />
				))
			}

			{props.scrollerElement}
		</Gallery>
	);
};
