import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CurrencySelector } from "@fyendalscollection/app/lib/components/CurrencySelector";
import { useAuthState } from "@fyendalscollection/shared";
import { REQUIRES_PASSWORD_TO_REGISTER } from "@fyendalscollection/app/lib/config";
import { Currency, useAuthRegister } from "@fyendalscollection/shared";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { useFocus } from "@fyendalscollection/app/lib/useFocus";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";

export const PageRegister = (): JSX.Element => {
	useTitle("Register");

	const authRegister = useAuthRegister();
	const navigate = useNavigate();
	const [ref, focus] = useFocus();

	const [displayName, setDisplayName] = useState("");
	const [emailAddress, setEmailAddress] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [preferredCurrency, setPreferredCurrency] = useState<Currency>(Currency.USD);
	const [registerPassword, setRegisterPassword] = useState<string>("");
	const { state } = useAuthState();

	// Focus the displayName TextEntry when we land on the page.
	useEffect(() => focus(), []);

	// If the useAuthRegister status changes to success, we now need to prompt
	// for email verification.
	useEffect(() => {
		if (authRegister.isSuccess) {
			navigate("../email-verify");
		}
	}, [authRegister.isSuccess]);

	const displayNameIsDuplicate =
		authRegister.isError &&
		authRegister.variables &&
		authRegister.variables.displayName == displayName && (
			authRegister.error.problem.problemType == "DuplicateEmailAddressAndDisplayName" ||
			authRegister.error.problem.problemType == "DuplicateDisplayName"
		);

	const emailAddressIsDuplicate =
		authRegister.isError &&
		authRegister.variables &&
		authRegister.variables.emailAddress == emailAddress && (
			authRegister.error.problem.problemType == "DuplicateEmailAddressAndDisplayName" ||
			authRegister.error.problem.problemType == "DuplicateEmailAddress"
		);

	const displayNameValid =
		!!displayName &&
		displayName.length > 1 &&
		displayName.length < 41 &&
		!displayNameIsDuplicate;

	const emailAddressValid =
		!!emailAddress &&
		emailAddress.indexOf("@") > 0 &&
		!emailAddressIsDuplicate;

	const passwordValid = password.length > 7;
	const confirmPasswordValid = !!confirmPassword && confirmPassword === password;

	const formValid = displayNameValid && emailAddressValid && passwordValid && confirmPasswordValid;

	return (
		<Loadable loading={authRegister.isLoading}>
			<Container title="Your Information">

				<ContainerSegment>
					<TextEntry
						ref={ref}
						placeholder="Display Name"
						valid={displayNameValid}
						value={displayName}
						onChange={setDisplayName} />
				</ContainerSegment>

				<ContainerSegment>
					<TextEntry
						inputMode="email"
						placeholder="Email Address"
						valid={emailAddressValid}
						type="email"
						value={emailAddress}
						onChange={setEmailAddress} />
				</ContainerSegment>

				<ContainerSegment>
					<TextEntry
						placeholder="Password"
						valid={passwordValid}
						type="password"
						value={password}
						onChange={setPassword} />
				</ContainerSegment>

				<ContainerSegment>
					<TextEntry
						placeholder="Confirm Password"
						valid={confirmPasswordValid}
						type="password"
						value={confirmPassword}
						onChange={setConfirmPassword} />
				</ContainerSegment>

				<ContainerSegment>
					<CurrencySelector
						value={preferredCurrency}
						onChange={setPreferredCurrency} />
				</ContainerSegment>

				{
					REQUIRES_PASSWORD_TO_REGISTER && (
						<ContainerSegment>
							<TextEntry
								placeholder="Register Password"
								type="password"
								value={registerPassword}
								onChange={setRegisterPassword} />
						</ContainerSegment>
					)
				}

				{authRegister.isError && <ContainerSegment>
					<ErrorDisplay problemResponse={authRegister.error}/>
				</ContainerSegment>}
			</Container>

			<Container transparent={true}>
				<GreenButtonSet buttons={[
					{
						text: "Register",
						disabled: !formValid,
						onClick: () => authRegister.mutate({
							emailAddress,
							password,
							displayName,
							preferredCurrency,
							registerPassword: registerPassword ? registerPassword : undefined,
							attribution: state.attribution
						})
					},
					{
						text: "Back",
						link: ".."
					}
				]} />
			</Container>
		</Loadable>
	);
};
