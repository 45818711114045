import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { TabularData, TabularDataHeaderStyle } from "@fyendalscollection/app/lib/components/TabularData";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { useUserPreferencesState } from "@fyendalscollection/shared";
import { Currency, Money, TransactionAction, TransactionDetailsSortOrder, useCollection, usePreferredCurrency, useTransactionCreate, useTransactionDetails } from "@fyendalscollection/shared";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { StyledSelect } from "@fyendalscollection/app/lib/components/StyledSelect";
import { CollectionTab, CollectionTabSelector } from "@fyendalscollection/app/features/collections/components/CollectionTabSelector";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { TransactionItem, TransactionItemDestination } from "@fyendalscollection/app/features/transactions/components/TransactionItem";
import { CallToAction } from "@fyendalscollection/app/lib/components/CallToAction";
import { SearchPanelUsage, SearchPanelVariant, useSearchPanel } from "@fyendalscollection/app/lib/useSearchPanel";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ScreenSizeView, useScreenSize } from "@fyendalscollection/app/lib/ScreenSizeContext";
import { Loader } from "@fyendalscollection/app/lib/components/Loader";

export const PageTransactions = (): JSX.Element => {
	const {
		state,
		dispatchSetTransactionDetailsSortOrder
	} = useUserPreferencesState();

	const { collectionId } = useParams();
	const collection = useCollection(collectionId || "");
	const transactionDetails = useTransactionDetails(collectionId || "", state.transactionDetailsSortOrder);
	const preferredCurrency = usePreferredCurrency();
	const screenSize = useScreenSize();

	const { mutate, isLoading, isError } = useTransactionCreate();

	const onVariantSelected = (variant: SearchPanelVariant) => {
		if (!collectionId) {
			return;
		}

		const unitAmount: Money = variant.marketPrice || {
			currency: preferredCurrency.data ? preferredCurrency.data.currency : Currency.USD,
			value: 0.00
		};

		mutate({
			requestModel: {
				variantId: variant.variantId,
				occurredDate: dayjs().format("YYYY-MM-DD"),
				action: TransactionAction.Purchase,
				quantity: 1,
				unitAmount: unitAmount
			},
			collectionId: collectionId
		});
	};

	const showSearchPanel = useSearchPanel(SearchPanelUsage.VariantId, onVariantSelected);

	const display = !transactionDetails.data || !collectionId ? [] :
		transactionDetails.data.pages.flatMap(x => x.results).map(transaction => (
			<TransactionItem key={transaction.transactionId} transaction={transaction} collectionId={collectionId} destination={TransactionItemDestination.VariantTransactions} />
		));

	return (
		<Limit force={LimitWidth.Massive}>
			<Loadable loading={collection.isLoading}>
				{
					collection.data && (
						<>
							<Breadcrumb crumbs={[
								{
									text: "Dashboard",
									link: "/dashboard"
								},
								{
									text: "Collections",
									link: "/collections"
								},
								{
									text: collection.data.name,
									link: `/collections/${collectionId}`
								},
								{
									text: "Transactions"
								}
							]}/>

							<PageTitle
								title={collection.data.name}
								subTitle={collection.data.description}
								primaryActions={<CollectionTabSelector selected={CollectionTab.Edit} />}
								secondaryActions={
									<>
										<StyledSelect value={state.transactionDetailsSortOrder} onChange={dispatchSetTransactionDetailsSortOrder} options={[
											{ text: "Sort: Chronological", value: TransactionDetailsSortOrder.Chronological },
											{ text: "Sort: Alphabetical", value: TransactionDetailsSortOrder.Alphabetical }
										]} />

										<GreenButtonSet buttons={[
											{
												text: "Import",
												link: `/collections/${collectionId}/transactions/csv-import`
											},
											{
												text: "Export",
												link: `/collections/${collectionId}/transactions/csv-export`
											}
										]} />
									</>
								} />

							{
								screenSize === ScreenSizeView.Mobile && !isLoading &&
								<>
									<GreenButtonSet buttons={[
										{
											text: "Create Transaction",
											disabled: !showSearchPanel,
											onClick: showSearchPanel || undefined
										}
									]} />
									<br /><br />
								</>
							}

							{
								screenSize === ScreenSizeView.Mobile && isLoading &&
								<Loader />
							}

							{
								isError &&
								<Container>
									<ContainerSegment>
										<ErrorDisplay message="Failed to create transaction!  Try again soon." />
									</ContainerSegment>
								</Container>
							}

							{
								display && display.length > 0 && (
									<TabularData headerStyle={TabularDataHeaderStyle.CenteredExceptFirst} headers={["Product", "QTY", "Action", "Unit Amount", "Total Amount", "Date", ""]}>
										{
											screenSize === ScreenSizeView.Mobile
												? <></>
												: (
													<TabularDataItem>
														<>
															{
																isLoading
																	? <Loader />
																	: <LinkButton text="+ Create Transaction" onClick={showSearchPanel ? showSearchPanel : undefined} />
															}
														</>
														<></>
														<></>
														<></>
														<></>
														<></>
														<></>
													</TabularDataItem>
												)
										}
										{display}
									</TabularData>
								)
							}

							{
								transactionDetails.data && transactionDetails.data.pages.flatMap(x => x.results).length === 0 &&
								<CallToAction
									title="Create your first transaction!"
									subtitle="Start by searching for the product you'd like to add to this collection!"
									buttonText="Create Transaction"
									onClick={showSearchPanel ? showSearchPanel : undefined} />
							}
						</>
					)
				}
			</Loadable>
			<InfiniteScroller forQuery={transactionDetails}/>
		</Limit>
	);
};
