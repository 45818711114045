import styles from "./StyledAutoCompleteBox.module.scss";
import { AutoCompleteBox, AutoCompleteBoxProps } from "@fyendalscollection/app/lib/components/AutoCompleteBox";

export const StyledAutoCompleteBox = (props: AutoCompleteBoxProps): JSX.Element => {
	return (
		<div className={styles.searchBar}>
			<AutoCompleteBox {...props} />
		</div>
	);
};
