import { MutationFunction, useQueryClient } from "react-query";
import { TransactionDetailsModel } from "./models/TransactionDetailsModel";
import { clearCacheAfterTransactionMutation } from "./clearCacheAfterTransactionMutation";
import { Money, TransactionDetailsSortOrder } from "../../domain";
import { HTTPAuthMode, Response, updateQueryKeyIfExists, useHTTP, useSimpleMutation } from "../../http";

export interface TransactionUpdateRequestModel {
	occurredDate?: string;
	action?: string;
	quantity?: number;
	unitAmount?: Money;
}

export interface TransactionUpdateRequestProperties {
	requestModel: TransactionUpdateRequestModel;
	transactionId: string;
	collectionId: string;
	variantId: string;
}

export const useTransactionUpdate = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<TransactionDetailsModel>, TransactionUpdateRequestProperties> = requestProperties => {
		return http<TransactionDetailsModel, TransactionUpdateRequestModel>({
			method: "PATCH",
			url: "/transaction/" + requestProperties.transactionId,
			body: requestProperties.requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (data, requestProperties) => {
			updateQueryKeyIfExists(queryClient, ["transactions", requestProperties.collectionId, TransactionDetailsSortOrder.Chronological], x => x.transactionId == data.body.transactionId, data.body);
			updateQueryKeyIfExists(queryClient, ["transactions", requestProperties.collectionId, TransactionDetailsSortOrder.Alphabetical], x => x.transactionId == data.body.transactionId, data.body);
			updateQueryKeyIfExists(queryClient, ["transactions", requestProperties.collectionId, requestProperties.variantId], x => x.transactionId == data.body.transactionId, data.body);
			clearCacheAfterTransactionMutation(queryClient, requestProperties.collectionId, requestProperties.variantId);
		}
	});
};
