import { MutationFunction, useQueryClient } from "react-query";
import { PriceOverrideDetailsModel } from "./models/PriceOverrideDetailsModel";
import { clearCacheAfterPriceOverrideMutation } from "./clearCacheAfterPriceOverrideMutation";
import { HTTPAuthMode, Response, paginatedUpdate, useHTTP, useSimpleMutation } from "../../http";
import { Money } from "../../domain";

export interface PriceOverrideUpdateRequestModel {
	price: Money;
}

export interface PriceOverrideUpdateRequestProperties {
	requestModel: PriceOverrideUpdateRequestModel;
	priceOverrideId: string;
}

export const usePriceOverrideUpdate = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<PriceOverrideDetailsModel>, PriceOverrideUpdateRequestProperties> = requestProperties => {
		return http<PriceOverrideDetailsModel, PriceOverrideUpdateRequestModel>({
			method: "PUT",
			url: `/price-override/${requestProperties.priceOverrideId}`,
			body: requestProperties.requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (data) => {
			queryClient.setQueryData(["priceOverrideDetails"], paginatedUpdate(data.body, x => x.priceOverrideId == data.body.priceOverrideId));
			clearCacheAfterPriceOverrideMutation(queryClient);
		}
	});
};
