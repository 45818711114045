import { EbayEdition, EbayFoiling, EbaySet, Money, RecentSaleSource } from "../../domain";
import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface GetProductRecentSalesResponseModel {
	results: ProductRecentSale[] | null;
}

export interface ProductRecentSale {
	recentSaleId: string;
	title: string;
	saleDate: string;
	imageUrl: string;
	price: Money;
	sourceUrl: string;
	recentSaleSource: RecentSaleSource;
	setInference: EbaySet | null;
	editionInference: EbayEdition | null;
	foilingInference: EbayFoiling | null;
}

export const useProductRecentSales = (productId: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["recentSales", productId], () => {
		return http<GetProductRecentSalesResponseModel>({
			method: "GET",
			url: `/product/${productId}/recent-sales`
		});
	});
};
