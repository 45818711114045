import { MutationFunction } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export const usePushAppLoadEvent = () => {
	const http = useHTTP(HTTPAuthMode.Required);

	const mutFunc: MutationFunction<Response, void> = () => {
		return http<undefined, undefined>({
			method: "POST",
			url: "/analytics/app-load",
			body: undefined
		});
	};

	return useSimpleMutation(mutFunc);
};
