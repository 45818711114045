import { useEffect, useRef, useState } from "react";
import styles from "./PageLogin.module.scss";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "@fyendalscollection/shared";
import { ModalMfa } from "@fyendalscollection/app/features/auth/components/ModalMfa";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { useFocus } from "@fyendalscollection/app/lib/useFocus";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { useAuthLogin } from "@fyendalscollection/shared";

export const PageLogin = (): JSX.Element => {
	useTitle("Login");

	const { state, dispatchAuthSetPostLoginRedirectURL } = useAuthState();
	const authLogin = useAuthLogin();
	const navigate = useNavigate();
	const [ref, focus] = useFocus();
	const refPassword = useRef<HTMLInputElement>(null);

	const [emailAddress, setEmailAddress] = useState("");
	const [password, setPassword] = useState("");
	const [mfaModalVisible, setMfaModelVisible] = useState(false);
	const [autofilled, setAutofilled] = useState<boolean | undefined>(false);

	// Focus the emailAddress TextEntry when we land on the page.
	useEffect(() => focus(),  []);

	useEffect(() => {
		if (authLogin.data) {
			if (!authLogin.data.body.emailVerified) {
				navigate("/login/email-verify");
				return;
			}

			if (!authLogin.data.body.accessToken) {
				setMfaModelVisible(true);
				return;
			}

			if (state.postLoginRedirectURL) {
				dispatchAuthSetPostLoginRedirectURL(undefined);
				navigate(state.postLoginRedirectURL);
				return;
			}

			navigate("/dashboard");
		}
	}, [authLogin.data]);

	const emailAddressValid = !!emailAddress && emailAddress.indexOf("@") > 0;
	const passwordValid = password.length > 7;

	const formValid = emailAddressValid && passwordValid;

	const mutateFunc = () => authLogin.mutate({
		emailAddress,
		password
	});

	useEffect(() => {
		const checkAutofilled = () => {
			if(!autofilled) {
				setAutofilled(
					ref.current?.matches("*:autofill")
					&& refPassword.current?.matches("*:autofill"));
			}
		};

		setTimeout(checkAutofilled, 250);
		setTimeout(checkAutofilled, 500);
		setTimeout(checkAutofilled, 750);
		setTimeout(checkAutofilled, 1000);
		setTimeout(checkAutofilled, 1500);
	}, []);

	return (
		<>
			<Loadable loading={authLogin.isLoading}>
				<Container title="Login Credentials">

					<ContainerSegment>
						<TextEntry
							ref={ref}
							inputMode="email"
							type="email"
							placeholder="Email Address"
							value={emailAddress}
							onChange={setEmailAddress} />
					</ContainerSegment>

					<ContainerSegment>
						<TextEntry
							ref={refPassword}
							placeholder="Password"
							type="password"
							value={password}
							onChange={setPassword}
							onEnter={mutateFunc}/>
					</ContainerSegment>

					{authLogin.isError && <ContainerSegment>
						<ErrorDisplay problemResponse={authLogin.error}/>
					</ContainerSegment>}
				</Container>

				<Container transparent={true}>
					<GreenButtonSet buttons={[
						{
							text: "Login",
							disabled: !autofilled && !formValid,
							onClick: mutateFunc
						}
					]} />
				</Container>

				<Container title="Need Help?" transparent={true}>
					<GreenButtonSet buttons={[
						{
							text: "Register",
							link: "register"
						},
						{
							text: "Forgot Password",
							link: "forgot-password/initiate"
						}
					]} />
				</Container>

				<Container transparent={true}>
					<div className={styles.termsAndConditions}>
						By signing in to Fyendal&apos;s Collection, you agree to our <a href="https://fyendalscollection.com/terms-and-conditions">Terms and Conditions</a>.
					</div>
				</Container>
			</Loadable>

			<ModalMfa
				visible={mfaModalVisible}
				onCancel={() => setMfaModelVisible(false)}
				onConfirm={mfaCode => authLogin.mutate({
					emailAddress,
					password,
					mfaCode
				})} />
		</>
	);
};
