import { Money } from "@fyendalscollection/shared";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

export enum SearchPanelUsage {
	ProductLookup,
	VariantId
}

export type SearchPanelCallback<U extends SearchPanelUsage> = U extends SearchPanelUsage.VariantId
	? (variant: SearchPanelVariant) => void
	: null;

export const useSearchPanel = <U extends SearchPanelUsage>(usage: U, callback: SearchPanelCallback<U>) => {
	const manager = useContext(subscriptionContext) as SearchPanelSubscriptionManager;
	const [currentUsage, setCurrentUsage] = useContext(usageContext) as [SearchPanelUsage | null, (usage: SearchPanelUsage | null) => void];
	const invoke = useMemo(() => {
		if (currentUsage) {
			return null;
		}

		return () => setCurrentUsage(usage);
	}, [usage, currentUsage, setCurrentUsage]);

	useEffect(() => {
		if (usage !== SearchPanelUsage.VariantId) {
			return;
		}

		const variantIdCallback = callback as SearchPanelCallback<SearchPanelUsage.VariantId>;
		manager.subscribe(variantIdCallback);
		return () => manager.unsubscribe(variantIdCallback);
	}, [usage, callback]);

	return invoke;
};

export const useSearchPanelUsage = () => {
	const currentUsageState = useContext(usageContext) as [SearchPanelUsage | null, (usage: SearchPanelUsage | null) => void];
	return currentUsageState;
};

export const useSearchPanelManager = () => useContext(subscriptionContext) as SearchPanelSubscriptionManager;

export interface SearchPanelProviderProps {
	manager: SearchPanelSubscriptionManager;
	children: React.ReactNode;
}

export const SearchPanelProvider = ({ manager, children }: SearchPanelProviderProps) => {
	const currentUsageState = useState<SearchPanelUsage | null>(null);

	return (
		<subscriptionContext.Provider value={manager}>
			<usageContext.Provider value={currentUsageState}>
				{children}
			</usageContext.Provider>
		</subscriptionContext.Provider>
	);
};

export interface SearchPanelVariant {
	readonly variantId: string;
	readonly productId: string;
	readonly imageUrl: string;
	readonly fcId: string;
	readonly marketPrice: Money | null;
	readonly productName: string;
	readonly variantName: string;
}

export class SearchPanelSubscriptionManager {
	private _subscriptions: ((variant: SearchPanelVariant) => void)[];

	constructor() {
		this._subscriptions = [];
	}

	subscribe(callback: (variant: SearchPanelVariant) => void) {
		this._subscriptions.push(callback);
	}

	emit(variant: SearchPanelVariant) {
		this._subscriptions.forEach(subscription => subscription(variant));
	}

	unsubscribe(callback: (variant: SearchPanelVariant) => void) {
		const index = this._subscriptions.indexOf(callback);
		if (index >= 0) {
			this._subscriptions.splice(index, 1);
		}
	}
}

const subscriptionContext = createContext<SearchPanelSubscriptionManager | null>(null);
const usageContext = createContext<[SearchPanelUsage | null, (usage: SearchPanelUsage | null) => void] | null>(null);
