import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { CollectionVariantDetailsModel } from "./models/CollectionVariantDetailsModel";

export const useCollectionVariantDetails = (collectionId: string, variantId: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["collectionVariantDetails", collectionId, variantId ], () => {
		return http<CollectionVariantDetailsModel>({
			method: "GET",
			url: `/collection/${collectionId}/variant/${variantId}`
		});
	});
};
