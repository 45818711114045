import { MutationFunction, useQueryClient } from "react-query";
import { CollectionModel } from "./models/CollectionModel";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface CollectionCreateRequestModel {
	name: string;
}

export const useCollectionCreate = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<CollectionModel>, CollectionCreateRequestModel> = requestModel => {
		return http<CollectionModel, CollectionCreateRequestModel>({
			method: "POST",
			url: "/collection",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: () => {
			queryClient.invalidateQueries(["collections"]);
		}
	});
};
