import { Link, useParams } from "react-router-dom";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import { PremiumPromotionContainer } from "@fyendalscollection/app/lib/components/PremiumPromotionContainer";
import styles from "./PageEvCategoryVariants.module.scss";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { EvSetType } from "@fyendalscollection/shared";
import { useEvBreakdownCategoryVariants } from "@fyendalscollection/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { useSubscription } from "@fyendalscollection/shared";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { Limit } from "@fyendalscollection/app/lib/components/Limit";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";

export const PageEvCategoryVariants = (): JSX.Element => {
	const params = useParams();
	const setType = params["setType"] as EvSetType;
	const category = params["category"] as string;
	const productType = params["productType"] as string;
	useTitle("EV Category Products");

	const subscription = useSubscription();

	return (
		<Limit>
			<Breadcrumb crumbs={[
				{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "EV Tracker",
					link: "/ev-tracker"
				},
				{
					text: "EV Breakdown",
					link: `/ev-tracker/breakdown/${setType}/${productType}`
				},
				{
					text: "EV Category Products"
				}
			]} />

			<PageTitle
				title="EV Category Products"
				subTitle={setType + " " + category} />

			{
				subscription.data && (
					subscription.data.activeSubscription
						? <PageEvCategoryVariantsInternal setType={setType} category={category} />
						: <PremiumPromotionContainer featureName="EV Tracker" />
				)
			}
		</Limit>
	);
};

interface PageEvCategoryVariantsInternalProps {
	setType: EvSetType;
	category: string;
}

const PageEvCategoryVariantsInternal = ({ setType, category }: PageEvCategoryVariantsInternalProps): JSX.Element => {
	const evBreakdownCategoryVariants = useEvBreakdownCategoryVariants(setType, category);

	return (
		<Loadable loading={evBreakdownCategoryVariants.isLoading}>
			<TabularData headers={["Product", "Market Price", "Short Printed"]}>
				{
					(!evBreakdownCategoryVariants.data ? [] : evBreakdownCategoryVariants.data.pages).flatMap(x => x.results).map(x => (
						<TabularDataItem key={x.productId}>
							<>
								<Link to={`/browse/product/${x.productId}`} className={styles.productDetails}>
									<ProductImage className={styles.productImage} image={x.imageUrl} />
									<div>
										<div>{x.productName}</div>
										<div>{x.fcId}</div>
									</div>
								</Link>
							</>
							<><PrettyMoney className={styles.productFigures} money={x.marketPrice} mode={MoneyDisplayMode.Standard}/></>
							<><div className={styles.productFigures}>{x.shortPrintDescription}</div></>
						</TabularDataItem>
					))
				}
			</TabularData>
			<InfiniteScroller forQuery={evBreakdownCategoryVariants}/>
		</Loadable>
	);
};
