import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { SubscriptionModel } from "./models/SubscriptionModel";

export const useSubscription = () => {
	const http = useHTTP(HTTPAuthMode.Required);

	return useSimpleQuery(["subscription"], () => {
		return http<SubscriptionModel>({
			method: "GET",
			url: "/subscription"
		});
	});
};
