import styles from "./PageTransactionsBulk.module.scss";
import { emptyBulkTransactionsDraft, useBulkTransactionsState } from "@fyendalscollection/app/features/transactions/BulkTransactionState";
import { AutoStack } from "@fyendalscollection/app/lib/components/AutoStack";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { Limit } from "@fyendalscollection/app/lib/components/Limit";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { Loader } from "@fyendalscollection/app/lib/components/Loader";
import { ModalConfirmation } from "@fyendalscollection/app/lib/components/ModalConfirmation";
import { NumericEntry } from "@fyendalscollection/app/lib/components/NumericEntry";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import { SearchPanelUsage, useSearchPanel } from "@fyendalscollection/app/lib/useSearchPanel";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { useBulkTransactions, useCollection, usePreferredCurrency, usePreferredPriceSource, useProduct } from "@fyendalscollection/shared";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import rubbishBinDark from "@fyendalscollection/app/assets/rubbish-bin-dark.svg";
import rubbishBinLight from "@fyendalscollection/app/assets/rubbish-bin-light.svg";
import { ThemedImage } from "@fyendalscollection/app/lib/components/ThemedImage";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";

enum SearchDestination {
	Leaving,
	Entering
}

export const PageTransactionsBulk = (): JSX.Element => {
	useTitle("Mass Transaction Entry");

	const collectionId = useParams()["collectionId"] as string;
	const collection = useCollection(collectionId);
	const {
		state,
		dispatchAddLeavingItem,
		dispatchAddEnteringItem,
		dispatchRemoveLeavingItem,
		dispatchRemoveEnteringItem,
		dispatchSetLeavingItemQuantity,
		dispatchSetEnteringItemQuantity,
		dispatchSetCashLeaving,
		dispatchSetCashEntering,
		dispatchReset
	} = useBulkTransactionsState();

	const navigate = useNavigate();
	const bulkTransactions = useBulkTransactions();
	const preferredCurrency = usePreferredCurrency();
	const preferredPriceSource = usePreferredPriceSource();

	const [resetModalVisible, setResetModalVisible] = useState(false);
	const [searchDestination, setSearchDestination] = useState(SearchDestination.Leaving);

	const showSearchPanel = useSearchPanel(SearchPanelUsage.VariantId, variant => {
		if (searchDestination === SearchDestination.Leaving) {
			dispatchAddLeavingItem(collectionId, variant.variantId, variant.productId, 1);
		} else {
			dispatchAddEnteringItem(collectionId, variant.variantId, variant.productId, 1);
		}
	});

	const draft = state[collectionId] || emptyBulkTransactionsDraft();

	useEffect(() => {
		if (!bulkTransactions.isSuccess) {
			return;
		}

		dispatchReset(collectionId);
		setTimeout(() => navigate(".."), 300);
	}, [bulkTransactions.isSuccess]);

	const onAddLeaving = () => {
		if (!showSearchPanel) {
			return;
		}

		setSearchDestination(SearchDestination.Leaving);
		showSearchPanel();
	};

	const onAddEntering = () => {
		if (!showSearchPanel) {
			return;
		}

		setSearchDestination(SearchDestination.Entering);
		showSearchPanel();
	};

	const onConfirm = () => {
		if (!preferredCurrency.data || !preferredPriceSource.data) {
			return;
		}

		bulkTransactions.mutate({
			collectionId,
			requestModel: {
				currency: preferredCurrency.data.currency,
				priceSource: preferredPriceSource.data.priceSource,
				itemsLeaving: draft.itemsLeaving.map(x => ({ variantId: x.variantId, quantity: x.quantity })),
				itemsEntering: draft.itemsEntering.map(x => ({ variantId: x.variantId, quantity: x.quantity })),
				cashLeaving: draft.cashLeaving,
				cashEntering: draft.cashEntering
			}
		});
	};

	const onReset = () => {
		dispatchReset(collectionId);
	};

	return (
		<Limit>
			<Breadcrumb crumbs={[
				{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "Collections",
					link: "/collections"
				},
				{
					text: collection.data?.name || "Collection",
					link: `/collections/${collectionId}`
				},
				{
					text: "Tools",
					link: "../../tools"
				},
				{
					text: "Mass Transaction Entry"
				}]} />

			<Loadable loading={bulkTransactions.isLoading || bulkTransactions.isSuccess}>
				<PageTitle
					title="Mass Transaction Entry"
					subTitle="Record a box opening or trade with ease"
					primaryActions={
						<>
							<LinkButton text="Reset" onClick={() => setResetModalVisible(true)} />
							<GreenButtonSet buttons={[
								{
									text: "Confirm",
									disabled: !preferredCurrency.data || !preferredPriceSource.data,
									onClick: onConfirm
								}
							]} />
						</>
					} />

				{
					bulkTransactions.error &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={bulkTransactions.error} />
						</ContainerSegment>
					</Container>
				}

				<AutoStack>
					<div>
						<Container title="Leaving">
							<ContainerSegment>
								<div className={styles.aligned}>
									<div>Cash Leaving</div>
									<NumericEntry
										value={draft.cashLeaving}
										onChange={x => dispatchSetCashLeaving(collectionId, x)}
										minValue={0}
										maxValue={1000000}
										maxDecimalPlaces={2} />
								</div>
							</ContainerSegment>
						</Container>

						<Container transparent>
							<TabularData headers={["Product", "Quantity", ""]}>
								{
									draft.itemsLeaving.map(x => (
										<VariantDataItem
											key={x.variantId}
											variantId={x.variantId}
											productId={x.productId}
											quantity={x.quantity}
											onSetQuantity={quantity => dispatchSetLeavingItemQuantity(collectionId, x.variantId, quantity)}
											onRemove={() => dispatchRemoveLeavingItem(collectionId, x.variantId)} />
									))
								}
							</TabularData>
						</Container>

						<LinkButton text="+ Add Product" onClick={onAddLeaving} />
					</div>
					<div>
						<Container title="Entering">
							<ContainerSegment>
								<div className={styles.aligned}>
									<div>Cash Entering</div>
									<NumericEntry
										value={draft.cashLeaving}
										onChange={x => dispatchSetCashEntering(collectionId, x)}
										minValue={0}
										maxValue={1000000}
										maxDecimalPlaces={2} />
								</div>
							</ContainerSegment>
						</Container>

						<Container transparent>
							<TabularData headers={["Product", "Quantity", ""]}>
								{
									draft.itemsEntering.map(x => (
										<VariantDataItem
											key={x.variantId}
											variantId={x.variantId}
											productId={x.productId}
											quantity={x.quantity}
											onSetQuantity={quantity => dispatchSetEnteringItemQuantity(collectionId, x.variantId, quantity)}
											onRemove={() => dispatchRemoveEnteringItem(collectionId, x.variantId)} />
									))
								}
							</TabularData>
						</Container>

						<LinkButton text="+ Add Product" onClick={onAddEntering} />
					</div>
				</AutoStack>

				<ModalConfirmation
					visible={resetModalVisible}
					onCancel={() => setResetModalVisible(false)}
					title="Reset"
					description="Are you sure you want to reset your mass transaction entry for this collection?"
					onConfirm={onReset} />
			</Loadable>
		</Limit>
	);
};

interface VariantDataItemProps {
	variantId: string;
	productId: string;
	quantity: number;
	onSetQuantity: (quantity: number) => void;
	onRemove: () => void;
}

const VariantDataItem = (props: VariantDataItemProps): JSX.Element => {
	const {
		variantId,
		productId,
		quantity,
		onSetQuantity,
		onRemove
	} = props;

	const product = useProduct(productId);
	const variant = product.data?.variants.find(x => x.variantId === variantId);

	return (
		<TabularDataItem>
			<>
				{
					!product.data || !variant
						? <Loader />
						: (
							<div className={styles.productDisplay}>
								<ProductImage className={styles.productImage} image={product.data.imageUrl} />
								<div>
									<div>{product.data.name}</div>
									<div>{variant.fcId}</div>
								</div>
							</div>
						)
				}
			</>
			<>
				<NumericEntry
					value={quantity}
					onChange={onSetQuantity}
					maxDecimalPlaces={0}
					minValue={1}
					maxValue={10000} />
			</>
			<>
				<ThemedImage className={styles.rubbishBin} srcLight={rubbishBinLight} srcDark={rubbishBinDark} onClick={onRemove} />
			</>
		</TabularDataItem>
	);
};
