import React, { ForwardedRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ProductSearchBar.module.scss";
import searchIcon from "@fyendalscollection/app/assets/search.svg";
import { useProductsSearch } from "@fyendalscollection/shared";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";

export interface ProductSearchBarProps {
	onProductSelected: (productId: string) => void;
	floating?: boolean;
	onEnter?: (searchQueryText: string) => void;
}

const ProductSearchBarInternal = ({ onProductSelected, floating = false, onEnter }: ProductSearchBarProps, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
	const [searchQueryText, setSearchQueryText] = useState("");
	const [searchQuery, setSearchQuery] = useState("");
	const [focusCount, setFocusCount] = useState(0);

	useEffect(() => {
		setSearchQuery("");

		if (!searchQueryText) {
			return;
		}

		const timeout = setTimeout(() => setSearchQuery(searchQueryText), 500);
		return () => clearTimeout(timeout);
	}, [searchQueryText]);

	const onProductSelectedEnriched = (productId: string) => {
		setSearchQuery("");
		setSearchQueryText("");
		onProductSelected(productId);
		setFocusCount(0);
	};

	useEffect(() => {
		if (focusCount === 0) {
			setSearchQuery("");
			setSearchQueryText("");
		}
	}, [focusCount]);

	const onFocus = floating
		? () => setFocusCount(count => count + 1)
		: () => undefined;

	const onBlur = floating
		? () => setTimeout(() => setFocusCount(count => Math.max(0, count - 1)), 1)
		: () => undefined;

	const onEnterEnriched = (searchQueryText: string) => {
		if(onEnter) {
			setSearchQuery("");
			setSearchQueryText("");
			onEnter(searchQueryText);
		}
	};

	const classNames = [ styles.search ];
	if (floating) {
		classNames.push(styles.floating);
	}

	return (
		<div className={styles.productSearchBar}>
			<div className={classNames.join(" ")}>
				<img src={searchIcon} />
				<div>
					<TextEntry
						placeholder="Search"
						value={searchQueryText}
						onChange={setSearchQueryText}
						ref={ref}
						onEnter={() => onEnterEnriched(searchQueryText)}
						onFocus={onFocus}
						onBlur={onBlur} />
				</div>
			</div>
			{
				searchQuery &&
				<ProductSearchResults
					searchQuery={searchQuery}
					onProductSelected={onProductSelectedEnriched}
					floating={floating}
					onFocus={onFocus}
					onBlur={onBlur} />
			}
		</div>
	);
};

interface ProductSearchResultsProps {
	searchQuery: string;
	onProductSelected: (productId: string) => void;
	floating: boolean;
	onFocus: () => void;
	onBlur: () => void;
}

const ProductSearchResults = ({ searchQuery, onProductSelected, floating, onFocus, onBlur }: ProductSearchResultsProps): JSX.Element => {
	const productsSearch = useProductsSearch(searchQuery);
	const navigate = useNavigate();

	const loadMore = () => {
		navigate("/browse?q=" + searchQuery);
		onBlur();
	};

	if (productsSearch.isLoading) {
		return <></>;
	}

	const classNames = [ styles.results ];
	if (floating) {
		classNames.push(styles.floating);
	}

	if (productsSearch.isError) {
		return (
			<div tabIndex={0} onFocus={onFocus} onBlur={onBlur} className={classNames.join(" ")}>
				<div>
					<ErrorDisplay problemResponse={productsSearch.error} />
				</div>
			</div>
		);
	}

	if (!productsSearch.data) {
		return <></>;
	}

	const resultDivs = productsSearch.data.pages.flatMap(x => x.results).map(result => (
		<div
			key={result.productId}
			tabIndex={0}
			onFocus={onFocus}
			onBlur={onBlur}
			onClick={() => onProductSelected(result.productId)}>
			<ProductImage className={styles.productImage} image={result.imageUrl} />
			<div>
				<div>{result.name}</div>
				<div>{result.number}</div>
			</div>
		</div>
	));

	if (resultDivs.length < 1) {
		return (
			<div tabIndex={0} onFocus={onFocus} onBlur={onBlur} className={classNames.join(" ")}>
				<div>
					No results found
				</div>
			</div>
		);
	}

	return (
		<div className={classNames.join(" ")}>
			{resultDivs}

			{
				productsSearch.hasNextPage && floating &&
					<div className={styles.loadMore} onClick={loadMore} tabIndex={0} onFocus={onFocus} onBlur={onBlur}>
						Load More
					</div>
			}
		</div>
	);
};

export const ProductSearchBar = React.forwardRef<HTMLInputElement, ProductSearchBarProps>(ProductSearchBarInternal);
