import { Currency } from "../../domain";
import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface GetPreferredCurrencyResponseModel {
	currency: Currency;
}

export const usePreferredCurrency = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["preferredCurrency"], () => {
		return http<GetPreferredCurrencyResponseModel>({
			method: "GET",
			url: "/settings/preferred-currency"
		});
	});
};
