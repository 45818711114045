import { MutationFunction, useQueryClient } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface OperationNormaliseQuantityRequestModel {
	normalisedQuantity: number;
}

export interface OperationNormaliseQuantityProps {
	requestModel: OperationNormaliseQuantityRequestModel;
	collectionId: string;
}

export const useOperationNormaliseQuantity = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, OperationNormaliseQuantityProps> = (requestProperties: OperationNormaliseQuantityProps) => {
		return http<undefined, OperationNormaliseQuantityRequestModel>({
			method: "POST",
			url: "/collection/" + requestProperties.collectionId + "/operation/normalise-quantity",
			body: requestProperties.requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (_, requestProperties) => {
			queryClient.invalidateQueries(["collections"]);
			queryClient.invalidateQueries(["collection", requestProperties.collectionId]);
			queryClient.invalidateQueries(["collectionVariantDetails", requestProperties.collectionId]);
			queryClient.invalidateQueries(["dashboardCurrentPosition"]);
			queryClient.invalidateQueries(["dashboardHistoricalPositions"]);
			queryClient.invalidateQueries(["transactionSummaries"]);
			queryClient.invalidateQueries(["transactions", requestProperties.collectionId]);
		}
	});
};
