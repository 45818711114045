
import { TransactionDetailsModel } from "./models/TransactionDetailsModel";
import { TransactionDetailsSortOrder } from "../../domain";
import { HTTPAuthMode, InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";

export const useTransactionDetails = (collectionId: string, sortOrder: TransactionDetailsSortOrder) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQueryWithCursor(["transactions", collectionId, sortOrder], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		return http<PaginatedResponseModel<TransactionDetailsModel>>({
			method: "GET",
			url: `/collection/${collectionId}/transaction?cursor=${pageParam}&sortOrder=${sortOrder}`
		});
	});
};
