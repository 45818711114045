import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { PaymentCardModel } from "./models/PaymentCardModel";

export const usePaymentCards = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["paymentCards"], () => {
		return http<PaymentCardModel[]>({
			method: "GET",
			url: "/payment-card"
		});
	});
};
