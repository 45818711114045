import { Currency, EmblemRarity, Money, PriceSource } from "../../domain";
import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface PublicCollectionSummaryResponseModel {
	name: string;
	description: string;
	displayName: string;
	image: number;
	totalQuantity: number;
	totalMarketValue: Money;
	emblemId: string;
	count: number;
	rarity: EmblemRarity;
}

export const usePublicCollection = (shareToken: string, currency: Currency, priceSource: PriceSource) => {
	const http = useHTTP(HTTPAuthMode.UseIfPresent);
	return useSimpleQuery(["publicCollection", shareToken, currency, priceSource], () => {

		return http<PublicCollectionSummaryResponseModel>({
			method: "GET",
			url: `/collection/public/${shareToken}?currency=${currency}&priceSource=${priceSource}`
		});
	});
};
