import { useMemo, useState } from "react";
import styles from "./PageCollectionProgress.module.scss";
import { Link, useParams } from "react-router-dom";
import { ModalConfigureGoalCollection } from "@fyendalscollection/app/features/collections/components/ModalConfigureGoalCollection";
import { collectionArts } from "@fyendalscollection/app/features/collections/CollectionArts";
import { Gallery, GalleryItem } from "@fyendalscollection/app/lib/components/Gallery";
import { ProgressBar } from "@fyendalscollection/app/lib/components/ProgressBar";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import { ProgressSummariesView, ProgressSummarySortOrder, useCollection, useCollectionProgress, useGoalCollection, useProgressSummaries, useSetGoalCollection, useUserPreferencesState } from "@fyendalscollection/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { CollectionTab, CollectionTabSelector } from "@fyendalscollection/app/features/collections/components/CollectionTabSelector";
import { Checkbox } from "@fyendalscollection/app/lib/components/Checkbox";
import { StyledSelect } from "@fyendalscollection/app/lib/components/StyledSelect";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { CallToAction } from "@fyendalscollection/app/lib/components/CallToAction";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";

export const PageCollectionProgress = (): JSX.Element => {
	const params = useParams();
	const collectionId = params.collectionId as string;
	const collection = useCollection(collectionId);
	const goalCollection = useGoalCollection(collectionId);
	const [modalVisible, setModalVisible] = useState(false);
	const setGoalCollection = useSetGoalCollection();
	const {
		state,
		dispatchSetProgressSummarySortOrder,
		dispatchSetIncludeCompleted,
		dispatchSetProgressSummariesView } = useUserPreferencesState();

	const collectionProgress = useCollectionProgress(collectionId);
	const progressSummaries = useProgressSummaries(collectionId, state.includeCompleted, state.progressSummarySortOrder);
	const progressPercentage = useMemo(() => {
		if (!collectionProgress.data) {
			return null;
		}

		if (collectionProgress.data.totalGoalQuantity === 0) {
			return null;
		}

		return Math.floor(100 * Math.min(collectionProgress.data.totalActualQuantity / collectionProgress.data.totalGoalQuantity, 1));
	}, [collectionProgress.data]);

	useTitle(collection.data ? collection.data.name : "Collection");

	const onRemoveGoalCollection = () => setGoalCollection.mutate({
		collectionId,
		requestModel: { goalCollectionId: null }
	});

	return (
		<Limit force={LimitWidth.Massive}>
			<Breadcrumb crumbs={[
				{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "Collections",
					link: "/collections"
				},
				{
					text: collection.data ? collection.data.name : "Collection",
					link: `/collections/${collectionId}`
				},
				{
					text: "Progress"
				}
			]}/>

			<PageTitle
				title={collection.data ? collection.data.name : "Collection"}
				subTitle="Track progress towards completing your collection!"
				primaryActions={<CollectionTabSelector selected={CollectionTab.Progress} />}
				secondaryActions={
					<>
						<Checkbox
							label="Include completed items"
							onChange={dispatchSetIncludeCompleted}
							isChecked={state.includeCompleted} />

						<StyledSelect value={state.progressSummariesView} onChange={dispatchSetProgressSummariesView} options={[
							{ text: "View: Gallery", value: ProgressSummariesView.Gallery },
							{ text: "View: List", value: ProgressSummariesView.List }
						]} />

						<StyledSelect value={state.progressSummarySortOrder} onChange={dispatchSetProgressSummarySortOrder} options={[
							{ text: "Sort: Alphabetical", value: ProgressSummarySortOrder.Alphabetical },
							{ text: "Sort: Market Value", value: ProgressSummarySortOrder.MarketValue },
							{ text: "Sort: Progress", value: ProgressSummarySortOrder.Progress },
							{ text: "Sort: Card Number", value: ProgressSummarySortOrder.CardNumber }
						]} />
					</>
				} />

			<Loadable loading={goalCollection.isLoading || setGoalCollection.isLoading || collectionProgress.isLoading || progressSummaries.isLoading}>
				{
					goalCollection.error &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={goalCollection.error} />
						</ContainerSegment>
					</Container>
				}

				{
					setGoalCollection.error &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={setGoalCollection.error} />
						</ContainerSegment>
					</Container>
				}

				{
					collectionProgress.error &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={collectionProgress.error} />
						</ContainerSegment>
					</Container>
				}

				{
					progressSummaries.error &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={progressSummaries.error} />
						</ContainerSegment>
					</Container>
				}

				{
					goalCollection.data && !goalCollection.data.goalCollection &&
					<CallToAction
						title="Configure Progress Tracking"
						subtitle="Set another collection as your goal for this collection to begin tracking progress!"
						buttonText="Configure"
						onClick={() => setModalVisible(true)} />
				}

				{
					goalCollection.data && goalCollection.data.goalCollection &&
					<Container>
						<div className={styles.goalCollection}>
							<Link to={`/collections/${goalCollection.data.goalCollection.goalCollectionId}`}>
								<div>
									<div style={{ backgroundImage: `url('${collectionArts[goalCollection.data.goalCollection.image]}')` }}></div>
									<div>
										<div>Goal Collection</div>
										<div>{goalCollection.data.goalCollection.name}</div>
									</div>
								</div>
							</Link>

							<LinkButton onClick={onRemoveGoalCollection} text="Remove" />
						</div>
					</Container>
				}

				{
					goalCollection.data && goalCollection.data.goalCollection && collectionProgress.data &&
					<Container>
						<ContainerSegment>
							<div className={styles.collectionProgress}>
								<div>
									<div>Progress</div>
									<div>{progressPercentage || 0}%</div>
								</div>
								<div>
									<div>Quantity</div>
									<div>{collectionProgress.data.totalActualQuantity}</div>
								</div>
								<div>
									<div>Goal Quantity</div>
									<div>{collectionProgress.data.totalGoalQuantity}</div>
								</div>
								<div>
									<div>Cost to Complete</div>
									<div><PrettyMoney mode={MoneyDisplayMode.Standard} money={collectionProgress.data.costToComplete} /></div>
								</div>
							</div>

							<ProgressBar progress={Math.min(collectionProgress.data.totalActualQuantity / collectionProgress.data.totalGoalQuantity, 1)} />
						</ContainerSegment>
					</Container>
				}

				{
					progressSummaries.data && state.progressSummariesView === ProgressSummariesView.Gallery &&
					<Gallery infiniteScrollerQuery={progressSummaries}>
						{
							progressSummaries.data.pages.flatMap(x => x.results).map(x => (
								<GalleryItem
									key={x.variantId}
									title={x.productName}
									subtitle={x.fcId}
									imageUrl={x.imageUrl}
									faded={x.actualQuantity < 1}
									imagePrefix={<ProgressBar progress={Math.min(x.actualQuantity / x.goalQuantity, 1)} />}
									imageLink={x.actualQuantity > 0 ? `/collections/${collectionId}/transactions/${x.variantId}` : `/browse/product/${x.productId}`}
									details={[
										{
											description: "Quantity",
											value: x.actualQuantity
										},
										{
											description: "Goal Quantity",
											value: x.goalQuantity
										},
										{
											description: "Value",
											value: <PrettyMoney mode={MoneyDisplayMode.Standard} money={x.marketValue} />
										},
										{
											description: "CTC",
											value: <PrettyMoney mode={MoneyDisplayMode.Standard} money={{ currency: x.marketValue.currency, value: x.marketValue.value * Math.max(x.goalQuantity - x.actualQuantity, 0)}} />
										}
									]} />
							))
						}
					</Gallery>
				}

				{
					progressSummaries.data && progressPercentage !== null && state.progressSummariesView === ProgressSummariesView.List &&
					<TabularData headers={["Product", "Progress", "Quantity", "Goal Quantity", "Value", "CTC"]} infiniteScrollerQuery={progressSummaries}>
						{
							progressSummaries.data.pages.flatMap(x => x.results).map(x => (
								<TabularDataItem key={x.variantId}>
									<>
										<Link className={styles.productDisplay} to={x.actualQuantity > 0 ? `/collections/${collectionId}/transactions/${x.variantId}` : `/browse/product/${x.productId}`}>
											<ProductImage className={styles.productImage + (x.actualQuantity < 1 ? ` ${styles.faded}` : "")} image={x.imageUrl} />
											<div>
												<div>{x.productName}</div>
												<div>{x.fcId}</div>
											</div>
										</Link>
									</>
									<><ProgressBar progress={Math.min(x.actualQuantity / x.goalQuantity, 1)} /></>
									<>{x.actualQuantity}</>
									<>{x.goalQuantity}</>
									<><PrettyMoney className={styles.price} mode={MoneyDisplayMode.Standard} money={x.marketValue} /></>
									<><PrettyMoney className={styles.price} mode={MoneyDisplayMode.Standard} money={{ currency: x.marketValue.currency, value: x.marketValue.value * Math.max(x.goalQuantity - x.actualQuantity, 0)}} /></>
								</TabularDataItem>
							))
						}
					</TabularData>
				}
			</Loadable>

			<ModalConfigureGoalCollection
				collectionId={collectionId}
				visible={modalVisible}
				onCancel={() => setModalVisible(false)} />
		</Limit>
	);
};
