import { MutationFunction, useQueryClient } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";
import { PriceSource } from "../../domain";

export interface SetPreferredPriceSourceRequestModel {
	priceSource: PriceSource;
}

export const useSetPreferredPriceSource = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, SetPreferredPriceSourceRequestModel> = requestModel => {
		return http<undefined, SetPreferredPriceSourceRequestModel>({
			method: "POST",
			url: "/settings/preferred-price-source",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (_, requestModel) => {
			queryClient.resetQueries();
			queryClient.setQueryData(["preferredPriceSource"], requestModel);
		}
	});
};
