import { NotificationsList } from "@fyendalscollection/app/features/notifications/components/NotificationsList";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";

export interface ModalNotificationsProps {
	visible: boolean;
	onCancel: () => void;
}

export const ModalNotifications = (props: ModalNotificationsProps): JSX.Element => {
	return (
		<Modal visible={props.visible} onCancel={props.onCancel}>
			<TitleSecondary text="Notifications" />

			{
				props.visible &&
				<NotificationsList notificationsLimit={true} onNotificationClick={props.onCancel}/>
			}

			<GreenButtonSet buttons={[
				{
					text: "View More",
					link: "/notifications",
					onClick: props.onCancel
				}
			]} />
		</Modal>
	);
};
