import { MutationFunction, useQueryClient } from "react-query";
import { TransactionDetailsModel } from "./models/TransactionDetailsModel";
import { clearCacheAfterTransactionMutation } from "./clearCacheAfterTransactionMutation";
import { HTTPAuthMode, Response, prependToQueryKeyIfExists, useHTTP, useSimpleMutation } from "../../http";
import { Money, TransactionDetailsSortOrder } from "../../domain";

export interface TransactionCreateRequestModel {
	variantId: string;
	occurredDate: string;
	action: string;
	quantity: number;
	unitAmount: Money;
}

export interface TransactionCreateRequestProperties {
	requestModel: TransactionCreateRequestModel;
	collectionId: string;
}

export const useTransactionCreate = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<TransactionDetailsModel>, TransactionCreateRequestProperties> = requestProperties => {
		return http<TransactionDetailsModel, TransactionCreateRequestModel>({
			method: "POST",
			url: "/collection/" + requestProperties.collectionId + "/transaction",
			body: requestProperties.requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (data, requestProperties) => {
			prependToQueryKeyIfExists(queryClient, ["transactions", requestProperties.collectionId, TransactionDetailsSortOrder.Chronological], data.body);
			prependToQueryKeyIfExists(queryClient, ["transactions", requestProperties.collectionId, TransactionDetailsSortOrder.Alphabetical], data.body);
			prependToQueryKeyIfExists(queryClient, ["transactions", requestProperties.collectionId, requestProperties.requestModel.variantId], data.body);
			clearCacheAfterTransactionMutation(queryClient, requestProperties.collectionId, requestProperties.requestModel.variantId);
		}
	});
};
