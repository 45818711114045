import React, { ForwardedRef, KeyboardEvent, useState } from "react";
import styles from "./TextEntry.module.scss";
import validIcon from "@fyendalscollection/app/assets/valid.svg";
import invalidIcon from "@fyendalscollection/app/assets/invalid.svg";

export interface TextEntryProps {
	value: string;
	onChange: (value: string) => void;
	placeholder?: string;
	type?: string;
	valid?: boolean;
	onEnter?: () => void;
	onFocus?: () => void;
	onBlur?: () => void;
	inputMode?: "search" | "text" | "numeric" | "email" | "tel" | "url" | "none" | "decimal";
	maxLength?: number;
	newPassword?: boolean;
	className?: string;
}

const TextEntryInternal = (props: TextEntryProps, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
	const {
		value,
		onChange,
		placeholder,
		type = "text",
		valid,
		onEnter,
		onFocus,
		onBlur,
		inputMode,
		maxLength,
		newPassword,
		className
	} = props;

	const [touched, setTouched] = useState(false);

	const onChangeInternal = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value);
		setTouched(true);
	};

	const validityImage = valid === undefined || !touched
		? undefined
		: valid ? validIcon : invalidIcon;

	const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" && onEnter) {
			onEnter();
		}
	};

	return (
		<div className={styles.textEntry}>
			<input
				ref={ref}
				type={type}
				value={value}
				inputMode={inputMode}
				placeholder={placeholder}
				onChange={onChangeInternal}
				onFocus={onFocus}
				onBlur={onBlur}
				maxLength={maxLength}
				onKeyUp={onKeyUp}
				autoComplete={newPassword ? "new-password" : "on"}
				className={className} />

			{validityImage && <img src={validityImage} />}
		</div>
	);
};

export const TextEntry = React.forwardRef<HTMLInputElement, TextEntryProps>(TextEntryInternal);
