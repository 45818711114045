import padlock from "@fyendalscollection/app/assets/padlock.svg";
import styles from "./Padlocked.module.scss";
import { Link } from "react-router-dom";

export interface PadlockedProps<T> {
	on: T | undefined | null;
	element: (on: T) => React.ReactNode;
}

export const Padlocked = <T, >(props: PadlockedProps<T>): JSX.Element => (
	<>
		{props.on
			? props.element(props.on)
			:
			<Link to={"/settings/subscription"}>
				<img className={styles.image} src={padlock}/>
			</Link>
		}
	</>
);
