import { HistoricalPositionsGraph } from "@fyendalscollection/app/features/collections/components/HistoricalPositionsGraph";
import styles from "./PageDashboard.module.scss";
import { useNavigate } from "react-router-dom";
import { useUserPreferencesState } from "@fyendalscollection/shared";
import dayjs from "dayjs";
import { CollectionsSortOrder, useCollections, useDashboardCurrentPosition, useDashboardHistoricalPositions } from "@fyendalscollection/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { PieGraph } from "@fyendalscollection/app/lib/components/PieGraph";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ContainerListItem } from "@fyendalscollection/app/lib/components/ContainerListItem";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { Padlocked } from "@fyendalscollection/app/lib/components/Padlocked";
import { CallToAction } from "@fyendalscollection/app/lib/components/CallToAction";

export const PageDashboard = (): JSX.Element => {
	useTitle("Dashboard");

	const currentPosition = useDashboardCurrentPosition();
	const collections = useCollections(CollectionsSortOrder.Alphabetical);
	const navigate = useNavigate();

	const gain = currentPosition.data ? currentPosition.data.gain : null;
	const invested = currentPosition.data ? currentPosition.data.invested : null;
	const marketValue = currentPosition.data ? currentPosition.data.marketValue : null;
	const collectionData = collections.data ? collections.data.pages[0] : null;

	const userHasACollection = collectionData ? collectionData.results.length > 0 : false;
	const { state } = useUserPreferencesState();
	const dateStr = dayjs().format("YYYY-MM-DD");
	const dashboardHistoricalPositions = useDashboardHistoricalPositions(state.graphDays, dateStr);

	return (
		<>
			<Limit force={LimitWidth.Medium}>
				<Loadable loading={collections.isLoading || currentPosition.isLoading}>
					<div className={styles.dashboardTitleDisplay}>
						{gain === null || invested === null || marketValue?.value === 0 ?
							<></>
							:
							<div className={styles.pieChart}>
								<PieGraph
									data={[{
										id: "Gain",
										label: "Gain",
										value: gain.value,
										color: gain.value < 0 ? "#AA583E" : "#5AAA3E"
									},
									{
										id: "Invested",
										label: "Invested",
										value: invested.value,
										color: "#B9B9B9"
									}]}
								/>
							</div>
						}

						{userHasACollection &&
							<div>
								<div className={styles.title}>
									Portfolio Market Value
								</div>
								<div className={styles.moneyTitleContainer}>
									{currentPosition.data &&
									<PrettyMoney className={styles.moneyTitle} money={currentPosition.data.marketValue} mode={MoneyDisplayMode.Standard} />
									}
								</div>
								<div className={styles.moneySubtitleContainer}>
									{currentPosition.data && currentPosition.data.gain && currentPosition.data.invested &&
									<PrettyMoney className={styles.moneySubtitle} originalValue={currentPosition.data.invested.value} money={currentPosition.data.gain} mode={MoneyDisplayMode.DeltaPercentage} />
									}
								</div>
							</div>
						}
					</div>

					{currentPosition.data && userHasACollection &&
						<Container title="Portfolio Performance">
							<ContainerSegment>
								<ContainerListItem title="Market Value">
									<PrettyMoney className={styles.value + " " + styles.static} money={currentPosition.data.marketValue} mode={MoneyDisplayMode.Standard} />
								</ContainerListItem>
							</ContainerSegment>
							<ContainerSegment>
								<ContainerListItem title="Invested">
									<Padlocked on={currentPosition.data.invested} element={invested => <PrettyMoney className={styles.value + " " + styles.static} money={invested} mode={MoneyDisplayMode.Standard}/>} />
								</ContainerListItem>
							</ContainerSegment>
							<ContainerSegment>
								<ContainerListItem title="Gain">
									<Padlocked on={currentPosition.data.gain} element={gain => <PrettyMoney className={styles.value} originalValue={currentPosition.data.invested?.value || 0} money={gain} mode={MoneyDisplayMode.DeltaPercentage}/>} />
								</ContainerListItem>
							</ContainerSegment>
							<ContainerSegment>
								<ContainerListItem title="Profit">
									<Padlocked on={currentPosition.data.profit} element={profit => <PrettyMoney className={styles.value} money={profit} mode={MoneyDisplayMode.Delta}/>} />
								</ContainerListItem>
							</ContainerSegment>
							<ContainerSegment>
								<ContainerListItem title="Cash Position">
									<Padlocked on={currentPosition.data.cashPosition} element={cashPosition => <PrettyMoney className={styles.value} money={cashPosition} mode={MoneyDisplayMode.Delta}/>} />
								</ContainerListItem>
							</ContainerSegment>
						</Container>
					}

					{userHasACollection &&
						<HistoricalPositionsGraph
							title="Portfolio History"
							historicalPositions={dashboardHistoricalPositions.data}
							loading={dashboardHistoricalPositions.isFetching}
							showHelp={true}
						/>
					}

					{!userHasACollection && !collections.isLoading &&
						<CallToAction
							title="Create your first collection!"
							subtitle="Collections are used for tracking the cards and products you own"
							buttonText="Create Collection"
							onClick={() => navigate("../collections?create=1")} />
					}
				</Loadable>
			</Limit>
		</>
	);
};
