import { MutationFunction, useQueryClient } from "react-query";
import { TransactionModel } from "./models/TransactionModel";
import { clearCacheAfterTransactionMutation } from "./clearCacheAfterTransactionMutation";
import { HTTPAuthMode, Response, removeFromQueryKeyIfExists, useHTTP, useSimpleMutation } from "../../http";
import { TransactionDetailsSortOrder } from "../../domain";

interface UseTransactionDeleteDetails {
	transactionId: string;
	collectionId: string;
	variantId: string;
}

export const useTransactionDelete = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, UseTransactionDeleteDetails> = (transactionDetails: UseTransactionDeleteDetails) => {
		return http({
			method: "DELETE",
			url: "/transaction/" + transactionDetails.transactionId
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (_, transactionDetails) => {
			removeFromQueryKeyIfExists<TransactionModel>(queryClient, ["transactions", transactionDetails.collectionId, TransactionDetailsSortOrder.Chronological], x => x.transactionId === transactionDetails.transactionId);
			removeFromQueryKeyIfExists<TransactionModel>(queryClient, ["transactions", transactionDetails.collectionId, TransactionDetailsSortOrder.Alphabetical], x => x.transactionId === transactionDetails.transactionId);
			removeFromQueryKeyIfExists<TransactionModel>(queryClient, ["transactions", transactionDetails.collectionId, transactionDetails.variantId], x => x.transactionId === transactionDetails.transactionId);
			clearCacheAfterTransactionMutation(queryClient, transactionDetails.collectionId, transactionDetails.variantId);
		}
	});
};
