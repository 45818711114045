import { MutationFunction, useQueryClient } from "react-query";
import { PriceOverrideDetailsModel } from "./models/PriceOverrideDetailsModel";
import { clearCacheAfterPriceOverrideMutation } from "./clearCacheAfterPriceOverrideMutation";
import { HTTPAuthMode, Response, paginatedRemove, useHTTP, useSimpleMutation } from "../../http";

export const usePriceOverrideDelete = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, string> = (priceOverrideId) => {
		return http({
			method: "DELETE",
			url: `/price-override/${priceOverrideId}`
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (_, priceOverrideId) => {
			queryClient.setQueryData(["priceOverrideDetails"], paginatedRemove<PriceOverrideDetailsModel>(x => x.priceOverrideId === priceOverrideId));
			clearCacheAfterPriceOverrideMutation(queryClient);
		}
	});
};
