import { MutationFunction, useQueryClient } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export const useAbortImport = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, void> = async () => {
		return http({
			method: "POST",
			url: "/import/abort",
			body: undefined
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: () => {
			queryClient.invalidateQueries(["importStatus"]);
		}
	});
};
