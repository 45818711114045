import warning from "@fyendalscollection/app/assets/warning.svg";
import styles from "./WarningContainer.module.scss";

export interface WarningContainerProps {
	title: string;
	children: React.ReactNode;
}

export const WarningContainer = ({ title, children }: WarningContainerProps): JSX.Element => {
	return (
		<div className={styles.warningContainer}>
			<div>
				<img src={warning} />
				<div>
					<div>{title}</div>
					<div>{children}</div>
				</div>
			</div>
		</div>
	);
};
