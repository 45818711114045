import React from "react";
import styles from "./AutoStack.module.scss";

export interface AutoStackProps {
	children: React.ReactNode;
}

export const AutoStack = ({ children }: AutoStackProps): JSX.Element => {
	return (
		<div className={styles.autoStack}>
			{children}
		</div>
	);
};
