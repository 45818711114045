import dayjs from "dayjs";
import { useQueryClient } from "react-query";
import { TokenClaims } from "./TokenClaims";
import { StorageService, useStorage } from "../storage";
import { createGlobalState } from "./createGlobalState";

export interface AuthState {
	accessToken?: string;
	emailAddress?: string;
	displayName?: string;
	mfa?: boolean;
	postLoginRedirectURL?: string;
	attribution?: string;
}

type AuthAction = {
	type: AuthActionType.LoggedIn;
	accessToken: string;
	emailAddress: string;
	displayName: string;
	mfa: boolean;
} | {
	type: AuthActionType.LoggedOut;
} | {
	type: AuthActionType.SetPostLoginRedirectURL;
	postLoginRedirectURL: string | undefined;
} | {
	type: AuthActionType.SetAttribution;
	attribution: string;
};

enum AuthActionType {
	LoggedIn = "LOGGED_IN",
	LoggedOut = "LOGGED_OUT",
	SetPostLoginRedirectURL = "SET_POST_LOGIN_REDIRECT_URL",
	SetAttribution = "SET_ATTRIBUTION"
}

const getAccessTokenClaims = (accessToken: string): [string, string, boolean, number] => {
	const claims = new TokenClaims(accessToken);
	return [
		claims["email"] as string,
		claims["dspn"] as string,
		claims["mfa"] as string === "True",
		claims["exp"] as number
	];
};

const getInitialState = (storage: StorageService): AuthState => {
	const accessToken = storage.getPersistent<string>("atjwt");
	const attribution = storage.getTemporary<string>("attribution");
	const state: AuthState = { attribution };

	if (!accessToken) {
		return state;
	}

	const [
		emailAddress,
		displayName,
		mfa,
		expiry
	] = getAccessTokenClaims(accessToken);

	if (expiry) {
		const now = dayjs();
		const expiryDate = dayjs.unix(expiry);

		if (now.isAfter(expiryDate)) {
			storage.removePersistent("atjwt");
			return state;
		}
	}

	return {
		...state,
		accessToken,
		emailAddress,
		displayName,
		mfa
	};
};

const reducer = (state: AuthState, action: AuthAction): AuthState => {
	switch (action.type) {
	case AuthActionType.LoggedIn:
		return {
			...state,
			accessToken: action.accessToken,
			emailAddress: action.emailAddress,
			displayName: action.displayName,
			mfa: action.mfa
		};
	case AuthActionType.LoggedOut:
		return {
			...state,
			accessToken: undefined
		};
	case AuthActionType.SetAttribution:
		return {
			...state,
			attribution: action.attribution
		};
	case AuthActionType.SetPostLoginRedirectURL:
		if (state.postLoginRedirectURL && action.postLoginRedirectURL) {
			return {
				...state
			};
		}

		return {
			...state,
			postLoginRedirectURL: action.postLoginRedirectURL
		};
	}
};

const [AuthStateProvider, useAuthStateReducer] = createGlobalState(reducer, getInitialState);
export { AuthStateProvider };

export const useAuthState = () => {
	const storage = useStorage();
	const [state, dispatch] = useAuthStateReducer();
	const queryClient = useQueryClient();

	const dispatchAuthLoggedIn = (accessToken: string) => {
		const [
			emailAddress,
			displayName,
			mfa
		] = getAccessTokenClaims(accessToken);

		dispatch({
			type: AuthActionType.LoggedIn,
			accessToken,
			emailAddress,
			displayName,
			mfa
		});

		storage.setPersistent("atjwt", accessToken);
	};

	const dispatchAuthLoggedOut = () => {
		dispatch({
			type: AuthActionType.LoggedOut
		});

		queryClient.resetQueries();
		storage.removePersistent("atjwt");
	};

	const dispatchAuthSetAttribution = (attribution: string) => {
		dispatch({
			type: AuthActionType.SetAttribution,
			attribution
		});

		storage.setTemporary("attribution", attribution);
	};

	const dispatchAuthSetPostLoginRedirectURL = (postLoginRedirectURL: string | undefined) => {
		dispatch({
			type: AuthActionType.SetPostLoginRedirectURL,
			postLoginRedirectURL
		});
	};

	return {
		state,
		dispatchAuthLoggedIn,
		dispatchAuthLoggedOut,
		dispatchAuthSetAttribution,
		dispatchAuthSetPostLoginRedirectURL
	};
};
