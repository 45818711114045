import { useEffect } from "react";
import styles from "./FrameDashboard.module.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthState } from "@fyendalscollection/shared";
import { Footer } from "./Footer";
import { usePushAppLoadEvent } from "@fyendalscollection/shared";
import { Navigation } from "@fyendalscollection/app/lib/components/Navigation";
import { SearchPanel } from "@fyendalscollection/app/lib/components/SearchPanel";
import { useSearchPanelUsage } from "@fyendalscollection/app/lib/useSearchPanel";

export const FrameDashboard = (): JSX.Element => {
	const { state, dispatchAuthSetPostLoginRedirectURL } = useAuthState();
	const navigate = useNavigate();
	const appLoadEvent = usePushAppLoadEvent();
	const [usage, setCurrentUsage] = useSearchPanelUsage();

	useEffect(() => {
		appLoadEvent.mutate();
	}, []);

	useEffect(() => {
		if (!state.accessToken) {
			const { pathname, search, hash } = window.location;
			dispatchAuthSetPostLoginRedirectURL(pathname + search + hash);
			navigate("/login");
		}
	}, [state.accessToken]);

	return (
		<div className={styles.frameDashboard}>
			<div>
				<Navigation />
				<Outlet />
			</div>
			<div>
				<Footer />
			</div>

			<SearchPanel usage={usage} onCancel={() => setCurrentUsage(null)} />
		</div>
	);
};
