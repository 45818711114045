import { TransactionSummariesSortOrder } from "../../domain";
import { HTTPAuthMode, InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";
import { TransactionSummaryModel } from "./models/TransactionSummaryModel";

export const useTransactionSummaries = (collectionId: string, sortOrder: TransactionSummariesSortOrder, productNameFilter: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQueryWithCursor(["transactionSummaries", collectionId, sortOrder, productNameFilter], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		return http<PaginatedResponseModel<TransactionSummaryModel>>({
			method: "GET",
			url: `/collection/${collectionId}/transaction-summary?cursor=${pageParam}&sort=${sortOrder}` + (productNameFilter ? `&productNameFilter=${productNameFilter}` : "")
		});
	});
};
