import { ResponsivePie } from "@nivo/pie";
import { Theme, useTheme } from "@fyendalscollection/app/lib/ThemeContext";
import { GraphTooltip } from "@fyendalscollection/app/lib/components/GraphTooltip";

interface DataGroup {
	id: string;
	label: string;
	value: number;
	color: string;
}
interface PieGraphProps {
	data: DataGroup[];
}

export const PieGraph = ({ data }: PieGraphProps) => {
	const theme = useTheme();
	const formattedData = data.map(x => ({ ...x, value: x.value < 0 ? Math.abs(x.value) : x.value }));

	return (
		<ResponsivePie
			data={formattedData}
			margin={{ bottom: 10, top: 50, left: 10, right: 10 }}
			colors={({ data }) => data.color}
			borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
			defs={[
				{
					id: "lines",
					type: "patternLines",
					background: "inherit",
					color: "rgba(255, 255, 255, 0.3)",
					rotation: -45,
					lineWidth: 6,
					spacing: 10
				}]}
			borderWidth={2}
			padAngle={6}
			innerRadius={0.2}
			enableArcLabels={false}
			enableArcLinkLabels={false}
			isInteractive={true}
			activeOuterRadiusOffset={5}
			animate={true}
			fill={[
				{
					match: {
						id: "Invested"
					},
					id: "lines"
				}]}
			legends={[
				{
					anchor: "top",
					direction: "row",
					justify: false,
					translateX: 30,
					translateY: -36,
					itemsSpacing: -10,
					itemWidth: 100,
					itemHeight: 18,
					itemTextColor: theme === Theme.Light ? "#444" : "#BBB",
					itemDirection: "left-to-right",
					itemOpacity: 1,
					symbolSize: 18,
					symbolShape: "circle"
				}
			]}
			tooltip={(value) => <GraphTooltip color={value.datum.color} labels={[{ label: value.datum.data.label, value: data.find(x => x.id === value.datum.id)?.value ?? 0}]} />}
		/>
	);
};
