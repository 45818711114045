import { useState } from "react";
import styles from "./Navigation.module.scss";
import icon from "@fyendalscollection/app/assets/icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "@fyendalscollection/shared";
import menuButtonLight from "@fyendalscollection/app/assets/menu-light.svg";
import menuButtonDark from "@fyendalscollection/app/assets/menu-dark.svg";
import { ProductSearchBar } from "@fyendalscollection/app/features/products/components/ProductSearchBar";
import { CurrencySelector } from "./CurrencySelector";
import { PriceSourceSelector } from "@fyendalscollection/app/lib/components/PriceSourceSelector";
import { NotificationButton } from "@fyendalscollection/app/features/notifications/components/NotificationButton";
import { ModalNotifications } from "@fyendalscollection/app/features/notifications/components/ModalNotifications";
import { ThemedImage } from "@fyendalscollection/app/lib/components/ThemedImage";
import { Emblem } from "@fyendalscollection/app/features/emblems/components/Emblem";
import { Currency, PriceSource, useEmblem, useGetUserEmblemRolls, usePreferredCurrency, usePreferredPriceSource, useSetPreferredCurrency, useSetPreferredPriceSource, useUnreadCount } from "@fyendalscollection/shared";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { SubscriptionBanner } from "@fyendalscollection/app/features/subscriptions/components/SubscriptionBanner";

export const Navigation = (): JSX.Element => {
	const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
	const { displayName } = useAuthState().state;
	const navigate = useNavigate();
	const location = useLocation();
	const emblem = useEmblem();

	const [notificationModalVisible, setNotificationModalVisible] = useState(false);

	const setPreferredCurrency = useSetPreferredCurrency();
	const preferredCurrency = usePreferredCurrency();

	const setPreferredPriceSource = useSetPreferredPriceSource();
	const preferredPriceSource = usePreferredPriceSource();

	const rolls = useGetUserEmblemRolls();
	const unreadCount = useUnreadCount();

	const onSelectCurrencyChange = (currency: Currency) => {
		if (!preferredCurrency.data) {
			return;
		}
		if (currency !== preferredCurrency.data.currency) {
			setPreferredCurrency.mutate({ currency });
		}
	};

	const onSelectPriceSourceChange = (priceSource: PriceSource) => {
		if (!preferredPriceSource.data) {
			return;
		}
		if (priceSource !== preferredPriceSource.data.priceSource) {
			setPreferredPriceSource.mutate({ priceSource });
		}
	};

	const hideMobileMenu = () => setMobileMenuVisible(false);

	const emblemsClassNames = [ styles.links ];
	if (rolls.data && rolls.data.emblemRolls > 0 && location.pathname !== "/emblems") {
		emblemsClassNames.push(styles.highlighted);
	}

	const links = (
		<>
			<Link className={styles.links} to="/collections" onClick={hideMobileMenu}>Collections</Link>
			<Link className={styles.links} to="/market-trends" onClick={hideMobileMenu}>Market Trends</Link>
			<Link className={styles.links} to="/ev-tracker" onClick={hideMobileMenu}>EV Tracker</Link>
			<Link className={emblemsClassNames.join(" ")} to="/emblems" onClick={hideMobileMenu}>Emblems</Link>
			<Link className={styles.links} to="/official-decks" onClick={hideMobileMenu}>Decks</Link>
			<Link className={styles.links} to="/browse" onClick={hideMobileMenu}>Browse</Link>
		</>
	);

	const productSearchBar = <ProductSearchBar floating={true} onProductSelected={x => navigate(`/browse/product/${x}`)} onEnter={searchQueryText => navigate("/browse?q=" + searchQueryText)}/>;

	return (
		<>
			<div className={styles.navigation}>
				<div className={styles.mainBar}>
					<div>
						<ThemedImage className={styles.menuButton} srcLight={menuButtonLight} srcDark={menuButtonDark} onClick={() => setMobileMenuVisible(!mobileMenuVisible)} />
						<div className={styles.mobileGap}></div>
						<Link to="/" className={styles.title}>
							<img src={icon} />
							<div>Fyendal&apos;s Collection</div>
						</Link>
						<div className={styles.gap}></div>
						<div className={styles.mobileButtons}>
							<NotificationButton unreadCount={unreadCount.data ? unreadCount.data.unreadCount : 0} onClick={() => setNotificationModalVisible(true)} />
						</div>
						<div className={styles.settingsContainer}>
							<Loadable loading={setPreferredCurrency.isLoading || preferredCurrency.isLoading || setPreferredPriceSource.isLoading || preferredPriceSource.isLoading || unreadCount.isLoading}>
								<div className={styles.selects}>
									<PriceSourceSelector value={preferredPriceSource.data ? preferredPriceSource.data.priceSource : null} onChange={onSelectPriceSourceChange} />
									<CurrencySelector value={preferredCurrency.data ? preferredCurrency.data.currency : null} onChange={onSelectCurrencyChange} />
									<NotificationButton unreadCount={unreadCount.data ? unreadCount.data.unreadCount : 0} onClick={() => setNotificationModalVisible(true)} />
								</div>
							</Loadable>
							<Link to="/settings" className={styles.settingsText}>
								<div>{displayName}</div>
								{
									emblem.data &&
									<div className={styles.emblem}>
										<Emblem emblemId={emblem.data.emblemId} count={emblem.data.count} rarity={emblem.data.rarity} />
									</div>
								}
							</Link>
						</div>
					</div>
				</div>
				<div className={styles.linkBar}>
					<div>
						{links}
						<div className={styles.gap}></div>
						{productSearchBar}
					</div>
				</div>
				<div className={mobileMenuVisible ? styles.mobileSearch + " " + styles.visible : styles.mobileSearch}>
					{productSearchBar}
				</div>
				{
					emblem.data &&
					<div className={mobileMenuVisible ? styles.mobileSearch + " " + styles.visible : styles.mobileSearch} onClick={hideMobileMenu}>
						<Link to="/settings" className={styles.settingsText}>
							<div className={styles.emblem}>
								<Emblem emblemId={emblem.data.emblemId} count={emblem.data.count} rarity={emblem.data.rarity} />
							</div>
							<div>
								{displayName}
							</div>
						</Link>
					</div>
				}
				<div className={mobileMenuVisible ? styles.mobileMenu + " " + styles.visible : styles.mobileMenu}>
					{links}
				</div>
			</div>

			<SubscriptionBanner />
			<ModalNotifications visible={notificationModalVisible} onCancel={() => setNotificationModalVisible(false)}/>
		</>
	);
};
