import { useEffect } from "react";
import { useOperationTcgPlayerMassEntryLink } from "@fyendalscollection/shared";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";

export interface ModalTcgPlayerMassEntryOperationProps {
	visible: boolean;
	onCancel: () => void;
	collectionId: string;
}

export const ModalTcgPlayerMassEntryOperation = (props: ModalTcgPlayerMassEntryOperationProps): JSX.Element => {
	const tcgPlayerMassEntryLink = useOperationTcgPlayerMassEntryLink();

	useEffect(() => {
		if (props.visible) {
			tcgPlayerMassEntryLink.mutate(props.collectionId);
		}
	}, [props.visible]);

	return (
		<Modal visible={props.visible} onCancel={props.onCancel}>
			<TitleSecondary text="Buy with TCGplayer" />

			<Container transparent={true}>
				<Paragraph>
					Click the button below to add all products in this collection to your cart with TCGplayer.  Up to <b>125</b> products can be added this way.
				</Paragraph>
			</Container>

			{
				tcgPlayerMassEntryLink.isError && (
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={tcgPlayerMassEntryLink.error}/>
						</ContainerSegment>
					</Container>
				)
			}

			<GreenButtonSet buttons={[
				{
					text: "Add",
					newTabLink: true,
					disabled: !tcgPlayerMassEntryLink.data,
					link: tcgPlayerMassEntryLink.data?.body.tcgPlayerMassEntryLink
				},
				{
					text: "Cancel",
					onClick: props.onCancel
				}
			]} />
		</Modal>
	);
};
