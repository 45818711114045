import { CollectionModel } from "./models/CollectionModel";
import { useSimpleQuery } from "../../http/useSimpleQuery";
import { HTTPAuthMode, useHTTP } from "../../http";

export const useCollection = (collectionId: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["collection", collectionId], () => {
		return http<CollectionModel>({
			method: "GET",
			url: `/collection/${collectionId}`
		});
	});
};

