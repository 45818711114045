import { useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { ModalCurrency } from "@fyendalscollection/app/lib/components/ModalCurrency";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import currencyExchangeIcon from "@fyendalscollection/app/assets/currency-exchange.svg";
import rubbishBinIconLight from "@fyendalscollection/app/assets/rubbish-bin-light.svg";
import rubbishBinIconDark from "@fyendalscollection/app/assets/rubbish-bin-dark.svg";
import errorBubbleIcon from "@fyendalscollection/app/assets/error-bubble.svg";
import styles from "./TransactionItem.module.scss";
import { ThemedImage } from "@fyendalscollection/app/lib/components/ThemedImage";
import { useTransaction } from "@fyendalscollection/app/features/transactions/hooks/useTransaction";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";
import { Select } from "@fyendalscollection/app/lib/components/Select";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { ModalCalendar } from "@fyendalscollection/app/lib/components/ModalCalendar";
import { TransactionAction, useTransactionDelete } from "@fyendalscollection/shared";
import { TransactionDetailsModel } from "@fyendalscollection/shared";
import { NumericEntry } from "@fyendalscollection/app/lib/components/NumericEntry";

export interface TransactionItemProps {
	transaction: TransactionDetailsModel;
	collectionId: string;
	destination: TransactionItemDestination;
}

export enum TransactionItemDestination {
	VariantTransactions,
	ProductPage
}

export const TransactionItem = ({ transaction, collectionId, destination }: TransactionItemProps): JSX.Element => {
	const [quantity, setQuantity] = useState(transaction.quantity);
	const [action, setAction] = useState<TransactionAction>(transaction.action);
	const [unitAmount, setUnitAmount] = useState(transaction.unitAmount.value);
	const [currency, setCurrency] = useState(transaction.unitAmount.currency);
	const [date, setDate] = useState<Date>(new Date(transaction.occurred));

	const transactionUpdate = useTransaction(quantity, action, unitAmount, currency, date, collectionId, transaction);
	const transactionDelete = useTransactionDelete();

	const handleRemove = () => {
		transactionDelete.mutate({
			transactionId: transaction.transactionId,
			collectionId: collectionId,
			variantId: transaction.variantId});
	};

	const [currencyModalVisible, setCurrencyModalVisible] = useState(false);
	const [dateModalVisible, setDateModalVisible] = useState(false);

	const url = destination === TransactionItemDestination.VariantTransactions ? `/collections/${collectionId}/transactions/${transaction.variantId}` : `/browse/product/${transaction.productId}`;

	return (
		<TabularDataItem>
			<>
				<Loadable loading={transactionUpdate.isLoading || transactionDelete.isLoading}>
					<Link className={styles.productDisplay} to={url}>
						<ProductImage className={styles.productImage} image={transaction.imageUrl} />
						<div>
							<div>{transaction.productName}</div>
							<div>{transaction.fcId}</div>
						</div>
					</Link>
				</Loadable>
			</>
			<>
				<NumericEntry
					value={quantity}
					onChange={setQuantity}
					maxDecimalPlaces={0}
					minValue={1}
					maxValue={100000} />
			</>
			<>
				<Select
					value={action}
					onChange={setAction}
					className={styles.transactionInfo}
					options={[
						{ value: TransactionAction.Purchase, text: "Purchase" },
						{ value: TransactionAction.Sale, text: "Sale" }
					]} />
			</>
			<>
				<NumericEntry
					value={unitAmount}
					onChange={setUnitAmount}
					maxDecimalPlaces={2}
					minValue={0}
					maxValue={500000} />
			</>
			<>
				<div className={styles.totalAmount}>
					<img src={currencyExchangeIcon} onClick={() => setCurrencyModalVisible(true)} />
					<PrettyMoney
						className={styles.transactionInfo}
						money={{
							value: Number(quantity) * Number(unitAmount),
							currency: currency
						}}
						mode={MoneyDisplayMode.Standard} />
					<ModalCurrency
						visible={currencyModalVisible}
						onCancel={() => setCurrencyModalVisible(false)}
						value={currency}
						onChange={setCurrency} />
				</div>
			</>
			<>
				<LinkButton
					text={dayjs(date).format("DD MMM YYYY")}
					onClick={() => setDateModalVisible(true)} />
				<ModalCalendar
					visible={dateModalVisible}
					onCancel={() => setDateModalVisible(false)}
					defaultDate={date}
					onDateSelected={setDate} />
			</>
			<>
				{
					transactionUpdate.isError || transactionDelete.isError ? (
						<img className={styles.errorIcon} src={errorBubbleIcon} />
					) : (
						<ThemedImage className={styles.removeIcon} srcLight={rubbishBinIconLight} srcDark={rubbishBinIconDark} onClick={handleRemove} />
					)
				}
			</>
		</TabularDataItem>
	);
};
