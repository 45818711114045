import React, { useEffect, useMemo, useState } from "react";
import styles from "./PageEmblems.module.scss";
import { Emblem } from "@fyendalscollection/app/features/emblems/components/Emblem";
import { ModalEmblemsRoll } from "@fyendalscollection/app/features/emblems/components/ModalEmblemsRoll";
import { ParticleContainer } from "@fyendalscollection/app/lib/components/ParticleContainer";
import dayjs from "dayjs";
import { EmblemStats } from "@fyendalscollection/app/features/emblems/components/EmblemStats";
import { getGoldenThresholdForEmblemRarity } from "@fyendalscollection/app/features/emblems/getGoldenThresholdForEmblemRarity";
import { ModalEmblemsExplain } from "@fyendalscollection/app/features/emblems/components/ModalEmblemsExplain";
import { getColorForEmblemRarity } from "@fyendalscollection/app/features/emblems/getColorForEmblemRarity";
import { EmblemRarity, EmblemResponseModel, useEmblems, useGetUserEmblemRolls, useSetUserEmblem, useStorage } from "@fyendalscollection/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";

export const PageEmblems = (): JSX.Element => {
	useTitle("Emblems");

	const emblems = useEmblems();
	const setUserEmblem = useSetUserEmblem();
	const rolls = useGetUserEmblemRolls();
	const [currentTime, setCurrentTime] = useState(new Date());
	const [rollModalVisible, setRollModalVisible] = useState(false);
	const [explainModalVisible, setExplainModalVisible] = useState(false);
	const storage = useStorage();

	const groupedEmblems = useMemo(() => {
		if (!emblems.data) {
			return { };
		}

		return emblems.data.pages
			.flatMap(x => x.results)
			.reduce((groupedEmblems, emblem) => {
				if (!groupedEmblems[emblem.rarity]) {
					groupedEmblems[emblem.rarity] = [];
				}

				groupedEmblems[emblem.rarity].push(emblem);
				return groupedEmblems;
			}, { } as Record<string, EmblemResponseModel[]>);
	}, [emblems.data]);

	useEffect(() => {
		const interval = setInterval(() => setCurrentTime(new Date()), 1000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const timeout = setTimeout(() => {
			const hasDisplayed = storage.getPersistent<boolean>("hasDisplayedEmblemExplainModal");
			if (hasDisplayed || rollModalVisible) {
				return;
			}

			setExplainModalVisible(true);
		}, 1500);

		return () => clearTimeout(timeout);
	}, [rollModalVisible]);

	return (
		<Limit force={LimitWidth.Massive}>
			<Breadcrumb crumbs={[
				{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "Emblems"
				}
			]} />

			<PageTitle
				title="Emblems"
				subTitle="Collect emblems to personalize your experience!"
				primaryActions={<EmblemStats />} />

			<Loadable loading={emblems.isLoading || setUserEmblem.isLoading || rolls.isLoading}>
				{
					emblems.isError &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={emblems.error} />
						</ContainerSegment>
					</Container>
				}

				{
					setUserEmblem.isError &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={setUserEmblem.error} />
						</ContainerSegment>
					</Container>
				}

				{
					rolls.isError &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={rolls.error} />
						</ContainerSegment>
					</Container>
				}

				<div className={styles.split}>
					<div>
						{
							groupedEmblems && ["Fabled", "Legendary", "Majestic", "Rare", "Common"].map(rarity => (
								<React.Fragment key={rarity}>
									<div className={styles.emblemGridTitle}>
										<div style={{ backgroundColor: getColorForEmblemRarity(rarity as EmblemRarity) }}></div>
										<div>{rarity}</div>
									</div>
									<div key={rarity} className={styles.emblemGrid}>
										{
											groupedEmblems[rarity]?.map(x => (
												<div key={x.emblemId} className={styles.emblemContainer + (x.count > 0 ? ` ${styles.owned}` : "")}>
													<Emblem {...x} onClick={x.count > 0 ? () => setUserEmblem.mutate({ emblemId: x.emblemId }) : undefined} />

													<div>
														<div>{x.name}</div>
														<div>
															<div>{x.rarity}</div>
															<div>{x.count}/{getGoldenThresholdForEmblemRarity(x.rarity)}</div>
														</div>
													</div>
												</div>
											))
										}
									</div>
								</React.Fragment>
							))
						}
					</div>

					<div>
						{
							rolls.data &&
							<ParticleContainer active={rolls.data.emblemRolls > 0}>
								<div className={styles.emblemRollsText}>
									<div>Rolls Remaining</div>
									<div className={rolls.data.emblemRolls > 0 ? styles.active : undefined}>{rolls.data.emblemRolls}</div>
								</div>

								<GreenButtonSet buttons={[
									{
										text: "Roll",
										disabled: rolls.data.emblemRolls < 1 || rollModalVisible,
										onClick: () => setRollModalVisible(true)
									}
								]} />

								<div className={styles.resetText}>Rolls reset in {getDurationText(currentTime, getNextUTCMidnight())}</div>
							</ParticleContainer>
						}
					</div>
				</div>

				<ModalEmblemsRoll visible={rollModalVisible} onCancel={() => setRollModalVisible(false)}/>
				<ModalEmblemsExplain visible={explainModalVisible} onCancel={() => setExplainModalVisible(false)} />
			</Loadable>
		</Limit>
	);
};

const getNextUTCMidnight = (): Date => {
	return dayjs(dayjs().utc()).add(1, "day").startOf("day").toDate();
};

const getDurationText = (now: Date, to: Date): string => {
	const duration = dayjs(to).diff(now, "second");
	const hours = Math.floor(duration / 3600);
	const minutes = Math.floor((duration % 3600) / 60);
	const seconds = duration % 60;

	return `${hours}h ${minutes}m ${seconds}s`;
};
