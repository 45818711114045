import { Money } from "../../domain";
import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface CollectionProgressResponseModel {
	readonly totalGoalQuantity: number;
	readonly totalActualQuantity: number;
	readonly costToComplete: Money;
}

export const useCollectionProgress = (collectionId: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["collectionProgress", collectionId], () => {
		return http<CollectionProgressResponseModel>({
			method: "GET",
			url: `/collection/${collectionId}/progress`
		});
	});
};
