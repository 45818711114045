import { useEffect, useRef } from "react";

export type AnimatedCanvasProps<T> = {
	width: number;
	height: number;
	className?: string;
	drawFunc: (ctx: CanvasRenderingContext2D, dt: number, args: T | null) => T;
};

export const AnimatedCanvas = <T,>(props: AnimatedCanvasProps<T>): JSX.Element => {
	const {
		width,
		height,
		className,
		drawFunc
	} = props;

	const args = useRef<T | null>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const ctx = useRef<CanvasRenderingContext2D | null>(null);

	useEffect(() => {
		const canvas = canvasRef.current;
		if (!canvas) {
			return;
		}

		ctx.current = canvas.getContext("2d");

		const drawFuncEnriched = (t: DOMHighResTimeStamp) => {
			const dt = lastTime === 0 ? 0 : t - lastTime;
			lastTime = t;

			if (ctx.current) {
				args.current = drawFunc(ctx.current, dt, args.current);
			}

			handle = requestAnimationFrame(drawFuncEnriched);
		};

		let lastTime = 0;
		let handle = requestAnimationFrame(drawFuncEnriched);
		return () => cancelAnimationFrame(handle);
	}, [drawFunc]);

	return (
		<canvas ref={canvasRef} className={className} width={width} height={height} />
	);
};
