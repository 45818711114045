import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "./PageProduct.module.scss";
import { LineGraph } from "@fyendalscollection/app/lib/components/LineGraph";
import { useFindInCollections, useUserPreferencesState } from "@fyendalscollection/shared";
import { HistoricalPricing, useProduct, useProductHistoricalPricing, useProductRecentSales } from "@fyendalscollection/shared";
import { ProductAffiliateLink } from "@fyendalscollection/app/features/products/components/ProductAffiliateLink";
import { RecentSaleDisplay } from "@fyendalscollection/app/features/products/components/RecentSaleDisplay";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { DaySelector } from "@fyendalscollection/app/lib/components/DaySelector";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import { ENABLE_SALE_VOLUMES } from "@fyendalscollection/app/lib/config";
import { VolumeGraph } from "@fyendalscollection/app/lib/components/VolumeGraph";
import { collectionArts } from "@fyendalscollection/app/features/collections/CollectionArts";

export const PageProduct = (): JSX.Element => {
	const GRAPH_COLOURS = ["#5AAA3E", "#3E90AA", "#8E3EAA", "#D74111", "#E8CE17"];
	const { state } = useUserPreferencesState();
	const params = useParams();
	const productId = params.productId as string;
	const { data } = useProduct(productId);
	const { data: historicalPricing, isFetching } = useProductHistoricalPricing(productId || "", state.graphDays);
	const findInCollections = useFindInCollections(productId);
	const recentSales = useProductRecentSales(productId);
	const breadcrumbs = [{
		text: "Dashboard",
		link: "/dashboard"
	}];
	const [loadMore, setLoadMore] = useState(false);

	if (data && data.number) {
		breadcrumbs.push({
			text: data.number.substring(0, 3),
			link: encodeURI(`/browse?q=s=${data.number.substring(0, 3)}`)
		});
	}

	useTitle(data ? data.name : "Product");

	const buildInfoItem = (title: string, value: string) => {
		return (
			<div className={styles.infoItem}>
				<div className={styles.infoTitle}>{title}</div>
				{value ? value : "-"}
			</div>
		);
	};

	const headers = ENABLE_SALE_VOLUMES
		? ["Product", "Sale Volumes", "Price"]
		: ["Product", "Price"];

	return (
		<Limit force={LimitWidth.Large}>
			<Breadcrumb crumbs={[
				...breadcrumbs,
				{
					text: data ? data.name : "Product"
				}
			]} />

			{data &&
				<PageTitle
					title={data.name}
					subTitle={data.number ? data.number.toUpperCase() : "Sealed Product"}
					primaryActions={
						<ProductAffiliateLink tcgPlayerProductId={data.tcgPlayerProductId} />
					} />
			}

			{data &&
				<div className={styles.flex}>
					<div className={styles.image}>
						<ProductImage className={styles.productImage} image={data.imageUrl} />
					</div>
					<div className={styles.detailsSection}>
						<div className={styles.prices}>
							<TabularData headers={headers}>
								{data.variants.map((x) => (
									<TabularDataItem key={x.variantId}>
										<>
											<div className={styles.variantName}>
												<div>{x.variantName}</div>
												<div>{x.fcId}</div>
											</div>
										</>
										{ ENABLE_SALE_VOLUMES && <VolumeGraph data={x.saleVolumes} /> }
										<>
											{
												x.marketPrice ? (
													<PrettyMoney className={styles.price} money={x.marketPrice} mode={MoneyDisplayMode.Standard} />
												) : (
													<>No pricing information</>
												)
											}
										</>
									</TabularDataItem>
								))}
							</TabularData>
						</div>

						{
							findInCollections.data && findInCollections.data.length > 0 &&
							<div className={styles.prices}>
								<TabularData headers={["Collection", "Variant", "Quantity"]}>
									{
										findInCollections.data.map(x => (
											<TabularDataItem key={x.collectionId}>
												<>
													<Link to={`/collections/${x.collectionId}/transactions/${x.variantId}`}>
														<div className={styles.collectionIcon}>
															<div style={{ backgroundImage: `url('${collectionArts[x.collectionImage]}')` }}></div>
															<div>{x.collectionName}</div>
														</div>
													</Link>
												</>
												<>{x.variantName}</>
												<>{x.quantity}</>
											</TabularDataItem>
										))
									}
								</TabularData>
							</div>
						}

						{historicalPricing && historicalPricing.length > 0 &&
							<div className={styles.graph}>
								<Container title="Pricing History">
									<ContainerSegment>
										<Loadable loading={isFetching}>
											<div className={styles.graphLimiter}>
												<LineGraph
													data={data.variants.map((variant, i) => {
														return {
															id: variant.fcId,
															label: variant.fcId,
															data: historicalPricing?.find((x) => x.variantId === variant.variantId)?.prices.map(
																(y: HistoricalPricing) => {
																	return {
																		x: new Date(y.date),
																		y: parseFloat(y.price.value.toFixed(2))
																	};
																}).reverse() ?? [],
															color: GRAPH_COLOURS[i]
														};
													})}
												/>
											</div>
											<DaySelector />
										</Loadable>
									</ContainerSegment>
								</Container>
							</div>
						}

						<Loadable loading={recentSales.isLoading}>
							<Container title="Ebay Sales">
								{
									recentSales.data && recentSales.data.results &&
									recentSales.data.results.slice(0, !loadMore ? 5 : undefined).map(x => (
										<ContainerSegment key={x.recentSaleId}>
											<RecentSaleDisplay
												imageUrl={x.imageUrl}
												title={x.title}
												saleDate={x.saleDate}
												setInference={x.setInference}
												foilingInference={x.foilingInference}
												editionInference={x.editionInference}
												price={x.price}
												link={x.sourceUrl} />
										</ContainerSegment>
									))
								}
								{
									recentSales.data && recentSales.data.results && recentSales.data.results.length > 5 && !loadMore &&
									<ContainerSegment>
										<LinkButton onClick={() => setLoadMore(!loadMore)} text="Load More"/>
									</ContainerSegment>
								}
								{
									recentSales.data && recentSales.data.results === null &&
									<ContainerSegment>
										We don&apos;t currently track recent sales for this product as it is not frequently traded on Ebay.
										If you feel like we should be tracking recent sales for this product, we&apos;d appreciate your feedback
										on our <a href="https://discord.gg/RDYn8yDnFm" className={styles.link} target="_blank" rel="noopener noreferrer">official Discord</a>.
									</ContainerSegment>
								}
								{
									recentSales.data && recentSales.data.results && recentSales.data.results.length === 0 &&
									<ContainerSegment>
										We don&apos;t currently have any recent sales on record for this product - check back soon!
									</ContainerSegment>
								}
							</Container>
							<div></div>
						</Loadable>

						<Container title="Product Information">
							<div className={styles.infoItems}>
								{data.rarity && buildInfoItem("Rarity", data.rarity)}
								{data.talent && buildInfoItem("Talent", data.talent)}
								{data.cardType && buildInfoItem("Card Type", data.cardType)}
								{data.cardSubType && buildInfoItem("Card SubType", data.cardSubType)}
								{data.pitchValue && buildInfoItem("Pitch", data.pitchValue)}
								{data.cost && buildInfoItem("Cost", data.cost)}
								{data.power && buildInfoItem("Power", data.power)}
								{data.defenseValue && buildInfoItem("Defense", data.defenseValue)}
							</div>
							<div>
								<div className={styles.infoItem}>
									<div className={styles.infoTitle}>Description</div>
									<div className={styles.description} dangerouslySetInnerHTML={{ __html: data.description }} />
								</div>
							</div>
						</Container>
					</div>
				</div>
			}
		</Limit>
	);
};
