import styles from "./RecentSaleDisplay.module.scss";
import dayjs from "dayjs";
import { EbayEdition, EbayFoiling, EbaySet, Money } from "@fyendalscollection/shared";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";

export interface RecentSaleDisplay {
	imageUrl: string;
	title: string;
	saleDate: string;
	setInference: EbaySet | null;
	foilingInference: EbayFoiling | null;
	editionInference: EbayEdition | null;
	price: Money;
	link: string;
}

export const RecentSaleDisplay = (props: RecentSaleDisplay): JSX.Element => {

	const inferenceText = [
		props.setInference,
		convertFoilingInferenceToString(props.foilingInference),
		convertEditionInferenceToString(props.editionInference)
	].filter(x => !!x).join(" / ");

	return (
		<a href={props.link} rel="noreferrer" target="_blank" className={styles.recentSaleDisplayContainer}>
			<div className={styles.recentSaleDisplay}>
				{ props.imageUrl && <div className={styles.imageHolder}><img src={props.imageUrl}/></div> }
				<div className={styles.text}>
					<div className={styles.title}>{props.title}</div>
					<div>{dayjs(props.saleDate).format("DD MMM YYYY")}</div>
					<div className={styles.inferences}>
						{inferenceText}
					</div>
				</div>
			</div>
			<PrettyMoney className={styles.price} money={props.price} mode={MoneyDisplayMode.Standard}/>
		</a>
	);
};

const convertFoilingInferenceToString = (inference: EbayFoiling | null) => {
	switch (inference) {
	case EbayFoiling.ColdFoil:
		return "Cold Foil";
	case EbayFoiling.RainbowFoil:
		return "Rainbow Foil";
	case EbayFoiling.Normal:
		return "Normal";
	default:
		return null;
	}
};

const convertEditionInferenceToString = (inference: EbayEdition | null) => {
	switch (inference) {
	case EbayEdition.FirstEdition:
		return "First Edition";
	case EbayEdition.UnlimitedEdition:
		return "Unlimited Edition";
	default:
		return null;
	}
};
