import { PriceSource } from "../../domain";
import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface GetPreferredPriceSourceResponseModel {
	priceSource: PriceSource;
}

export const usePreferredPriceSource = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["preferredPriceSource"], () => {
		return http<GetPreferredPriceSourceResponseModel>({
			method: "GET",
			url: "/settings/preferred-price-source"
		});
	});
};
