import styles from "./NotificationButton.module.scss";
import bellLight from "@fyendalscollection/app/assets/bell-light.svg";
import bellDark from "@fyendalscollection/app/assets/bell-dark.svg";
import { ThemedImage } from "@fyendalscollection/app/lib/components/ThemedImage";

export interface NotificationButtonProps {
	unreadCount: number;
	onClick: () => void;
}

export const NotificationButton = (props: NotificationButtonProps): JSX.Element => {
	const classNames = [styles.unreadCount];
	if (!props.unreadCount) {
		classNames.push(styles.noNotifications);
	}

	const countDisplay = props.unreadCount > 99 ? 99 : props.unreadCount;

	return (
		<div className={styles.notificationButton} onClick={props.onClick}>
			<div className={classNames.join(" ")}>
				<div>{countDisplay}</div>
			</div>
			<ThemedImage srcLight={bellLight} srcDark={bellDark} />
		</div>
	);
};
