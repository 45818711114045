import { OfficialDecksSortOrder } from "../../domain";
import { HTTPAuthMode, InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";
import { OfficialDeckDetailsModel } from "./models/OfficialDeckDetailsModel";

export const useOfficialDecks = (sortOrder: OfficialDecksSortOrder, heroFilter: string | null, formatFilter: string | null) => {
	const http = useHTTP(HTTPAuthMode.Required);

	return useSimpleQueryWithCursor(["officialDecks", sortOrder, heroFilter, formatFilter], ({ pageParam = 0}: InfiniteQueryPageContext) => {
		let url = "/official-deck?";
		url += `${heroFilter ? `heroFilter=${heroFilter}&` : ""}`;
		url += `${formatFilter ? `formatFilter=${formatFilter}&` : ""}`;
		url += `cursor=${pageParam}&sort=${sortOrder}`;

		return http<PaginatedResponseModel<OfficialDeckDetailsModel>>({
			method: "GET",
			url: url
		});
	});
};
