import { Selector, SelectorProps } from "@fyendalscollection/app/lib/components/Selector";
import styles from "./StyledSelector.module.scss";

export const StyledSelector = <T,>(props: SelectorProps<T>): JSX.Element => {
	return (
		<div className={styles.styledSelector}>
			<Selector {...props} classNames={styles.select} />
		</div>
	);
};
