
import styles from "./CollectionTabSelector.module.scss";
import { Link, useParams } from "react-router-dom";

export enum CollectionTab {
	Collection,
	Trends,
	Edit,
	Progress,
	Tools
}

export interface CollectionTabSelectorProps {
	selected: CollectionTab;
}

export const CollectionTabSelector = ({ selected }: CollectionTabSelectorProps): JSX.Element => {
	const { collectionId } = useParams();

	const classNames = [styles.collectionTabSelector];

	if (!collectionId) {
		classNames.push(styles.disabled);
	}

	return (
		<div className={styles.collectionTabSelector}>
			<Link to={`/collections/${collectionId}`} className={selected == CollectionTab.Collection ? styles.selected : undefined}>
				<div>Collection</div>
			</Link>
			<Link to={`/collections/${collectionId}/trends`} className={selected == CollectionTab.Trends ? styles.selected : undefined}>
				<div>Trends</div>
			</Link>
			<Link to={`/collections/${collectionId}/transactions`} className={selected == CollectionTab.Edit ? styles.selected : undefined}>
				<div>Edit</div>
			</Link>
			<Link to={`/collections/${collectionId}/progress`} className={selected == CollectionTab.Progress ? styles.selected : undefined}>
				<div>Progress</div>
			</Link>
			<Link to={`/collections/${collectionId}/tools`} className={selected == CollectionTab.Tools ? styles.selected : undefined}>
				<div>Tools</div>
			</Link>
		</div>
	);
};
