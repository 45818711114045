import { HTTPAuthMode, InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";
import { EmblemResponseModel } from "./models";

export const useEmblems = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQueryWithCursor(["emblems"], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		return http<PaginatedResponseModel<EmblemResponseModel>>({
			method: "GET",
			url: `/emblem?cursor=${pageParam}`
		});
	});
};
