import { MutationFunction, useQueryClient } from "react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface OperationMergeRequestModel {
	mergeCollectionId: string;
}

export interface OperationMergeRequestProperties {
	requestModel: OperationMergeRequestModel;
	collectionId: string;
}

export const useOperationMerge = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, OperationMergeRequestProperties> = (requestProperties: OperationMergeRequestProperties) => {
		return http<undefined, OperationMergeRequestModel>({
			method: "POST",
			url: "/collection/" + requestProperties.collectionId + "/operation/merge",
			body: requestProperties.requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (_, requestProperties) => {
			queryClient.invalidateQueries(["dashboardCurrentPosition"]);
			queryClient.invalidateQueries(["dashboardHistoricalPositions"]);
			queryClient.invalidateQueries(["transactionSummaries"]);
			queryClient.invalidateQueries(["transactions", requestProperties.collectionId]);
			queryClient.invalidateQueries(["collections"]);
			queryClient.invalidateQueries(["collection", requestProperties.collectionId]);
			queryClient.invalidateQueries(["collectionVariantDetails", requestProperties.collectionId]);
		}
	});
};
