import { sha256 } from "js-sha256";

export const derivePaymentCardId = (paymentMethodId: string): string => {
	const hashedStripePaymentMethodId = sha256(paymentMethodId);

	const subStringValue = hashedStripePaymentMethodId.substring(0, 32);

	const groupByTwoArray: string[] = [];

	for (let i = 0; i < subStringValue.length; i += 2) {
		const segment = subStringValue.substring(i, i + 2);
		groupByTwoArray.push(segment);
	}

	const swapTwoElements = (index1: number, index2: number) => {
		const temp = groupByTwoArray[index1];
		groupByTwoArray[index1] = groupByTwoArray[index2];
		groupByTwoArray[index2] = temp;
	};

	swapTwoElements(0, 3);
	swapTwoElements(1, 2);
	swapTwoElements(4, 5);
	swapTwoElements(6, 7);

	let joinedString = groupByTwoArray.join("");

	const spl = joinedString.split("");

	spl[12] = "4";
	spl[16] = "b";

	joinedString = spl.join("");

	const firstSegment = joinedString.substring(0, 8);
	const secondSegment = joinedString.substring(8, 12);
	const thirdSegment = joinedString.substring(12, 16);
	const fourthSegment = joinedString.substring(16, 20);
	const fifthSegment = joinedString.substring(20, 32);

	return [firstSegment, secondSegment, thirdSegment, fourthSegment, fifthSegment].join("-");
};
