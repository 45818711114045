import { Currency, Money, PriceSource, PublicTransactionSummariesSortOrder } from "../../domain";
import { HTTPAuthMode, InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";

export interface PublicTransactionSummaryResponseModel {
	productId: string;
	variantId: string;
	imageUrl: string;
	productName: string;
	fcId: string;
	quantity: number;
	marketValue: Money;
	totalMarketValue: Money;
}

export const usePublicTransactionSummariesByShareToken = (shareToken: string, currency: Currency, priceSource: PriceSource, productNameFilter: string, sort: PublicTransactionSummariesSortOrder) => {
	const http = useHTTP(HTTPAuthMode.UseIfPresent);
	return useSimpleQueryWithCursor(["publicTransactionSummaries", shareToken, currency, priceSource, sort, productNameFilter], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		return http<PaginatedResponseModel<PublicTransactionSummaryResponseModel>>({
			method: "GET",
			url: `/collection/public/${shareToken}/transaction-summaries?cursor=${pageParam}&currency=${currency}&priceSource=${priceSource}&sort=${sort}` + (productNameFilter ? `&productNameFilter=${productNameFilter}` : "")
		});
	});
};
