import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { NotificationUnreadCountModel } from "./models";

export const useUnreadCount = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["notificationUnreadCount"], () => {
		return http<NotificationUnreadCountModel>({
			method: "GET",
			url: "/notification/unread-count"
		});
	});
};
