import { CurrencySelector } from "@fyendalscollection/app/lib/components/CurrencySelector";
import { Currency, usePreferredCurrency, useSetPreferredCurrency } from "@fyendalscollection/shared";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";

export const PageSetPreferredCurrency = (): JSX.Element => {
	useTitle("Preferred Currency");

	const setPreferredCurrency = useSetPreferredCurrency();
	const preferredCurrency = usePreferredCurrency();

	const onSelectChange = (currency: Currency) => {
		if (!preferredCurrency.data) {
			return;
		}
		if (currency !== preferredCurrency.data.currency) {
			setPreferredCurrency.mutate({ currency });
		}
	};

	return (
		<Loadable loading={setPreferredCurrency.isLoading || preferredCurrency.isLoading}>
			<Limit force={LimitWidth.Tiny}>

				<Breadcrumb crumbs={[{
					text: "Settings",
					link: "/settings"
				},
				{
					text: "Preferred Currency"
				}]}/>

				<PageTitle
					title="Preferred Currency"
					subTitle="Change the currency used for displaying pricing information.  Please note that this will not change the currency used for billing." />

				<Container>
					<ContainerSegment>
						<CurrencySelector value={preferredCurrency.data ? preferredCurrency.data.currency : null} onChange={onSelectChange} />
					</ContainerSegment>

					{setPreferredCurrency.isError && <ContainerSegment>
						<ErrorDisplay problemResponse={setPreferredCurrency.error}/>
					</ContainerSegment>}
				</Container>
			</Limit>
		</Loadable>
	);
};
