import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { HistoricalPositionModel } from "./models/HistoricalPositionModel";

export const useDashboardHistoricalPositions = (days: number, dateStr: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["dashboardHistoricalPositions", days, dateStr], () => {
		return http<HistoricalPositionModel[]>({
			method: "GET",
			url: `/collection/dashboard/historical-positions?days=${days}&date=${dateStr}`
		});
	}, { keepPreviousData: true });
};
