import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { OfficialDeckHeroesModel } from "./models";

export const useOfficialDeckHeroes = (formatFilter: string | null) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["officialDeckHeroes", formatFilter], () => {
		return http<OfficialDeckHeroesModel[]>({
			method: "GET",
			url: `/official-deck/heroes${formatFilter ? `?formatFilter=${formatFilter}` : ""}`
		});
	});
};
