import { HTTPAuthMode, InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";
import { TransactionDetailsModel } from "./models/TransactionDetailsModel";

export const useVariantTransactionDetails = (collectionId: string, variantId: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQueryWithCursor(["transactions", collectionId, variantId], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		return http<PaginatedResponseModel<TransactionDetailsModel>>({
			method: "GET",
			url: `/collection/${collectionId}/transaction/variant/${variantId}?cursor=${pageParam}`
		});
	});
};
