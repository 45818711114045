import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryParams } from "@fyendalscollection/app/lib/useQueryParams";
import { useEffectOnFirstMount } from "@fyendalscollection/app/lib/useEffectOnFirstMount";
import { useAuthState } from "@fyendalscollection/shared";

export const Landing = (): JSX.Element => {
	const { state, dispatchAuthSetAttribution } = useAuthState();
	const navigate = useNavigate();
	const queryParams = useQueryParams();
	const attribution = queryParams.get("attr");

	useEffect(() => {
		if (attribution) {
			dispatchAuthSetAttribution(attribution);
		}
	}, [attribution]);

	useEffectOnFirstMount(() => {
		if (state.accessToken) {
			navigate("dashboard", { replace: true });
			return;
		}

		navigate("login", { replace: true });
	});

	return <></>;
};
