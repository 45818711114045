import styles from "./CurrencySelector.module.scss";
import { Currency } from "@fyendalscollection/shared";
import { StyledSelect } from "@fyendalscollection/app/lib/components/StyledSelect";
import { Select } from "@fyendalscollection/app/lib/components/Select";

export interface CurrencySelectorProps {
	value: Currency | null;
	onChange: (value: Currency) => void;
	styled?: boolean;
}

export const CurrencySelector = ({ value, onChange, styled }: CurrencySelectorProps): JSX.Element => {
	const currencyOptions = [
		{
			text: "AUD",
			value: Currency.AUD
		},
		{
			text: "CAD",
			value: Currency.CAD
		},
		{
			text: "EUR",
			value: Currency.EUR
		},
		{
			text: "GBP",
			value: Currency.GBP
		},
		{
			text: "IDR",
			value: Currency.IDR
		},
		{
			text: "JPY",
			value: Currency.JPY
		},
		{
			text: "NZD",
			value: Currency.NZD
		},
		{
			text: "SGD",
			value: Currency.SGD
		},
		{
			text: "USD",
			value: Currency.USD
		}
	];

	return (
		<div className={styles.currencySelector}>
			{
				!styled ?
					<Select value={value || Currency.USD} onChange={onChange} options={currencyOptions} /> :
					<StyledSelect value={value || Currency.USD} onChange={onChange} options={currencyOptions} />
			}
		</div>
	);
};
