export enum PerformanceDeltaStep {
	Step24Hours = "Step24Hours",
	Step3Days = "Step3Days",
	Step7Days = "Step7Days",
	Step14Days = "Step14Days",
	Step1Month = "Step1Month",
	Step3Months = "Step3Months",
	Step6Months = "Step6Months",
	Step1Year = "Step1Year"
}
