import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { UserEmblemRollsResponseModel } from "./models";

export const useGetUserEmblemRolls = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["userEmblemRolls"], () => {
		return http<UserEmblemRollsResponseModel>({
			method: "GET",
			url: "/emblem/rolls"
		});
	});
};
