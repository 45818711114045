import { useEffect, useState } from "react";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { useFocus } from "@fyendalscollection/app/lib/useFocus";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";

export interface ModalTextEntryProps {
	title: string;
	description: string;
	visible: boolean;
	onCancel: () => void;
	onConfirm: (value: string) => void;
}

export const ModalTextEntry = (props: ModalTextEntryProps): JSX.Element => {
	const [ref, focus] = useFocus();

	// Focus the value TextEntry when we land on the page.
	useEffect(() => {
		if (props.visible) {
			focus();
			setText("");
		}
	}, [props.visible]);

	const [text, setText] = useState("");

	const enrichedOnConfirm = () => {
		if (text.trim().length === 0) return;
		props.onConfirm(text);
		props.onCancel();
	};

	return (
		<Modal visible={props.visible} onCancel={props.onCancel}>
			<TitleSecondary text={props.title} />

			<Paragraph>
				{props.description}
			</Paragraph>

			<Container>
				<ContainerSegment>
					<TextEntry
						ref={ref}
						placeholder="Enter Here"
						value={text}
						onChange={setText}
						onEnter={enrichedOnConfirm} />
				</ContainerSegment>
			</Container>

			<GreenButtonSet buttons={[
				{
					text: "Confirm",
					onClick: enrichedOnConfirm
				}
			]} />
		</Modal>
	);
};
