import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryParams } from "@fyendalscollection/app/lib/useQueryParams";
import Envelope from "@fyendalscollection/app/assets/envelope.svg";
import { useEffectOnFirstMount } from "@fyendalscollection/app/lib/useEffectOnFirstMount";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { BannerMessage } from "@fyendalscollection/app/lib/components/BannerMessage";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { useAuthEmailVerify, useAuthResendVerifyEmail } from "@fyendalscollection/shared";

export const PageEmailVerify = (): JSX.Element => {
	useTitle("Email Verification");

	const queryParams = useQueryParams();
	const authEmailVerify = useAuthEmailVerify();
	const authResendVerifyEmail = useAuthResendVerifyEmail();
	const navigate = useNavigate();

	const code = queryParams.get("code");
	const userId = queryParams.get("userId");

	useEffectOnFirstMount(() => {
		if (code && userId) {
			authEmailVerify.mutate({
				userId: userId,
				emailVerifyCode: code
			});
		}
	});

	useEffect(() => {
		if (authEmailVerify.isSuccess) {
			navigate("..");
		}
	}, [authEmailVerify.isSuccess]);

	const getFormContent = (): JSX.Element => {
		if (authResendVerifyEmail.isError) {
			return (
				<ContainerSegment>
					<ErrorDisplay problemResponse={authResendVerifyEmail.error}/>
				</ContainerSegment>
			);
		}

		if (authResendVerifyEmail.isSuccess) {
			return (
				<Container>
					<ContainerSegment>
						<BannerMessage image={Envelope} title="Verification Email Resent" content="We've just resent your verification email!  Remember to check your spam folder if you cannot find it!"/>
					</ContainerSegment>
				</Container>
			);
		}

		if (authEmailVerify.isError) {
			if (authEmailVerify.error.problem.problemType === "CodeExpired" && userId) {
				return (
					<ContainerSegment>
						Unfortunately, the email verification link you have has
						expired!  <a onClick={() => authResendVerifyEmail.mutate({ userId })}>Click here</a> to send a new
						one.
					</ContainerSegment>
				);
			}

			return (
				<ContainerSegment>
					<ErrorDisplay problemResponse={authEmailVerify.error}/>
				</ContainerSegment>
			);
		}

		if (userId && code) {
			return (
				<ContainerSegment>
					Verifying email...
				</ContainerSegment>
			);
		}

		return (
			<Container>
				<ContainerSegment>
					<BannerMessage image={Envelope} title="Email Verification" content="Thanks for signing up!  We just sent you an email so we can verify your email address before you login.  If you cannot find the email, you may need to check your spam folder."/>
				</ContainerSegment>
			</Container>
		);
	};

	return (
		<Loadable loading={authEmailVerify.isLoading || authResendVerifyEmail.isLoading}>
			<Container>
				{getFormContent()}
			</Container>
		</Loadable>
	);
};
