import React, { useEffect, useMemo, useState } from "react";
import styles from "./ParticleContainer.module.scss";
import { AnimatedCanvas } from "@fyendalscollection/app/lib/components/AnimatedCanvas";

export interface ParticleContainerProps {
	children: React.ReactNode;
	active: boolean;
}

interface ParticleInformation {
	x: number;
	y: number;
	color: string;
}

export const ParticleContainer = ({ children, active }: ParticleContainerProps): JSX.Element => {
	const container = React.useRef<HTMLDivElement>(null);
	const [width, setWidth] = useState(0);

	useEffect(() => {
		const onResize = () => {
			if (!container.current) {
				return;
			}

			setWidth(container.current.clientWidth + (window.innerWidth < 360 ? 16 : 32));
		};

		onResize();
		window.addEventListener("resize", onResize);
		return () => window.removeEventListener("resize", onResize);
	}, []);

	const classNames = [ styles.particleContainer ];
	if (!active) {
		classNames.push(styles.inactive);
	}

	const drawFunc = useMemo(() => {
		return drawParticles(width);
	}, [width]);

	return (
		<div ref={container} className={classNames.join(" ")}>
			<div>
				<div>
					{children}
				</div>
			</div>

			{active && <AnimatedCanvas width={width} height={96} drawFunc={drawFunc} />}
		</div>
	);
};

const drawParticles = (width: number) => {
	return (ctx: CanvasRenderingContext2D, dt: number, particles: ParticleInformation[] | null) => {
		if (!particles) {
			particles = [];
		}

		if (particles.length < 600) {
			particles.push({
				x: Math.random() * (width - 48) + 24,
				y: 0,
				color: ["#5AAA3E", "#67aa4f", "#52ca26"][Math.floor(Math.random() * 3)]
			});
		}

		particles = particles.filter((particle) => particle.y < 96);

		ctx.clearRect(0, 0, width, 96);

		particles.forEach((particle) => {
			particle.y += dt / 25;
			const radius = Math.max(8 - (particle.y / 96) * 8, 0);

			const gradient = ctx.createRadialGradient(particle.x, particle.y, radius / 4, particle.x, particle.y, radius);
			gradient.addColorStop(0, particle.color);
			gradient.addColorStop(1, `${particle.color}00`);

			ctx.beginPath();
			ctx.arc(particle.x, particle.y, radius, 0, 2 * Math.PI, false);
			ctx.fillStyle = gradient;
			ctx.fill();
		});

		return particles;
	};
};
