import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface GetShareableLinkResponseModel {
	shareableLink: string;
}

export const useShareableLink = (collectionId: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["shareableLink", collectionId], () => {
		return http<GetShareableLinkResponseModel>({
			method: "GET",
			url: `/collection/${collectionId}/public/shareable-link`
		});
	});
};
