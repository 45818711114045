import { MutationFunction, useQueryClient } from "react-query";
import { SubscriptionModel } from "./models/SubscriptionModel";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface SubscriptionCreateRequestModel {
	planId: string;
	paymentCardId: string;
	promoCode?: string;
}

export const useSubscriptionCreate = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<SubscriptionModel>, SubscriptionCreateRequestModel> = requestModel => {
		return http<SubscriptionModel, SubscriptionCreateRequestModel>({
			method: "POST",
			url: "/subscription",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: data => {
			queryClient.resetQueries();
			queryClient.setQueryData(["subscription"], data.body);
		}
	});
};
